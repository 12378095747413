import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import DarkLightModeButton from './dark-light-mode-button';
import { AuthenticationButton} from '@wyes/react-auth0';

export default function Header() {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Wyes - Administration
        </Typography>
        <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
          <AuthenticationButton />
          <DarkLightModeButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
