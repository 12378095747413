import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Card, CardMedia, CardContent, Stack, Typography } from '@mui/material';
import previewLeft from './wyes-glasses-0-left.png';
import previewRight from './wyes-glasses-0-right.png';
import preview from './front-view.png';

export function WyesGlasses0SelectItem() {
  return (
    <Stack sx={{ width: '100%' }} direction="row" alignItems='center' justifyContent='space-between'>
      <Typography>Wyes Glasses 0</Typography>
      <img height='50px' src={preview} />
    </Stack>
  )
}

export default function WyesGlasses0({ children = null, hardwareVersion, onClick = () => { }, ...props }) {

  const img = useMemo(() => {
    if (!hardwareVersion) return preview;
    return hardwareVersion.endsWith('LEFT') ? previewLeft : hardwareVersion.endsWith('RIGHT') ? previewRight : preview;
  }, [hardwareVersion]);

  return (
    <Card {...props} onClick={onClick}>
      <CardMedia
        component="img"
        sx={{ p: 1, height: '200px', objectFit: 'contain' }}
        src={img}
      ></CardMedia>
      {children && (
        <CardContent>
          {children}
        </CardContent>
      )}
    </Card>
  );
}

WyesGlasses0.propTypes = {
  children: PropTypes.any,
  hardwareVersion: PropTypes.string,
  onClick: PropTypes.func,
}