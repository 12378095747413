import { useField } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import OutputActivation from '../../activations/OutputActivation';
import StepBackBone, { useStepperController } from './step-backbone';
import { Stack } from '@mui/material';
import { SensorsChart } from '@wyes/web-react-devices';
import { useSensorStreamController } from '../../../../sensors/sensors-stream';
import { useSensorsDatasetFromField } from './choose-inputs';
import { useExpressionsDatasetFromField } from './write-expressions';
import { TYPE as OAStype } from '../../activations/OutputActivationSchmitt';
import { TYPE as OATtype } from '../../activations/OutputActivationThreshold';

const activationAlgorithm = ({ t, c }, expression) => {
  let lastValue = false;
  return (scope, value, history) => {
    try {
      const value = scope[expression]
      if (value === undefined) return 0;

      switch (t) {
        case OAStype:
          console.log({ lastValue, c, value, expression })
          if (lastValue === false) {
            if (value > c.ht) {
              lastValue = true;
              return 1000;
            } else {
              return 0;
            }
          } else {
            if (value < c.lt) {
              lastValue = false;
              return 0;
            } else {
              return 1000;
            }
          }
          break;
        case OATtype:
          if (value > c.th) {
            return 1000;
          } else {
            return 0;
          }
          break;
        default:
          return null;
      }

    } catch (e) {
      return null;
    }
  }
}

export const useActivationDatasetFromField = (namespace, expression = { __key: null }) => {
  const [{ value }, meta] = useField(namespace);
  const [data, setData] = useState([]);

  useEffect(() => {
    const data = [];
    data.push({
      noBackground: true,
      __name: 'activation',
      __key: 'activation',
      __compute: activationAlgorithm(value, expression.__key),
    });
    setData(data);
  }, [value, setData]);

  return { data, value };
}

export default function ChooseActivation({ namespace, ...props }) {

  const { nextDisabled, setNextButtonDisabled } = useStepperController();
  const [field, meta] = useField(namespace + '.a');

  const { start, stop, activated, dataset, emitter } = useSensorStreamController();
  const { data: sensors } = useSensorsDatasetFromField(namespace + '.i', dataset);
  const { data: expressions } = useExpressionsDatasetFromField(namespace + '.e');
  const { data: activations } = useActivationDatasetFromField(namespace + '.a', expressions[expressions.length - 1]);
  const data = useMemo(() => [...sensors, ...expressions, ...activations], [sensors, expressions, activations]);

  useEffect(() => {
    setNextButtonDisabled(meta.error);
  }, [meta.error, setNextButtonDisabled]);

  return (
    <StepBackBone nextDisabled={nextDisabled} {...props}>
      <Stack spacing={2}>
        <SensorsChart defaultStarted={activated} onStart={start} onStop={stop} datasets={data} emitter={emitter} height='500px' />
        <OutputActivation namespace={namespace + '.a'} />
      </Stack>
    </StepBackBone>
  );
}

ChooseActivation.propTypes = {
  namespace: propTypes.string,
}