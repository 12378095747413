
import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { MenuItem, TextField } from '@mui/material';
import { useField } from 'formik';
import { LAYOUTS } from './layouts';

export const DEFAULT_VALUE = 0;

export default function KeyboardLayoutField({ name, ...props }) {

    const [{ value }, meta, { setValue }] = useField(name);
    
    return (
        <TextField
            select
            fullWidth
            name={name}
            label='Layout'
            value={value.toString()}
            onChange={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                setValue(parseInt(ev.target.value));
            }}
        >
            {LAYOUTS.map((layout, index) => (
                <MenuItem key={index} value={index.toString()}>
                    {layout.name}
                </MenuItem>
            ))}
        </TextField>
    )
}

KeyboardLayoutField.propTypes = {
    name: propTypes.string,
}
