import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

function SelectDeviceWithScannerInput({ onClick }) {

    const [value, setValue] = useState('');
    const [error, setError] = useState(null);

    const onChange = (ev) => {
        setValue(ev.target.value);
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Tab' || ev.key === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
            try {
                const json = JSON.parse(value);
                setError(null);
                onClick(json.sn);
            } catch (e) {
                setError(e.message);
            }
        }
    }

    return (
        <TextField
            fullWidth
            autoFocus
            helperText={error}
            error={Boolean(error)}
            type='text'
            value={value}
            onChange={onChange}
            onKeyDown={onKeyPress}
        />
    )
}

SelectDeviceWithScannerInput.propTypes = {
    onClick: PropTypes.func,
}

function SelectDeviceWithScannerModal({ onClose, onClick }) {
    return (
        <Dialog maxWidth='lg' fullWidth open onClose={onClose}>
            <DialogTitle>
                <Typography>Flash the device label</Typography>
            </DialogTitle>
            <DialogContent>
                <SelectDeviceWithScannerInput onClick={onClick} />
            </DialogContent>
            <DialogActions>
                <Button color='inherit' onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

SelectDeviceWithScannerModal.propTypes = {
    onClose: PropTypes.func,
    onClick: PropTypes.func,
}

export default function SelectDeviceWithScanner({ ...props }) {

    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(true);
    const onClose = () => setOpen(false);

    return (
        <Fragment>
            <IconButton onClick={handleClick}>
                <QrCodeScannerIcon />
            </IconButton>
            {open && <SelectDeviceWithScannerModal {...props}  onClose={onClose}/>}
        </Fragment>
    )
}

SelectDeviceWithScanner.propTypes = {
    onClose: PropTypes.func,
    onClick: PropTypes.func,
}
