import React from 'react';
import propTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import { Field, useField } from 'formik';
import * as Yup from 'yup';
import { Slider, Stack, Typography } from '@mui/material';

export const type = "number";
export const label = "Filter";
export const defaultValue = 10;
export const min = 0;
export const max = 100;
export const schema = Yup.number().max(max, 'max 100').min(min, 'min 0').required('Required');

export function SensorInputFilterSlider({ namespace }) {
  const [{ value }, meta, { setValue }] = useField(namespace);
  const handleChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    setValue(newValue);
  };

  const marks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 100,
      label: '100%',
    }
  ];

  return (
    <Stack sx={{ paddingX: 2 }}>
      <Typography>{label}  ({value}%)</Typography>
      <Slider defaultValue={defaultValue} marks={marks} step={1} min={min} max={max} value={value} onChange={handleChange} />
    </Stack>
  )
}

SensorInputFilterSlider.propTypes = {
  namespace: propTypes.string,
}

export default function SensorInputFilter({ namespace, className, isSubmitting = false, disabled = false, ...props }) {

  return (
    <Field
      size='small'
      className={className}
      label={label}
      placeholder={label}
      component={TextField}
      name={namespace}
      type={type}
      disabled={disabled || isSubmitting}
      onClick={(ev) => ev.stopPropagation()}
    />
  );
}

SensorInputFilter.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
  disabled: propTypes.bool,
}