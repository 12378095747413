import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ConfigureMovementModal, { useConfigureMovementModalController } from './configure-movement-modal';
import { useOnlineDeviceReactions, useOnlineDeviceSensors } from '../live/online-device-provider';

export default function AddMovementButton({ sn }) {
    const [open, handleOpen, handleClose] = useConfigureMovementModalController();

    const sensors = useOnlineDeviceSensors();
    const reactions = useOnlineDeviceReactions();

    const disabled = sensors.length === 0 || reactions.length === 0;
    const title = disabled ? 'Load sensors and reactions first' : 'Add a movement';

    return (
        <Fragment>
            <Tooltip title={title}>
                <span>
                    <IconButton disabled={disabled} onClick={handleOpen} aria-label="add movement">
                        <AddIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <ConfigureMovementModal sn={sn} open={open} onClose={handleClose} movement={undefined} />
        </Fragment>
    );
}

AddMovementButton.propTypes = {
    sn: PropTypes.string,
}
