import { useAuth0 } from "@auth0/auth0-react";

const rolesClaims = 'https://administration.wyes.cloud/roles';

const useUsersRoles = () => {
  const { user } = useAuth0();
  const usersRoles = user[rolesClaims] || [];
  return usersRoles;
}

export const useIsFactoryAdmin = () => {
  const roles = useUsersRoles();
  return roles.includes('factory-admin');
}

export const useIsAfterSalesAdmin = (user) => {
  const roles = useUsersRoles();
  return roles.includes('after-sales-admin');
}
