import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
import { BASE_ADMIN_API_URL } from '../../../../config';
import { useOnlineDeviceIds } from './online-device-provider';
import { useFetch } from '../../../misc/fetch';

export function useLaunchReboot() {
    const fetch = useFetch();
    const launchRebootCb = useCallback(async (serialNumber) => {
        const response = await fetch(
            `${BASE_ADMIN_API_URL}/actions/devices/${serialNumber}/device/reboot`,
            {
                method: 'GET',
            });

        if (!response.ok) {
            throw new Error(await response.text())
        }
    }, []);
    return launchRebootCb;
}

export function ConfirmRebootModal({ open, onClose }) {

    const { serialNumber } = useOnlineDeviceIds();
    const handleLaunchReboot = useLaunchReboot();

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
            <DialogTitle>Reboot device: {serialNumber} ?</DialogTitle>
            <DialogActions>
                <Button color='primary' onClick={onClose} autoFocus>Cancel</Button>
                <Button color='inherit' onClick={() => handleLaunchReboot(serialNumber).finally(onClose)}>
                    Reboot
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmRebootModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default function RebootButton({ }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Button onClick={handleClickOpen} aria-label="launch reboot">
                Reboot
            </Button>
            <ConfirmRebootModal open={open} onClose={handleClose} />
        </Fragment>
    );
}

RebootButton.propTypes = {
    
}
