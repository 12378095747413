import React, { useCallback, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { useAfterSalesResetDB } from '../../model/aftersales/AfterSalesDatabase';
import ClearIcon from '@mui/icons-material/Clear';
import { useIsAfterSalesAdmin, useIsFactoryAdmin } from '@wyes/react-auth0';
import { useRegistrationResetDB } from '../../model/registration/RegistrationDatabase';

export default function ResetDatabasesButton(props) {
    const [disabled, setDisabled] = useState(false);

    const resetAfterSales = useAfterSalesResetDB();
    const resetFactory = useRegistrationResetDB();

    const isFactoryAdmin = useIsFactoryAdmin();
    const isAfterSalesAdmin = useIsAfterSalesAdmin();

    if (!(isFactoryAdmin || isAfterSalesAdmin)) return null;

    const handleClick = useCallback(() => {
        setDisabled(true);
        const todo = [];
        if (isFactoryAdmin) todo.push(resetFactory());
        if (isAfterSalesAdmin) todo.push(resetAfterSales());
        Promise.all(todo)
            .then(() => window.location.reload())
            .finally(setDisabled(false));
    }, []);

    if(!(isFactoryAdmin || isAfterSalesAdmin)) return null;

    return (
        <Tooltip title='Reset databases'>
            <IconButton
                {...props}
                disabled={disabled}
                onClick={handleClick}
                color={'primary'}
            >
                <ClearIcon />
            </IconButton>
        </Tooltip>
    );
}
