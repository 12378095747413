import { useEffect } from 'react';
import { useJobEnd, useJobError, useJobProgress, useJobStart } from '../jobs/jobs-provider';
import { topicBuilder, useMqtt } from './mqtt-client';
import MqttPattern from 'mqtt-pattern';

export const jobsTopic = (namespace = '+') => topicBuilder(`v1/jobs/${namespace}/+/+`);
export const jobsTopicPattern = (namespace = '+namespace') => topicBuilder(`v1/jobs/${namespace}/+key/+state`);

export const useSubscribeJobsState = (namespace) => {
  const { client } = useMqtt();

  useEffect(() => {

    if (client === null) {
      return;
    }

    const _client = client;
    const _topic = jobsTopic(namespace);

    _client.subscribe(_topic, function (err) {
      if (err) {
        return console.error('subscribe', err);
      }
    });

    return () => {
      if (!_client.disconnecting && _client.connected) {
        _client.unsubscribe(_topic, function (err) {
          if (err) {
            return console.error('unsubscribe', err);
          }
        });
      }
    }
  }, [client, namespace]);
}

export const useJobsState = (namespace) => {
  const { client } = useMqtt();

  const startJob = useJobStart();
  const progressJob = useJobProgress();
  const endJob = useJobEnd();
  const errorJob = useJobError();

  useEffect(() => {
    if (client === null) {
      return;
    }

    const _client = client;
    const pattern = jobsTopicPattern(namespace);

    const listener = (topic, message) => {
      const params = MqttPattern.exec(pattern, topic);

      if (params) {
        const payload = JSON.parse(message.toString('ascii'));
        const jobname = params.key;
        if (params.state === 'start') {
          startJob(jobname, payload);
        } else if (params.state === 'progress') {
          progressJob(jobname, payload.progress);
        } else if (params.state === 'finish') {
          if (payload.error) {
            errorJob(jobname, payload.error);
          } else {
            endJob(jobname);
          }
        } else {
          // do nothing.
        }
      }
    }

    _client.on('message', listener);

    return () => {
      _client.removeListener('message', listener);
    }
  }, [client, namespace]);
}
