
import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import RelayReactionConfiguration, { TYPE as RRCType } from './relay-reaction-configuration';
import BleReactionConfiguration, { TYPE as BLEType } from './ble-reaction-configuration';
import { Typography } from '@mui/material';


export default function ReactionConfiguration({ reaction, ...props }) {

    if (!reaction) {
        return null;
    }

    switch (reaction.t) {
        case RRCType:
            return <RelayReactionConfiguration reaction={reaction} {...props} />
        case BLEType: 
            return <BleReactionConfiguration reaction={reaction} {...props} />
        default:
            return <Typography>No Configuration Associated.</Typography>
    }

}

ReactionConfiguration.propTypes = {
    reaction: propTypes.object,
}