import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, IconButton, Snackbar, Tooltip } from '@mui/material';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import copy from 'copy-to-clipboard';

export const useMovementCopyToClipboardAction = (movement) => {

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleCopy = useCallback(async (ev) => {
        setDisabled(true);
        ev.preventDefault();
        ev.stopPropagation();

        try {
            copy(JSON.stringify(movement, null, 4));
            setOpen(true);
        } catch (e) {
            console.error(e);
        }
        setDisabled(false);
    }, [movement]);

    return [handleCopy, disabled, open, handleClose];
}


export default function CopyToClipboardMovementButton({ movement }) {

    const [handleCopy, disabled, open, closeSnack] = useMovementCopyToClipboardAction(movement);
    const title = 'Copy to clipboard';

    return (
        <Fragment>
            <Tooltip title={title}>
                <span>
                    <IconButton disabled={disabled} onClick={handleCopy} aria-label="copy movement">
                        <CopyAllOutlinedIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={closeSnack}
            >
                <Alert onClose={closeSnack} severity="info" sx={{ width: '100%' }}>
                    Copied to clipboard !
                </Alert>
            </Snackbar>
        </Fragment>

    );
}

CopyToClipboardMovementButton.propTypes = {
    movement: PropTypes.object,
}
