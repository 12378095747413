import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Box, Paper, IconButton, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { observeProgrammerById } from './observers';
import ProgrammerCapabilities from './programmer-capabilities-chips';
import ProgrammerStatusBadge from './programmer-status-badge';
import { CREATED, REGISTERED } from '../../../model/registration/Programmer';
import RegisterProgrammerView from './register-programmer-view';
import ProgrammerOnlineWorkbench from './online';

import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

export function ProgrammerDetailsInternal({ programmer }) {

    const status = programmer.status;
    const [fullscreen, setFullScreen] = useState(false);
    const options = fullscreen ? { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1250 } : {};

    useEffect(() => {
        const handler = (event) => {
            if (fullscreen) {
                if (event.key === 'Escape') {
                    setFullScreen(false);
                    event.stopPropagation();
                }
            }

            // catch all tabulation and prevent them from going outside the console. 
            if(event.key === 'Tab') {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        window.addEventListener("keydown", handler, false);
        return () => {
            window.removeEventListener("keydown", handler, false);
        };
    }, [fullscreen]);

    return (
        <Box component={Paper} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, ...options }}>
            <Stack sx={{ m: 4 }} spacing={2} direction='row' justifyContent={'flex-start'}>
                <Tooltip title={fullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}>
                    <IconButton onClick={() => setFullScreen(!fullscreen)}>
                        {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                </Tooltip>
                <ProgrammerStatusBadge programmer={programmer}>
                    <Typography variant='h4'>{programmer.name}</Typography>
                </ProgrammerStatusBadge>
                <Box flexGrow={1} />
                <ProgrammerCapabilities programmer={programmer} />
            </Stack>
            {status === CREATED && <RegisterProgrammerView programmer={programmer} />}
            {status === REGISTERED && <ProgrammerOnlineWorkbench programmer={programmer} />}
        </Box>
    )
}

ProgrammerDetailsInternal.propTypes = {
    programmer: PropTypes.any
}

const ObservedProgrammerDetails = observeProgrammerById(ProgrammerDetailsInternal);

export default function ProgrammersDetails() {
    const { id } = useParams();
    return <ObservedProgrammerDetails id={id} />
}
