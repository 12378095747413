
export const W_GLASSES_0 = 'W_GLASSES_0';
export const W_CONTACTORS_0 = 'W_CONTACTORS_0';
export const W_CONTROLLERS_0 = 'W_CONTROLLERS_0';
export const W_BLE_0 = 'W_BLE_0';

export const HARDWARES = [
  W_GLASSES_0,
  W_CONTACTORS_0,
  W_CONTROLLERS_0,
  W_BLE_0,
]
