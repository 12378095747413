import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ProgrammerNetworkList from './networks-list';
import ProgrammerConnectionProvider from './connection-provider';
import ProgrammerConsole from './programmer-console';

export default function ProgrammerConnectionNetwork({ programmer }) {

    const [network, setNetwork] = useState(null);

    if (network) {
        return (
            <ProgrammerConnectionProvider network={network} resetNetwork={() => setNetwork(null)}>
                <ProgrammerConsole />
            </ProgrammerConnectionProvider>
        );
    }

    return (
        <ProgrammerNetworkList onClick={setNetwork} programmer={programmer} />
    )
}

ProgrammerConnectionNetwork.propTypes = {
    programmer: PropTypes.any
}
