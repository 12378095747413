import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack, Switch, Paper } from '@mui/material';
import { defaultValue as ROIdefault, schema as ROIschema } from './ReactionOutputIdentifier';
import ReactionOutputEvent, { defaultValue as ROEdefault, schema as ROEschema } from './ReactionOutputEvent';
import ReactionOutputWhen, { defaultValue as ROWdefault, schema as ROWschema } from './ReactionOutputWhen';
import ReactionOutputSolution, { defaultValue as ROSdefault, schema as ROSschema } from './ReactionOutputSolution';
import ReactionOutputMandatory, { defaultValue as ROMdefault, schema as ROMschema } from './ReactionOutputMandatory';
import * as Yup from 'yup';
import ReactionCard from '../../../reactions/reaction-card';

export const schema = Yup.object({
  n: ROIschema,
  w: ROWschema,
  e: ROEschema,
  s: ROSschema,
  m: ROMschema,
});

export const defaultValue = {
  n: ROIdefault,
  w: ROWdefault,
  e: ROEdefault,
  s: ROSdefault,
  m: ROMdefault,
};

export default function ReactionOutput({ reaction, namespace, onAdd, onRemove, ...props }) {

  const handleClick = onAdd ? onAdd : onRemove ? onRemove : undefined;

  return (
    <Paper sx={{ p: 1 }}>
      <Stack spacing={1} direction='column'>
        <ReactionCard onClick={handleClick} reaction={reaction} noAction/>
        {namespace !== undefined && <ReactionOutputEvent events={reaction.e} namespace={namespace + '.e'} />}
        {namespace !== undefined && <ReactionOutputWhen namespace={namespace + '.w'} />}
        {namespace !== undefined && <ReactionOutputSolution namespace={namespace + '.s'} />}
        {namespace !== undefined && <ReactionOutputMandatory namespace={namespace + '.m'} />}
        <Stack spacing={2} direction='row' justifyContent='center' alignItems='center'>
          <Switch
            checked={onAdd === undefined}
            onChange={handleClick}
            inputProps={{ 'aria-label': 'add this input' }}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}

ReactionOutput.propTypes = {
  reaction: propTypes.object,
  namespace: propTypes.string,
  onRemove: propTypes.func,
  onAdd: propTypes.func,
}