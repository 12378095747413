import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Typography } from '@mui/material';
import OutputActivationThresholdField, { schema as FieldSchema} from './OutputActivationThresholdField';
import * as Yup from 'yup';

export const schema = Yup.object({
  th: FieldSchema,
});
export const TYPE = 'oat';
export const defaultValue = {
  th: 0,
};

export default function OutputActivationThreshold({ namespace, ...props }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}><Typography>Simple Threshold</Typography></Grid>
      <Grid item xs={12}><OutputActivationThresholdField namespace={namespace + '.th'} label='Threshold' /></Grid>
    </Grid>
  )
}

OutputActivationThreshold.propTypes = {
  namespace: propTypes.string,
}
