import React, { useCallback } from "react"
import { Button } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"

export const useLogoutRoutine = () => {
  const { logout } = useAuth0();

  return useCallback(() => {
    const returnTo = window.location.origin;
    logout({ returnTo });
  }, [logout]);
}

const LogoutButton = () => {
  const logoutRoutine = useLogoutRoutine();
  return <Button onClick={() => logoutRoutine()}>Log Out</Button>
}

export default LogoutButton

