import React, { } from 'react'
import { Auth0Provider } from "@auth0/auth0-react"
import { buildRedirectURI } from './login-button-with-redirect'

export default function AuthProvider({ clientId, domain, cacheLocation, children = null }) {

  return (
    <Auth0Provider
      cacheLocation={cacheLocation}
      domain={domain}
      clientId={clientId}
      skipRedirectCallback
      redirectUri={buildRedirectURI()}
    >
      {children}
    </Auth0Provider>
  )
}
