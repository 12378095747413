import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import OnlineDeviceProvider from './online-device-provider';
import SensorsList from '../sensors/sensors-list';
import ReactionsList from '../reactions/reactions-list';
import MovementsList from '../movements/movements-list';
import HardwareCard from '../../hardwares/hardware-card';
import FirmwaresList from '../../firmwares/list';
import { W_CONTROLLERS_0 } from '../../../../model/Hardware';
import { JobsList, JobsLock, JobsProvider, useJobsState, useSubscribeJobsState } from '@wyes/web-react-devices';
import LastStateCard from './last-state-card';
import OnlineDeviceActionsCard from './online-device-actions-card';


export function DeviceDetailsInternal({ device }) {

    useSubscribeJobsState(device.id);
    useJobsState(device.id);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2}>
                <Grid container spacing={2} item xs={0} lg={9}>
                    <Grid item xs={0} lg={3}>
                        <HardwareCard hardware={device.hardware} sx={{ width: '100%', height: '100%' }} />
                    </Grid>
                    <Grid item xs={9} lg={6}>
                        <LastStateCard sx={{ width: '100%', height: '100%' }} />
                    </Grid>
                    <Grid item xs={3}>
                        <OnlineDeviceActionsCard sx={{ width: '100%', height: '100%' }} />
                    </Grid>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <JobsList />
                    </Paper>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <MovementsList />
            </Grid>
            <Grid item lg={6} xs={12}>
                <SensorsList />
            </Grid>
            <Grid item lg={6} xs={12}>
                <ReactionsList />
            </Grid>
            <Grid item xs={12}>
                <FirmwaresList hardware={device.hardware} filterRevoked device={device} />
            </Grid>
        </Grid>
    );
}

DeviceDetailsInternal.propTypes = {
    device: PropTypes.any,
}

export default function DeviceDetails(props) {
    const { id } = useParams();
    return (
        <OnlineDeviceProvider serialNumber={id}>
            <JobsProvider>
                <JobsLock />
                <DeviceDetailsInternal device={{ id, hardware: W_CONTROLLERS_0 }} />
            </JobsProvider>
        </OnlineDeviceProvider>
    );
}
