import React, { } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import _ from 'lodash';
import RegisterProgrammerButton from './generate-jwt-programmer-button';

export default function RegisterProgrammerView({ programmer }) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
            <RegisterProgrammerButton programmer={programmer} />
        </Box>
    );
}

RegisterProgrammerView.propTypes = {
    programmer: PropTypes.any
}
