import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Grid, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Formik, Form, Field } from 'formik';
import { BASE_REGISTRATION_API_URL } from '../../../../config';
import * as Yup from 'yup';
import HardwareSelectField from '../../hardwares/hardware-select-field';
import { TextField } from 'formik-mui';
import { HARDWARES } from '../../../../model/Hardware';
import { useAfterSalesSynchronizeDB } from '../../../../model/aftersales/AfterSalesDatabase';
import { useFetch } from '../../../misc/fetch';

export function useCreateDevice() {
    const sync = useAfterSalesSynchronizeDB();
    const fetch = useFetch();
    const addCallback = useCallback(async ({ type, number }) => {
        const response = await fetch(
            `${BASE_REGISTRATION_API_URL}/devices/${type}/${number}`,
            {
                method: 'PUT',
            });

        if (!response.ok) {
            throw new Error(await response.text())
        }

        await sync();
    }, []);
    return addCallback;
}

const DeviceCreationSchema = Yup.object().shape({
    type: Yup.string().oneOf(HARDWARES).required('Required'),
    number: Yup.number().min(0).max(100).round('round').required('Required'),
});

export function AddDeviceModal({ open, onClose, type = '', number = 30 }) {

    const handleCreate = useCreateDevice();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add a Device (dev only)</DialogTitle>
            <Formik
                initialValues={{ type, number }}
                validationSchema={DeviceCreationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    await handleCreate(values);
                    setSubmitting(false);
                    onClose();
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <HardwareSelectField name="type" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextField} name="number" type="number" label="Number" fullWidth />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isSubmitting} onClick={onClose}>Cancel</Button>
                            <Button disabled={isSubmitting} type='submit' color='secondary'>Create</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>

        </Dialog>
    )
}

AddDeviceModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    number: PropTypes.number,
}

export default function AddDeviceButton({ hardwareId }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Tooltip title={`Create devices`}>
                <IconButton onClick={handleClickOpen} aria-label="Create devices">
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <AddDeviceModal open={open} onClose={handleClose} hardwareId={hardwareId} />
        </Fragment>
    );
}

AddDeviceButton.propTypes = {
    hardwareId: PropTypes.string,
}
