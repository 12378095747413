import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { useField } from 'formik';
import * as Yup from 'yup';
import { MenuItem, TextField } from '@mui/material';

const VALUES = {
  DISABLED: 0,
  LOW_EDGE: 1,
  RISING_EDGE: 2,
  HIGH_EDGE: 3,
  FALLING_EDGE: 4,
}

export const type = "number";
export const label = "On";
export const defaultValue = VALUES.RISING_EDGE;
export const schema = Yup.number().required('Required')
  .min(VALUES.LOW_EDGE, 'Must be between LOW EDGE and FALLING EDGE')
  .max(VALUES.FALLING_EDGE, 'Must be between LOW EDGE and FALLING EDGE');

export default function ReactionOutputWhen({ namespace, className, isSubmitting, ...props }) {

  const [{ value }, meta, { setValue }] = useField(namespace);

  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
  };

  return (
    <TextField
      size='small'
      className={className}
      label={label}
      placeholder={label}
      name={namespace}
      type={type}
      disabled={isSubmitting}
      value={value.toString()}
      onChange={handleChange}
      select >
      {_.map(VALUES, (value, name) => <MenuItem key={name} value={value.toString()}>{name.replace('_', ' ')}</MenuItem>)}
    </TextField>
  );
}

ReactionOutputWhen.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
}