import React from 'react';
import propTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import { Field, useField } from 'formik';
import * as Yup from 'yup';
import { Slider, Stack, Typography } from '@mui/material';

export const type = "number";
export const label = "Movement Duration";
export const defaultValue = 20;
export const min = 5;
export const max = 500;
export const schema = Yup.number().min(min, '50ms min').max(max, '5s max').required('Required');

export function SensorInputDurationSlider({ namespace }) {
  const [{ value }, meta, { setValue }] = useField(namespace);
  const handleChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    setValue(newValue);
  };

  const marks = [
    {
      value: 5,
      label: '50ms',
    },
    {
      value: 500,
      label: '5s',
    }
  ];
  
  return (
    <Stack sx={{ paddingX: 2 }}>
      <Typography>{label} ({(value/100).toFixed(2)}s)</Typography>
      <Slider defaultValue={defaultValue} marks={marks} step={min} min={min} max={max} value={value} onChange={handleChange} />
    </Stack>
  )
}

SensorInputDurationSlider.propTypes = {
  namespace: propTypes.string,
}

export default function SensorInputDuration({ namespace, className, isSubmitting = false, disabled = false, ...props }) {

  return (
    <Field
      size='small'
      className={className}
      label={label}
      placeholder={label}
      component={TextField}
      name={namespace}
      type={type}
      disabled={disabled || isSubmitting}
      onClick={(ev) => ev.stopPropagation()}
    />
  );
}

SensorInputDuration.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
  disabled: propTypes.bool,
}