import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Select, MenuItem } from '@mui/material';
import { useField } from 'formik';

import { defaultValue as OASdefault, TYPE as OAStype } from './OutputActivationSchmitt';
import { defaultValue as OATdefault, TYPE as OATtype } from './OutputActivationThreshold';

export const defaultValue = {
  t: OATtype,
  ...OATdefault
}

const values = [OATtype, OAStype];
const names = {
  [OATtype]: {
    name: 'Simple Trigger',
    default: OATdefault,
  },
  [OAStype]: {
    name: 'Schmitt Trigger',
    default: OASdefault,
  }
}

export default function OutputActivationTypeSelector({ namespace, ...props }) {

  const [field] = useField(namespace);
  const { value: { t: type }, onChange } = field;

  return (
    <Grid item xs={12}>
      <Select
        onChange={(ev) => {
          const type = ev.target.value;

          onChange({
            target: {
              name: namespace,
              value: Object.assign({ t: type }, names[type].default)
            }
          });
        }}
        value={type}
      >
        {_.map(values, type => (
          <MenuItem value={type}>{names[type].name}</MenuItem>
        ))}
      </Select>
    </Grid>
  );
}

OutputActivationTypeSelector.propTypes = {
  namespace: propTypes.string,
}
