
import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Stack } from '@mui/material';

export default function ProgrammerCapabilities({ programmer, ...props }) {

    const capabilities = programmer.capabilities || [];

    return (
        <Stack {...props} spacing={2} direction='row'>
            {capabilities.map(({key, configured}, i) => <Chip key={key + i} label={key} color={configured ? 'secondary' : 'default'} />)}
        </Stack>
    );
}

ProgrammerCapabilities.propTypes = {
    programmer: PropTypes.any,
}
