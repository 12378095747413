import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid, TableRow, TableCell, TableBody, TableContainer,
    Paper, Table, Chip, CircularProgress, Typography, Stack, Button
} from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import ReactJson from 'react-json-view'
import HardwareCard from '../../hardwares/hardware-card';
import FirmwaresList from '../../firmwares/list';
import { useRegistrationDatabase } from '../../../../model/registration/RegistrationDatabase';
import FactoryDeviceQRcode from './qrcode';


export function FactoryDeviceDetailsInternal({ device, id }) {

    const navigate = useNavigate();

    if (device === undefined) {
        return <CircularProgress />
    } else if (device === null) {
        return (
            <Stack>
                <Typography>No device found for this id: {id}</Typography>
                <Button onClick={() => navigate(-1)}>Go Back</Button>
            </Stack>
        )
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                    <TableContainer component={Paper} sx={{ p: 2 }}>
                        <Table aria-label="device details table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>{device.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Hardware</TableCell>
                                    <TableCell><Chip label={device.hardware} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Owner</TableCell>
                                    <TableCell>{device.ownerId}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Hardware Version</TableCell>
                                    <TableCell>{device.hardwareVersionId}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Firmware</TableCell>
                                    <TableCell>{device.firmwareId}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Factory Produced At</TableCell>
                                    <TableCell>{device.factoryProducedAt.toISOString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Factory Log</TableCell>
                                    <TableCell><ReactJson src={device.factoryLog} theme='solarized' /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Factory Status</TableCell>
                                    <TableCell><Chip label={device.factoryStatus} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Connectivity Status</TableCell>
                                    <TableCell><Chip label={device.status} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Last Connection</TableCell>
                                    <TableCell>{device.lastConnection.toISOString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Updated At</TableCell>
                                    <TableCell>{device.updatedAt.toISOString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>{device.createdAt.toISOString()}</TableCell>
                                </TableRow>
                                <TableRow sx={{ 'td': { border: 0 } }}>
                                    <TableCell>Ext Conf</TableCell>
                                    <TableCell><ReactJson src={device.extConf} theme='solarized' /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={3} container>
                    <Grid item xs={12}>
                        <FactoryDeviceQRcode device={device} />
                    </Grid>
                    <Grid item xs={12}>
                        <HardwareCard hardware={device.hardware} />
                    </Grid>
                    <Grid item xs={12}>
                        <FirmwaresList hardware={device.hardware} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

FactoryDeviceDetailsInternal.propTypes = {
    device: PropTypes.any,
    id: PropTypes.string,
}

export default function FactoryDeviceDetails(props) {
    const { id } = useParams();
    const database = useRegistrationDatabase();
    const [device, setDevice] = useState(null);

    useEffect(() => {
        try {
            const subscription = database
                .get('devices')
                .query(Q.where('id', id), Q.take(1))
                .observe()
                .subscribe(d => {
                    setDevice(d[0]);
                });

            return () => subscription.unsubscribe();
        } catch (e) {
            setDevice(null);
        }
    }, [id]);

    return (
        <FactoryDeviceDetailsInternal device={device} id={id} />
    );
}
