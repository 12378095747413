import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { BASE_ADMIN_API_URL } from '../../../../config';
import { useFetch } from '../../../misc/fetch';

export const useReactionLoadListAction = (sn) => {
    const fetch = useFetch();
    return useCallback(async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        try {
            const response = await fetch(
                `${BASE_ADMIN_API_URL}/actions/devices/${sn}/reactions/list/`,
                {
                    method: 'GET',
                });

            if (!response.ok) {
                throw new Error(await response.text());
            }
        } catch (e) {
            console.error(e);
        }

    }, []);
}

export default function ReactionsLoadListIconButton({ sn }) {
    const action = useReactionLoadListAction(sn);

    return (
        <IconButton onClick={action} aria-label="reload reactions">
            <RefreshIcon />
        </IconButton>
    )
}

ReactionsLoadListIconButton.propTypes = {
    sn: PropTypes.string
}
