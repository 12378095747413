import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NoMatchView() {

    const navigate = useNavigate();
    const handleClick = useCallback(() => {
        navigate(-1);
    }, []);

    return (
        <Box sx={{ textAlign:'center', alignItems: 'center' }}>
            <Typography>You are not going anywhere usefull.</Typography>
            <Button onClick={handleClick}>Go Back</Button>
        </Box>
    );
}
