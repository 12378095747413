import { Model, tableSchema } from '@nozbe/watermelondb'
import { date, field, nochange, readonly } from '@nozbe/watermelondb/decorators'

export const hardwareVersionSchema = tableSchema({
  name: 'hardware_versions',
  columns: [
    { name: 'type', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'bundle_file_id', type: 'string', isOptional: true },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class HardwareVersion extends Model {
  static table = 'hardware_versions'

  @nochange @field('type') type
  @nochange @field('version') version
  @nochange @field('bundle_file_id') bundle
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
};
