import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import QRCodeButton from './qrcode-button';

export const ParameterModalSelect = ({ parameter: { name, method }, config: { list }, value, handleChange, handleDone }) => {

    if (method !== 'select') {
        return null;
    }

    const onChange = (ev) => {
        const value = ev.target.value;
        handleChange(value);
        handleDone(value);
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="select-paramater-label">{name}</InputLabel>
            <Select
                autoFocus
                labelId="select-paramater-label"
                id="select-paramater"
                value={value}
                label={name}
                onChange={onChange}
            >
                {_.map(list, ({ id, name, description }) => (
                    <MenuItem key={id} value={id}>
                        <Stack spacing={1}>
                            <Typography>{name}</Typography>
                            {description && <Typography fontSize={12}>{description}</Typography>}
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

ParameterModalSelect.propTypes = {
    parameter: PropTypes.any,
    config: PropTypes.any,
    value: PropTypes.any,
    handleChange: PropTypes.func,
    handleDone: PropTypes.func,
}

export const ParameterModalInput = ({ parameter: { name, description, method, type }, config: { placeholder }, value, handleChange, handleDone }) => {

    if (method !== 'input') {
        return null;
    }

    const onChange = (ev) => {
        const value = ev.target.value;
        handleChange(value);
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Tab') {
            ev.preventDefault();
            ev.stopPropagation();
            handleDone();
        }
    }

    return (
        <TextField
            id="input"
            fullWidth
            autoFocus
            label={name}
            type={type}
            placeholder={placeholder}
            helperText={description}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyPress}
        />
    )
}

ParameterModalInput.propTypes = {
    parameter: PropTypes.any,
    config: PropTypes.any,
    value: PropTypes.any,
    handleChange: PropTypes.func,
    handleDone: PropTypes.func,
}

function OpenedProgrammerParameterModal({ parameter, config = { defaultValue: undefined }, setValue }) {
    const [value, setLocalValue] = useState(config.defaultValue === undefined ? null : config.defaultValue);
    const handleClose = () => {
        setValue('No user input');
    }

    const handleChange = (value) => {
        setLocalValue(value);
    }

    const handleDone = (forcedValue) => {
        setValue(null, forcedValue === undefined ? value : forcedValue);
    }

    return (
        <Dialog maxWidth='lg' fullWidth open={true} onClose={handleClose}>
            <DialogTitle>
                <Typography>Set the value of {parameter.name}</Typography>
                {parameter.description && <Typography fontSize={12}>{parameter.description}</Typography>}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ p: 2 }}>
                    <ParameterModalSelect parameter={parameter} config={config} value={value} handleChange={handleChange} handleDone={handleDone} />
                    <ParameterModalInput parameter={parameter} config={config} value={value} handleChange={handleChange} handleDone={handleDone} />
                </Box>
            </DialogContent>
            <DialogActions>
                <QRCodeButton value='set' onClick={() => handleDone()} >Set</QRCodeButton>
                <QRCodeButton color='inherit' value='cancel' onClick={handleClose}>Cancel</QRCodeButton>
            </DialogActions>
        </Dialog>
    )
}

OpenedProgrammerParameterModal.propTypes = {
    parameter: PropTypes.any,
    config: PropTypes.any,
    setValue: PropTypes.func,
}

export default function ProgrammerParameterModal({ open, ...props }) {

    if (!open) {
        return null;
    }

    return <OpenedProgrammerParameterModal {...props} />
}

ProgrammerParameterModal.propTypes = {
    parameter: PropTypes.any,
    config: PropTypes.any,
    open: PropTypes.bool,
    setValue: PropTypes.func,
}
