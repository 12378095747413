import { useField } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import ManualMovementPhaseName from '../ManualMovementPhaseName';
import StepBackBone, { useStepperController } from './step-backbone';

export default function ChangeName({ namespace, ...props }) {

  const { nextDisabled, setNextButtonDisabled } = useStepperController();
  const [field, meta] = useField(namespace + '.n');

  useEffect(() => {
      setNextButtonDisabled(meta.error);
  }, [meta.error, setNextButtonDisabled]);

  return (
    <StepBackBone nextDisabled={nextDisabled} {...props}>
      <ManualMovementPhaseName namespace={namespace + '.n'} />
    </StepBackBone>
  );
}

ChangeName.propTypes = {
  namespace: propTypes.string,
}
