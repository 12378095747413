import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';

const jobsContext = createContext({
  jobs: undefined,
  lock: false,
  start: (name, options) => console.error('early call to start()'),
  progress: (name, progress) => console.error('early call to progress()'),
  end: (name) => console.error('early call to finish()'),
  error: (name, error) => console.error('early call to error()'),
  remove: (name) => console.error('early call to remove()'),
});

export const useJobs = () => useContext(jobsContext).jobs;
export const useJobStart = () => useContext(jobsContext).start;
export const useJobProgress = () => useContext(jobsContext).progress;
export const useJobEnd = () => useContext(jobsContext).end;
export const useJobError = () => useContext(jobsContext).error;
export const useJobRemove = () => useContext(jobsContext).remove;
export const useJobLock = () => useContext(jobsContext).lock;

export default function JobsProvider({ children = null, ...props }) {

  const [lock, setLock] = useState(false);
  const [jobs, setJobs] = useState({});

  const start = useCallback((name, { indeterminate = false, lock = false, autoRemove = true, cancel, restart, icon, title } = {}) => {
    setJobs(jobs => {
      if (jobs[name]) {
        delete jobs[name];
      }
      const job = {
        lock,
        status: 'started',
        progress: indeterminate ? null : 0,
        message: '',
        autoRemove,
        title,
        icon,
        cancel,
        restart
      }
      if (lock) {
        setLock({ name, job });
      }
      return Object.assign({}, jobs, { [name]: job });
    })
  }, []);
  const progress = useCallback((name, progress) =>
    setJobs(jobs => {
      if (jobs[name]) {
        jobs[name].progress = progress;
      }
      return Object.assign({}, jobs);
    }), []);

  const remove = useCallback((name) =>
    setJobs(jobs => {
      jobs = Object.assign({}, jobs);
      delete jobs[name];
      return jobs;
    }), []);

  const end = useCallback((name) =>
    setJobs(jobs => {
      if (jobs[name]) {
        jobs[name].progress = 100;
        jobs[name].status = 'finished';
        if (jobs[name].lock) {
          setLock(false);
        }
        if (jobs[name].autoRemove) {
          setTimeout(() => remove(name), 3000);
        }
      }
      return Object.assign({}, jobs);
    }), []);

  const error = useCallback((name, error) =>
    setJobs(jobs => {
      if (jobs[name]) {
        jobs[name].progress = 100;
        jobs[name].status = 'error';
        jobs[name].message = error.message || error;
        if (jobs[name].lock) {
          setLock(false);
        }
        if (jobs[name].autoRemove) {
          setTimeout(() => remove(name), 3000);
        }
      }
      return Object.assign({}, jobs);
    }), []);

  const value = useMemo(() => ({ jobs, lock, start, progress, end, error, remove }), [jobs, lock, start, progress, end, error]);

  return (
    <jobsContext.Provider value={value}>
      {children}
    </jobsContext.Provider>
  )
}
