import React, { } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables'
import _ from 'lodash'
import { useParams } from 'react-router-dom';
import { withAfterSalesDatabase } from '../../../model/aftersales/AfterSalesDatabase';

export function UserDetailsInternal({ user }) {
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}><Typography>{user.name}</Typography></Grid>
                    <Grid item xs={12}><Typography>{user.mail}</Typography></Grid>
                    <Grid item xs={12}><Typography>{user.authProviderId || 'No auth provider'}</Typography></Grid>
                    <Grid item xs={12}><Typography>{user.updatedAt.toISOString()}</Typography></Grid>
                    <Grid item xs={12}><Typography>{user.createdAt.toISOString()}</Typography></Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

UserDetailsInternal.propTypes = {
    user: PropTypes.any
}

const observed = withObservables(['id'], ({ id, database }) => ({
    user: database.get('users').findAndObserve(id)
}));

const ObservedUserDetails = withAfterSalesDatabase(observed(UserDetailsInternal));

export default function UserDetails(props) {
    const { id } = useParams();
    return <ObservedUserDetails id={id} />
}
