import withObservables from '@nozbe/with-observables'
import { Q } from '@nozbe/watermelondb'
import { compose } from 'recompose';
import { withRegistrationDatabase } from '../../../model/registration/RegistrationDatabase';

export const observeProgrammers = compose(
    withRegistrationDatabase,
    withObservables([], ({ database, filterRevoked = false }) => {

        const queries = [];

        if (filterRevoked) {
            queries.push(Q.where('revoke', false));
        }

        if (queries.length > 0) {
            return {
                programmers: database.get('programmers').query(...queries)
            }
        } else {
            return {
                programmers: database.get('programmers').query()
            }
        }
    })
);

export const observeProgrammerById = compose(
    withRegistrationDatabase,
    withObservables([], ({ database, id }) => {
        return {
            programmer: database.get('programmers').findAndObserve(id)
        }
    })
);
