import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack, IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { defaultValue as MMPNdefault, ManualMovementPhaseNameText, schema as MMPNschema } from './ManualMovementPhaseName';
import { defaultValue as SIdefault, schema as SISchema } from '../sensor-inputs/SensorInputs';
import { defaultValue as Edefault, schema as ESchema } from '../expressions/ExpressionsList';
import { defaultValue as ROdefault, schema as ROSchema } from '../reaction-outputs/ReactionOutputs';
import { defaultValue as OAdefault, schema as OASchema } from '../activations/OutputActivation';
import ManualMovementPhaseDialog, { useConfigureManualMovementPhaseController } from './ManualMovementPhaseDialog';
import { useField } from 'formik';
import * as Yup from 'yup';

export const schema = Yup.object({
  n: MMPNschema,
  i: SISchema,
  e: ESchema,
  a: OASchema,
  o: ROSchema
});

export const defaultValue = {
  n: MMPNdefault,
  i: SIdefault,
  e: Edefault,
  a: OAdefault,
  o: ROdefault
};

export function ManualMovementPhase({ namespace, onRemove, onCopy, ...props }) {

  const [open, handleOpen, handleClose] = useConfigureManualMovementPhaseController();

  const [field, meta] = useField(namespace);

  const border = meta.error ? '1px dashed red' : '1px solid grey'

  return (
    <Box sx={{ p: 2, border }}>
      <Stack spacing={1}>
        <ManualMovementPhaseNameText namespace={namespace + '.n'} />
        <Stack spacing={1} direction='row' alignItems='center' justifyContent='space-evenly'>
          <IconButton onClick={handleOpen}><SettingsIcon /></IconButton>
          <IconButton onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            onCopy(_.cloneDeep(field.value))
          }}><ContentCopyIcon /></IconButton>
          <IconButton onClick={onRemove}><DeleteIcon /></IconButton>
          <ManualMovementPhaseDialog {...props} open={open} onClose={handleClose} namespace={namespace} />
        </Stack>
      </Stack>
    </Box>
  )
}

ManualMovementPhase.propTypes = {
  namespace: propTypes.string,
  onRemove: propTypes.func,
  onCopy: propTypes.func,
}