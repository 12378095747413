import React, { Fragment, useCallback } from 'react';
import { Button } from '@mui/material';
import { BASE_ADMIN_API_URL } from '../../../../config';
import { useOnlineDeviceIds } from './online-device-provider';
import { useFetch } from '../../../misc/fetch';

export function useLaunchPing(serialNumber) {
    const fetch = useFetch();
    const launchRebootCb = useCallback(async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        const response = await fetch(
            `${BASE_ADMIN_API_URL}/actions/devices/${serialNumber}/device/ping`,
            {
                method: 'GET',
            });

        if (!response.ok) {
            throw new Error(await response.text())
        }
    }, [serialNumber]);
    return launchRebootCb;
}

export default function PingButton() {
    const { serialNumber } = useOnlineDeviceIds();
    const handleLaunchPing = useLaunchPing(serialNumber);

    return (
        <Fragment>
            <Button onClick={handleLaunchPing} aria-label="launch reboot">
                Ping
            </Button>
        </Fragment>
    );
}

PingButton.propTypes = {

}
