import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSocket } from './connection-provider';

const CapabilitiesContext = createContext({
    capabilities: null,
    loading: null,
    load: () => console.error('early call to CapabilitiesContext::load() method'),
});

export const useCapabilities = () => {
    const capabilities = useContext(CapabilitiesContext).capabilities;
    return capabilities;
}

export const useCapability = (capability) => {
    const capabilities = useContext(CapabilitiesContext).capabilities;
    return _.find(capabilities, cap => cap.name === capability);
}

export const useTasks = (capability) => {
    capability = useCapability(capability)
    if (capability === undefined) {
        return [];
    }
    return capability.tasks;
}

export const useTask = (capability, task) => {
    const tasks = useTasks(capability);
    return _.find(tasks, t => t.name === task);
}

export const useSteps = (capability, task) => {
    task = useTasks(capability, task);
    if (task === undefined) {
        return [];
    }
    return task.steps;
}

export const useListCapabilities = () => {
    const socket = useSocket();
    const [capabilities, setCapabilities] = useState(null);
    const [loading, setLoading] = useState(false);
    const load = useCallback(() => {
        setLoading(true);
        socket.emit('capabilities:list', undefined, ({ error, capabilities }) => {
            if (error) {
                setLoading(false);
                return;
            }
            setCapabilities(capabilities);
            setLoading(false);
        });
    }, []);
    return [capabilities, load, loading];
}

export default function ProgrammerCapabilitiesProvider({ children }) {

    const [capabilities, load, loading] = useListCapabilities();

    useEffect(() => {

        load();

        return () => {

        }
    }, []);

    return (
        <CapabilitiesContext.Provider value={{ capabilities, load, loading }}>
            {children}
        </CapabilitiesContext.Provider>
    )
}

ProgrammerCapabilitiesProvider.propTypes = {
    children: PropTypes.any,
}
