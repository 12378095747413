import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { observeDevicesListFromQuery } from './device-list-query-builder';
import { IconButton, Tooltip } from '@mui/material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

function LabelCsvExportButton({ devices, query }) {

    const data = useMemo(() => _.flatMap(devices, (device) => [
        [device.id, device.hardware, JSON.stringify({ sn: device.id, type: device.hardware })],
        [device.id, device.hardware, JSON.stringify({ sn: device.id, type: device.hardware })],
        [device.id, device.hardware, JSON.stringify({ sn: device.id, type: device.hardware })],
        [device.id, device.hardware, JSON.stringify({ sn: device.id, type: device.hardware })]
    ]), [devices]);

    return (
        <CSVLink filename={`labels-${query.status}-${data.length / 4}.csv`} data={data} separator={";"} enclosingCharacter={``}>
            <Tooltip title={`Download Labels CSV (${data.length} labels, ${data.length / 4} devices)`}>
                <IconButton>
                    <LocalPrintshopIcon />
                </IconButton>
            </Tooltip>
        </CSVLink>
    );
}

LabelCsvExportButton.propTypes = {
    devices: PropTypes.array,
    query: PropTypes.any,
}

export default observeDevicesListFromQuery(LabelCsvExportButton);