
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Chip, Grid, Link, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { BASE_REGISTRATION_API_URL, BASE_ADMIN_API_URL, BASE_DATA_API_URL } from '../../../config';
import { useEffect } from 'react';


const useServiceStatus = (url) => {
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [lastUpdate, setLastUpdate] = useState(null);

    useEffect(() => {
        const work = async () => {
            setLoading(true);
            try {
                const response = await fetch(url + '/health');
                if (!response.ok) {
                    throw new Error(await response.text());
                }
                setStatus(await response.json());
                setError(null);
            } catch (e) {
                setStatus(null);
                setError(e);
            } finally {
                setLastUpdate(new Date());
                setLoading(false);
            }
        };
        const interval = setInterval(work, 60000);
        work();
        return () => clearInterval(interval);
    }, [url]);
    return [status, loading, error, lastUpdate]
}

const ServiceStatus = ({ serviceUrl, name, ...props }) => {
    const [status, loading, error, lastUpdate] = useServiceStatus(serviceUrl);
    return (
        <Card {...props}>
            <CardHeader title={name} subheader={`Status`} />
            <CardContent>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell>Service URL</TableCell>
                            <TableCell><Link target='_blank' href={serviceUrl + '/health'}>{serviceUrl}</Link></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell><Typography>{status?.status === 'online' ?
                                <Chip label='online' color='success' /> :
                                <Chip label='offline' variant='outlined' color={error ? 'error' : undefined} />}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Version</TableCell>
                            <TableCell><Typography>{status?.version}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Last Update</TableCell>
                            <TableCell><Typography>{loading ? 'Loading ...' : lastUpdate?.toLocaleTimeString()}</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}

ServiceStatus.propTypes = {
    name: PropTypes.string.isRequired,
    serviceUrl: PropTypes.string.isRequired,
}

export default function ServicesStatus() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant='h4'>Services Status</Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2} direction={'row'}>
                    <ServiceStatus serviceUrl={BASE_ADMIN_API_URL} name='Administration Service' />
                    <ServiceStatus serviceUrl={BASE_REGISTRATION_API_URL} name='Registration Service' />
                    <ServiceStatus serviceUrl={BASE_DATA_API_URL} name='Data Service' />
                </Stack>
            </Grid>
        </Grid>
    )
}

ServicesStatus.propTypes = {}
