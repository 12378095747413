
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import _ from 'lodash';
import { QRCodeSVG } from 'qrcode.react';

export function QRCodeHandler({ disabled = false, value = 'a', control = '\r', onClick }) {

    useEffect(() => {
        let input = [];
        const keycodes = (value).split('').map(c => c.charCodeAt(0));
        let match = false;

        const handler = (ev) => {
            if (ev.key === 'Tab') {
                input = [];

                if (match) {
                    match = false;
                    ev.stopPropagation();
                    ev.preventDefault();
                    onClick(ev);
                }
            } else if (ev.keyCode === control.charCodeAt(0)) {
                if (_.isEqual(keycodes, input)) {
                    ev.stopPropagation();
                    match = true;
                } else {
                    input = [];
                }
            } else {
                if (!['Shift'].includes(ev.key)) {
                    input.push(ev.key.charCodeAt(0));
                }
            }
        }
        window.addEventListener('keydown', handler, true);
        return () => {
            window.removeEventListener('keydown', handler, true);
        }
    }, [value, control, onClick]);

    const fg = 'black';
    const bg = 'white';

    if (disabled) {
        return null;
    }

    return (
        <Box sx={{ p: 0.5 }} bgcolor={'white'}>
            <QRCodeSVG display={'block'} size={32}
                fgColor={fg}
                bgColor={bg}
                value={value + control} />
        </Box>
    )
}

QRCodeHandler.propTypes = {
    value: PropTypes.string,
    control: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
}

export default function QRCodeButton({ value, control, disabled, onClick, children, ...props }) {
    return (
        <Button {...props} disabled={disabled} variant='outlined' size='size' onClick={onClick} endIcon={(
            <QRCodeHandler disabled={disabled} value={value} control={control} onClick={onClick} />
        )}>
            {children}
        </Button>
    )
}

QRCodeButton.propTypes = {
    value: PropTypes.string,
    control: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.any,
}
