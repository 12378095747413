
import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { CONNECTED } from '../../../model/registration/Programmer';

export default function ProgrammerStatusChip({ programmer, ...props }) {

    const connection = programmer.connection;
    const content = connection === CONNECTED ? 'AVAILABLE' : 'UNAVAILABLE';
    const color = connection === CONNECTED ? 'success' : 'error'

    return (
        <Chip {...props} label={content} color={color} variant='outlined' />
    );
}

ProgrammerStatusChip.propTypes = {
    programmer: PropTypes.any,
}
