import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Card, CardMedia, CardContent, Stack, Typography } from '@mui/material';
import preview from './top-view.png';

export function WyesControllers0SelectItem() {
  return (
    <Stack sx={{ width: '100%' }} direction="row" alignItems='center' justifyContent='space-between'>
      <Typography>Wyes Controllers 0</Typography>
      <img height='50px' src={preview} />
    </Stack>
  )
}

export default function WyesController0({ children = null, onClick = () => { }, ...props }) {
  return (
    <Card {...props} onClick={onClick}>
      <CardMedia
        component="img"
        sx={{ p: 1, height: '200px', objectFit: 'contain' }}
        src={preview}
      ></CardMedia>
      {children && (
        <CardContent>
          {children}
        </CardContent>
      )}
    </Card>
  );
}

WyesController0.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
}
