
import React, { useRef } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Autocomplete, Chip, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { useField } from 'formik';
import { Buffer } from 'buffer';
import DeleteIcon from '@mui/icons-material/Delete';
import { USB_KEYBOARD_KEY_TO_VALUE, USB_KEYBOARD_MODIFIERS, USB_KEYBOARD_VALUE_TO_KEY } from './usb-keyboard-layout';
import { getLayoutCodeFromKey, getLayoutKey, getLayoutSymbol } from './layouts';

export const INITIAL_VALUE = '0000';

const clearReport = (setValue) => {
    setValue(INITIAL_VALUE);
}

const buildReport = (report, setReport, layout) => (ev) => {
    const { code } = ev;
    //console.log({ code, key: ev.key, US: getLayoutKey(0, code) });
    // report is too small
    if (report.length / 2 < 2) {
        report = INITIAL_VALUE;
    }

    // report is full
    if (report.length / 2 === 8) {
        return;
    }

    const key = getLayoutKey(layout, code);
    if (key) {
        // Get the modifiers first.
        const modifier = USB_KEYBOARD_MODIFIERS[key];
        if (modifier) {
            const buffer = Buffer.from(report, 'hex');
            buffer.writeUInt8(buffer.readUInt8(0) | modifier);
            report = buffer.toString('hex');
            setReport(report);
            return;
        }

        // Then check for another key.
        const byte = USB_KEYBOARD_KEY_TO_VALUE[key];
        if (byte) {
            const buffer = Buffer.alloc(1, byte);
            report = report + buffer.toString('hex');
            setReport(report);
            return;
        }
    }
}

const decodeReport = (report, layout) => {
    const buffer = Buffer.from(report, 'hex');
    const value = [];

    // Check for modifiers
    const modifier = buffer.readUInt8(0);
    _.each(USB_KEYBOARD_MODIFIERS, (mask, key) => {
        if (modifier & mask) {
            const code = getLayoutCodeFromKey(layout, key);
            const name = getLayoutSymbol(layout, code);
            if (name) {
                value.push({ name, code, modifier: true });
            }
        }
    });

    // Then check the 6 other keys
    for (let i = 2; i < buffer.length; i++) {
        const byte = buffer.readUint8(i);
        const key = USB_KEYBOARD_VALUE_TO_KEY[byte];
        if (key) {
            const code = getLayoutCodeFromKey(layout, key);
            if (code) {
                const name = getLayoutSymbol(layout, code);
                if (name) {
                    value.push({ name, code });
                }
            }
        }
    }

    return value;
}

export default function KeyboardReportField({ name, layoutName, ...props }) {

    const [{ value }, meta, { setValue }] = useField(name);
    const [{ value: layout }] = useField(layoutName);

    const rebuild = useRef();

    return (
        <Autocomplete
            multiple
            readOnly
            freeSolo
            fullWidth
            id="report-builder"
            options={[]}
            onKeyUp={() => {
                rebuild.current = true;
            }}
            onKeyDown={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                if (ev.repeat) {
                    return;
                }

                let localvalue = value;
                if (rebuild.current) {
                    clearReport(setValue);
                    rebuild.current = false;
                    localvalue = INITIAL_VALUE;
                }
                buildReport(localvalue, setValue, layout)(ev);
            }}
            value={decodeReport(value, layout)}
            renderTags={(value, getTagProps) => {
                return value.map(({ name, code, modifier }, index) => (
                    <Tooltip key={code} title={code}>
                        <Chip
                            variant="outlined"
                            color={modifier ? 'primary' : undefined}
                            label={name}
                            {...getTagProps({ index })} />
                    </Tooltip>
                ))
            }}
            renderInput={(params) => (
                <TextField
                    autoFocus
                    {...params}
                    label='Keys'
                    inputProps={{
                        ...params.inputProps,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="reset keyboard keys"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    clearReport(setValue);
                                }}
                                edge="end"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
            )}
        />
    );
}

KeyboardReportField.propTypes = {
    name: propTypes.string,
    layoutName: propTypes.string,
}
