import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OnlineDevicesList from './live/list';
import OnlineDeviceDetails from './live/details';
import FactoryDevicesList from './factory/list';
import FactoryDeviceDetails from './factory/details';
import { useIsAfterSalesAdmin, useIsFactoryAdmin } from '@wyes/react-auth0';

export function OnlineDevicesView() {
    const isFactoryAdmin = useIsFactoryAdmin();
    const isAfterSalesAdmin = useIsAfterSalesAdmin();
    if(!(isFactoryAdmin || isAfterSalesAdmin)) return null;

    return (
        <Routes>
            <Route path='/' exact element={<OnlineDevicesList />} />
            <Route path='/:id/details' element={<OnlineDeviceDetails />} />
        </Routes>
    );
}

export function FactoryDevicesView() {
    const isFactoryAdmin = useIsFactoryAdmin();
    if(!isFactoryAdmin) return null;

    return (
        <Routes>
            <Route path='/' exact element={<FactoryDevicesList />} />
            <Route path='/:id/details' element={<FactoryDeviceDetails />} />
        </Routes>
    );
}
