import React from "react";
import PropTypes from 'prop-types';
import { Field, useField } from "formik";
import { TextField } from "@mui/material";

export default function FileField({ name, label, ...props }) {

    const [field, meta, helpers] = useField(name);

    const { value, error } = meta;
    const { setValue } = helpers;

    const handleChange = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValue(ev.target.files[0]);
    }

    return (
        <TextField {...props} error={Boolean(error)} helperText={error} label={label} placeholder={label} type='file' onChange={handleChange} />
    );
};

FileField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
}