import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { HARDWARES } from '../../../../model/Hardware';

export default function SelectDeviceHardware({ hardware, setHardware }) {
    const handleChange = (event) => {
        setHardware(event.target.value);
    };

    return (
        <TextField
            id="select-hardware"
            size="small"
            select
            label="Select"
            value={hardware}
            onChange={handleChange}
        >
            <MenuItem value={'null'}>
                ALL
            </MenuItem>
            {HARDWARES.map((hardware) => (
                <MenuItem key={hardware} value={hardware}>
                    {hardware}
                </MenuItem>
            ))}
        </TextField>
    );
}

SelectDeviceHardware.propTypes = {
    hardware: PropTypes.string,
    setHardware: PropTypes.func,
}
