import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import { Field, useField } from 'formik';
import * as Yup from 'yup';
import cuid from 'cuid';

export const type = "text";
export const label = "Identifier";
export const defaultValue = '';
export const schema = Yup.string().nullable();

export const movementDefaultValue = () => cuid().slice(1, 13);

export default function MovementId({ namespace, className, ...props }) {

  const [{ value }, meta, { setValue }] = useField(namespace);

  useEffect(() => {
    if (value === '') {
      setValue(movementDefaultValue());
    }
  }, []);

  return (
    <Field
      size='small'
      className={className}
      placeholder={label}
      component={TextField}
      name={namespace}
      type={type}
      disabled
      onClick={(ev) => ev.stopPropagation()}
    />
  );
}

MovementId.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
}