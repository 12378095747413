import React from 'react';
import propTypes from 'prop-types';
import { useField } from 'formik';
import * as Yup from 'yup';
import { MenuItem, TextField } from '@mui/material';

export const type = "number";
export const label = "Fires event";
export const defaultValue = 1;
export const schema = Yup.number().required('Required');

export default function ReactionOutputEvent({ events, namespace, className, isSubmitting, ...props }) {

  const [{ value }, meta, { setValue }] = useField(namespace);

  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
  };

  return (
    <TextField
      size='small'
      name={namespace}
      className={className}
      label={label}
      placeholder={label}
      type={type}
      disabled={isSubmitting}
      value={value.toString()}
      onChange={handleChange}
      select>
      {_.map(events, (event) => <MenuItem key={event.i} value={event.i.toString()}>{event.n.toUpperCase()}</MenuItem>)}
    </TextField>
  );
}

ReactionOutputEvent.propTypes = {
  events: propTypes.array,
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
}