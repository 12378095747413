import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { useOnlineDeviceApi, useOnlineDeviceIds, useOnlineDeviceMovements } from '../live/online-device-provider';
import MovementCard from './movement-card';
import AddMovementButton from './add-movement-button';
import { topicBuilder, useMqttListener } from '@wyes/web-react-devices';
import MovementsLoadListIconButton from './movements-load-list';

export const useMovementsListListener = (serialNumber, cb) => {
    const listener = useCallback((data) => {
        cb(data);
    }, [cb]);
    const topic = useMemo(() => topicBuilder(`v1/dev/${serialNumber}/d/movements/list`), [serialNumber]);
    useMqttListener(topic, listener);
}


export default function MovementsList({ ...props }) {

    const { serialNumber } = useOnlineDeviceIds();
    const { setMovements } = useOnlineDeviceApi();
    const movements = useOnlineDeviceMovements();

    useMovementsListListener(serialNumber, setMovements);

    const limit = 100;

    return (
        <Card>
            <CardHeader title={`Movements (${movements.length})`} action={<MovementsLoadListIconButton sn={serialNumber} />} />
            <CardContent>
                <Stack direction='row' alignItems='center' spacing={2}>
                    {movements.map(m => <MovementCard key={m.i} movement={m} canCopy={movements.length < limit} />)}
                    {movements.length < limit && (
                        <Stack flexGrow={1} direction='row' alignItems='center' justifyContent='center'>
                            <AddMovementButton sn={serialNumber} />
                        </Stack>
                    )}
                </Stack>
            </CardContent>
        </Card>

    )
}

MovementsList.propTypes = {

}