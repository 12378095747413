import React from 'react';
import PropTypes from 'prop-types';
import WyesGlasses0 from './contactors/wyes-glasses-0';
import WyesContactors0 from './extensions/wyes-contactors-0';
import WyesController0 from './controllers/wyes-controllers-0';
import { W_GLASSES_0, W_CONTACTORS_0, W_CONTROLLERS_0 } from '../../../model/Hardware';

const HardwareTypeToCard = {
    [W_GLASSES_0]: WyesGlasses0,
    [W_CONTACTORS_0]: WyesContactors0,
    [W_CONTROLLERS_0]: WyesController0,
}

function HardwareCard({ hardware, ...props }) {
    const Comp = HardwareTypeToCard[hardware] || null;
    if(Comp === null) return null;
    return React.createElement(Comp, props);
}

HardwareCard.propTypes = {
    hardware: PropTypes.string,
}

export default HardwareCard;
