import React, { useCallback, useMemo } from 'react';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { createReaction, useOnlineDeviceApi, useOnlineDeviceIds, useOnlineDeviceReactions } from '../live/online-device-provider';
import ReactionCard from './reaction-card';
import ReactionsLoadListIconButton from './reactions-load-list';
import { topicBuilder, useMqttListener } from '@wyes/web-react-devices';

export const useReactionsListListener = (serialNumber, cb) => {
    const listener = useCallback((data) => {
        data = data.map(d => createReaction(d));
        cb(data);
    }, [cb]);
    const topic = useMemo(() => topicBuilder(`v1/dev/${serialNumber}/d/reactions/list`), [serialNumber]);
    useMqttListener(topic, listener);
}

export default function ReactionsList() {

    const { serialNumber } = useOnlineDeviceIds();
    const { setReactions } = useOnlineDeviceApi();
    const reactions = useOnlineDeviceReactions();

    useReactionsListListener(serialNumber, setReactions);

    return (
        <Card>
            <CardHeader 
                title={`Reactions (${reactions.length})`}
                action={<ReactionsLoadListIconButton sn={serialNumber} />} />
            <CardContent>
                <Stack spacing={2} direction='column' alignItems='left'>
                    {reactions.map(r => <ReactionCard key={r.k} reaction={r} />)}
                </Stack>
            </CardContent>
        </Card>

    )
}
