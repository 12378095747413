import { Model, tableSchema } from '@nozbe/watermelondb'
import { date, field, nochange, readonly } from '@nozbe/watermelondb/decorators'

export const userSchema = tableSchema({
  name: 'users',
  columns: [
    { name: 'name', type: 'string' },
    { name: 'mail', type: 'string' },
    { name: 'auth_provider_id', type: 'string', isOptional: true },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class User extends Model {
  static table = 'users'

  @field('name') name
  @field('mail') mail
  @field('auth_provider_id') authProviderId
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
};
