import { Model, tableSchema } from '@nozbe/watermelondb'
import { date, field, readonly, writer } from '@nozbe/watermelondb/decorators'

export const firmwareSchema = tableSchema({
  name: 'firmwares',
  columns: [
    { name: 'type', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'revoke', type: 'boolean' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
    { name: 'factory_file_id', type: 'string', isOptional: true },
    { name: 'ota_file_id', type: 'string', isOptional: true },
  ]
});

export default class Firmware extends Model {
  static table = 'firmwares'

  @readonly @field('type') type
  @readonly @field('version') version
  @field('revoke') revoke
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @readonly @field('factory_file_id') factoryFile
  @readonly @field('ota_file_id') otaFile

  @writer async markRevoked() {
    await this.update(firmware => {
      firmware.revoke = true
    })
  }
};
