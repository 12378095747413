import React, { useCallback, useMemo } from 'react';
import { IconButton } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { BASE_ADMIN_API_URL } from '../../../../config';
import { useOnlineDeviceApi, useOnlineDeviceIds } from './online-device-provider';
import { topicBuilder, useMqttListener } from '@wyes/web-react-devices';
import { useFetch } from '../../../misc/fetch';

export function useListBleConnections() {
    const fetch = useFetch();
    const listBleConnectionsCb = useCallback(async (serialNumber) => {
        const response = await fetch(
            `${BASE_ADMIN_API_URL}/actions/devices/${serialNumber}/device/list-ble-connections`,
            {
                method: 'GET',
            });

        if (!response.ok) {
            throw new Error(await response.text());
        }
    }, []);
    return listBleConnectionsCb;
}

export function useListBleConnectionsListener(serialNumber, cb) {
    const listener = useCallback((data) => {
        cb(data);
    }, [cb]);
    const topic = useMemo(() => topicBuilder(`v1/dev/${serialNumber}/d/connections/list`), [serialNumber]);
    useMqttListener(topic, listener);
}

export default function ListBLEConnectionsButton() {
    const { serialNumber } = useOnlineDeviceIds();
    const { setConnections } = useOnlineDeviceApi();
    useListBleConnectionsListener(serialNumber, setConnections);

    const handleList = useListBleConnections();

    return (
        <IconButton onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            handleList(serialNumber);
        }}>
            <SyncIcon />
        </IconButton>
    );
}
