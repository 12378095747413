
// from us international to us international
export const US_INTERNATIONAL_MAPPING = {
    KeyA: { key: 'KEY_A', symbol: 'A' },
    KeyB: { key: 'KEY_B', symbol: 'B' },
    KeyC: { key: 'KEY_C', symbol: 'C' },
    KeyD: { key: 'KEY_D', symbol: 'D' },
    KeyE: { key: 'KEY_E', symbol: 'E' },
    KeyF: { key: 'KEY_F', symbol: 'F' },
    KeyG: { key: 'KEY_G', symbol: 'G' },
    KeyH: { key: 'KEY_H', symbol: 'H' },
    KeyI: { key: 'KEY_I', symbol: 'I' },
    KeyJ: { key: 'KEY_J', symbol: 'J' },
    KeyK: { key: 'KEY_K', symbol: 'K' },
    KeyL: { key: 'KEY_L', symbol: 'L' },
    KeyM: { key: 'KEY_M', symbol: 'M' },
    KeyN: { key: 'KEY_N', symbol: 'N' },
    KeyO: { key: 'KEY_O', symbol: 'O' },
    KeyP: { key: 'KEY_P', symbol: 'P' },
    KeyQ: { key: 'KEY_Q', symbol: 'Q' },
    KeyR: { key: 'KEY_R', symbol: 'R' },
    KeyS: { key: 'KEY_S', symbol: 'S' },
    KeyT: { key: 'KEY_T', symbol: 'T' },
    KeyU: { key: 'KEY_U', symbol: 'U' },
    KeyV: { key: 'KEY_V', symbol: 'V' },
    KeyW: { key: 'KEY_W', symbol: 'W' },
    KeyX: { key: 'KEY_X', symbol: 'X' },
    KeyY: { key: 'KEY_Y', symbol: 'Y' },
    KeyZ: { key: 'KEY_Z', symbol: 'Z' },

    Digit1: { key: 'KEY_1', symbol: '1' },
    Digit2: { key: 'KEY_2', symbol: '2' },
    Digit3: { key: 'KEY_3', symbol: '3' },
    Digit4: { key: 'KEY_4', symbol: '4' },
    Digit5: { key: 'KEY_5', symbol: '5' },
    Digit6: { key: 'KEY_6', symbol: '6' },
    Digit7: { key: 'KEY_7', symbol: '7' },
    Digit8: { key: 'KEY_8', symbol: '8' },
    Digit9: { key: 'KEY_9', symbol: '9' },
    Digit0: { key: 'KEY_0', symbol: '0' },

    Enter: { key: 'KEY_ENTER', symbol: 'Enter' },
    Escape: { key: 'KEY_ESC', symbol: 'Esc' },
    Backspace: { key: 'KEY_BACKSPACE', symbol: 'Delete' },
    Tab: { key: 'KEY_TAB', symbol: 'Tab' },
    Space: { key: 'KEY_SPACE', symbol: 'Space' },
    Minus: { key: 'KEY_MINUS', symbol: '-' },
    Equal: { key: 'KEY_EQUAL', symbol: '+' },
    BracketLeft: { key: 'KEY_LEFTBRACE', symbol: '[' },
    BracketRight: { key: 'KEY_RIGHTBRACE', symbol: ']' },
    Backslash: { key: 'KEY_BACKSLASH', symbol: '\\' },
    IntlBackslash: { key: 'KEY_HASHTILDE', symbol: '#' },
    Semicolon: { key: 'KEY_SEMICOLON', symbol: ';' },
    Quote: { key: 'KEY_APOSTROPHE', symbol: '\'' },
    Backquote: { key: 'KEY_GRAVE', symbol: '`' },
    Comma: { key: 'KEY_COMMA', symbol: ',' },
    Period: { key: 'KEY_DOT', symbol: '.' },
    Slash: { key: 'KEY_SLASH', symbol: '/' },
    CapsLock: { key: 'KEY_CAPSLOCK', symbol: 'CapsLock' },

    F1: { key: 'KEY_F1', symbol: 'F1' },
    F2: { key: 'KEY_F2', symbol: 'F2' },
    F3: { key: 'KEY_F3', symbol: 'F3' },
    F4: { key: 'KEY_F4', symbol: 'F4' },
    F5: { key: 'KEY_F5', symbol: 'F5' },
    F6: { key: 'KEY_F6', symbol: 'F6' },
    F7: { key: 'KEY_F7', symbol: 'F7' },
    F8: { key: 'KEY_F8', symbol: 'F8' },
    F9: { key: 'KEY_F9', symbol: 'F9' },
    F10: { key: 'KEY_F10', symbol: 'F10' },
    F11: { key: 'KEY_F11', symbol: 'F11' },
    F12: { key: 'KEY_F12', symbol: 'F12' },
    F13: { key: 'KEY_F13', symbol: 'F13' },
    F14: { key: 'KEY_F14', symbol: 'F14' },
    F15: { key: 'KEY_F15', symbol: 'F15' },
    F16: { key: 'KEY_F16', symbol: 'F16' },
    F17: { key: 'KEY_F17', symbol: 'F17' },
    F18: { key: 'KEY_F18', symbol: 'F18' },
    F19: { key: 'KEY_F19', symbol: 'F19' },
    F20: { key: 'KEY_F20', symbol: 'F20' },
    F21: { key: 'KEY_F21', symbol: 'F21' },
    F22: { key: 'KEY_F22', symbol: 'F22' },
    F23: { key: 'KEY_F23', symbol: 'F23' },
    F24: { key: 'KEY_F24', symbol: 'F24' },

    PrintScreen: { key: 'KEY_SYSRQ', symbol: 'PrintScreen' },
    ScrollLock: { key: 'KEY_SCROLLLOCK', symbol: 'Scroll Lock' },
    Pause: { key: 'KEY_PAUSE', symbol: 'Pause' },
    Insert: { key: 'KEY_INSERT', symbol: 'Insert' },
    Home: { key: 'KEY_HOME', symbol: 'Home' },
    PageUp: { key: 'KEY_PAGEUP', symbol: 'Page Up' },
    Delete: { key: 'KEY_DELETE', symbol: 'Suppr' },
    End: { key: 'KEY_END', symbol: 'End' },
    PageDown: { key: 'KEY_PAGEDOWN', symbol: 'Page Down' },
    ArrowRight: { key: 'KEY_RIGHT', symbol: 'Right' },
    ArrowLeft: { key: 'KEY_LEFT', symbol: 'Left' },
    ArrowDown: { key: 'KEY_DOWN', symbol: 'Down' },
    ArrowUp: { key: 'KEY_UP', symbol: 'Up' },

    // Keyboard keypad
    NumLock: { key: 'KEY_NUMLOCK', symbol: 'NumLock' },
    NumpadDivide: { key: 'KEY_KPSLASH', symbol: 'Keypad /' },
    NumpadMultiply: { key: 'KEY_KPASTERISK', symbol: 'Keypad *' },
    NumpadSubtract: { key: 'KEY_KPMINUS', symbol: 'Keypad -' },
    NumpadAdd: { key: 'KEY_KPPLUS', symbol: 'Keypad +' },
    NumpadEnter: { key: 'KEY_KPENTER', symbol: 'Keypad Enter' },
    Numpad1: { key: 'KEY_KP1', symbol: 'Keypad 1' },
    Numpad2: { key: 'KEY_KP2', symbol: 'Keypad 2' },
    Numpad3: { key: 'KEY_KP3', symbol: 'Keypad 3' },
    Numpad4: { key: 'KEY_KP4', symbol: 'Keypad 4' },
    Numpad5: { key: 'KEY_KP5', symbol: 'Keypad 5' },
    Numpad6: { key: 'KEY_KP6', symbol: 'Keypad 6' },
    Numpad7: { key: 'KEY_KP7', symbol: 'Keypad 7' },
    Numpad8: { key: 'KEY_KP8', symbol: 'Keypad 8' },
    Numpad9: { key: 'KEY_KP9', symbol: 'Keypad 9' },
    Numpad0: { key: 'KEY_KP0', symbol: 'Keypad 0' },
    NumpadDecimal: { key: 'KEY_KPDOT', symbol: 'Keypad .' },
    NumpadEqual: { key: 'KEY_KPEQUAL', symbol: 'Keypad =' },

    ControlLeft: { key: 'KEY_LEFTCTRL', symbol: 'Ctrl Left' },
    ShiftLeft: { key: 'KEY_LEFTSHIFT', symbol: 'Shift Left' },
    AltLeft: { key: 'KEY_LEFTALT', symbol: 'Alt Left' },
    MetaLeft: { key: 'KEY_LEFTMETA', symbol: 'Meta Left' },
    ControlRight: { key: 'KEY_RIGHTCTRL', symbol: 'Ctrl Right' },
    ShiftRight: { key: 'KEY_RIGHTSHIFT', symbol: 'Shift Right' },
    AltRight: { key: 'KEY_RIGHTALT', symbol: 'Alt Right' },
    MetaRight: { key: 'KEY_RIGHTMETA', symbol: 'Meta Right' },
}

