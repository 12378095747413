import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { BASE_REGISTRATION_API_URL } from '../../../config';
import FileField from '../../misc/forms/file-field';
import * as Yup from 'yup';
import HardwareSelectField from '../hardwares/hardware-select-field';
import { useAfterSalesSynchronizeDB } from '../../../model/aftersales/AfterSalesDatabase';
import { useFetch } from '../../misc/fetch';

export function useCreateFirmware() {
    const fetch = useFetch();
    const sync = useAfterSalesSynchronizeDB
    const addCallback = useCallback(async ({ hardware, version, factory, dfu }) => {
        const body = new FormData();
        body.append('factory', factory);
        if (dfu) {
            body.append('dfu', dfu);
        }
        const response = await fetch(
            `${BASE_REGISTRATION_API_URL}/firmwares/${hardware}/${version}`,
            {
                method: 'PUT',
                body
            });

        if (!response.ok) {
            throw new Error(await response.text())
        }

        await sync();
    }, []);
    return addCallback;
}

const FirmwareCreationSchema = Yup.object().shape({
    version: Yup.string().required('Required'),
    hardware: Yup.string().required('Required'),
    factory: Yup.mixed(input => input instanceof File).required('Required'),
    dfu: Yup.mixed(input => input instanceof File),
});

export function AddFirmwareModal({ open, onClose, hardware = '', version = '' }) {

    const handleCreate = useCreateFirmware();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add a firmware</DialogTitle>
            <Formik
                initialValues={{ version, hardware, factory: null, dfu: null }}
                validationSchema={FirmwareCreationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    await handleCreate(values);
                    setSubmitting(false);
                    onClose();
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field component={TextField} disabled={Boolean(version)} name="version" type="text" label="Version" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <HardwareSelectField disabled={Boolean(hardware)} name="hardware" label="Hardware" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileField name="factory" label="Factory" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileField name="dfu" label="DFU" fullWidth />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isSubmitting} onClick={onClose} color='inherit' >Cancel</Button>
                            <Button disabled={isSubmitting} type='submit' color='secondary' >Upload</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>

        </Dialog>
    )
}

AddFirmwareModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    hardware: PropTypes.string,
    version: PropTypes.string,
}

export default function AddFirmwareButton({ hardware, version }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <IconButton onClick={handleClickOpen} aria-label="add hardware">
                <AddIcon />
            </IconButton>
            <AddFirmwareModal open={open} onClose={handleClose} hardware={hardware} version={version} />
        </Fragment>
    );
}

AddFirmwareButton.propTypes = {
    hardware: PropTypes.string,
    version: PropTypes.string,
}
