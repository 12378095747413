import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import CreateProgrammerButton from './add-programmer-button';
import { observeProgrammers } from './observers';
import DeleteProgrammerButton from './delete-programmer-button';
import ProgrammerCapabilities from './programmer-capabilities-chips';
import ProgrammerStatusChip from './programmer-status-chip';

export const useNavigateToProgrammer = () => {
    const navigate = useNavigate();
    const handleClick = useCallback((id) => {
        navigate(`${id}/`);
    }, []);
    return handleClick;
}

export function ProgrammerListItem({ programmer }) {

    const handleClick = useNavigateToProgrammer();

    return (
        <ListItem
            disablePadding
            secondaryAction={<DeleteProgrammerButton programmer={programmer} />}
        >
            <ListItemButton onClick={() => handleClick(programmer.id)}>
                <ListItemText
                    primary={
                        <Stack direction='row' spacing={2}>
                            <ProgrammerStatusChip programmer={programmer} />
                            <Typography>
                                {programmer.name}
                            </Typography>
                        </Stack>
                    }
                    secondary={programmer.description} />
                <ProgrammerCapabilities sx={{ mr: 4 }} programmer={programmer} />
            </ListItemButton>
        </ListItem>
    )
}

ProgrammerListItem.propTypes = {
    programmer: PropTypes.any
}

export function ProgrammersListInternal({ programmers }) {

    return (
        <List>
            {_.map(programmers, (programmer) =>
                <ProgrammerListItem key={programmer.id} programmer={programmer} />)}
        </List>
    );
}

ProgrammersListInternal.propTypes = {
    programmers: PropTypes.array
}

export const ObservedProgrammersList = observeProgrammers(ProgrammersListInternal)

export default function ProgrammersList() {
    return (
        <Card>
            <CardHeader
                action={<CreateProgrammerButton />}
                title="Programmers"
            />
            <CardContent>
                <ObservedProgrammersList />
            </CardContent>
        </Card>
    );
};