import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId'
import { Database } from '@nozbe/watermelondb'
import { generateId } from '@wyes/database-models'

import schema from './schema'
import migrations from './migrations'

import Device from '../Device'
import Firmware from '../Firmware'
import User from '../User'
import { synchronize, hasUnsyncedChanges } from '@nozbe/watermelondb/sync'
import { BASE_ADMIN_API_URL } from '../../config'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { useFetch } from '../../main/misc/fetch'

/* eslint-disable-next-line */
setGenerator(() => generateId()); // use custom id generator.

const AfterSalesDatabaseContext = createContext(null);

export function withAfterSalesDatabase(Component) {
    function DatabaseComponent(props) {
        return (
            <AfterSalesDatabaseContext.Consumer>
                {(database) => <Component {...props} database={database} />}
            </AfterSalesDatabaseContext.Consumer>
        )
    }
    return hoistNonReactStatics(DatabaseComponent, Component)
}
export const useAfterSalesDatabase = () => useContext(AfterSalesDatabaseContext);

export const useAfterSalesDBHasChanges = () => {
    const database = useAfterSalesDatabase();
    const [changes, setChanges] = useState(null);

    const check = useCallback(async () => {
        const changes = await hasUnsyncedChanges({ database });
        setChanges(changes);
        return changes;
    }, []);

    useEffect(() => {
        const timeout = setInterval(() => {
            check().catch(e => console.error(e));
        }, 1000);
        return () => {
            clearInterval(timeout);
        }
    }, []);

    return [changes, check];
}

export const useAfterSalesSynchronizeDB = () => {
    const fetch = useFetch();
    const database = useAfterSalesDatabase();
    return useCallback(async () => await synchronize({
        database,
        pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
            const urlParams = `last_pulled_at=${lastPulledAt}&schema_version=${schemaVersion}&migration=${encodeURIComponent(JSON.stringify(migration))}`
            const response = await fetch(`${BASE_ADMIN_API_URL}/watermelon?${urlParams}`)
            if (!response.ok) {
                throw new Error(await response.text())
            }

            const { changes, timestamp } = await response.json();
            return { changes, timestamp };
        },
        pushChanges: async ({ changes, lastPulledAt }) => {
            const response = await fetch(`${BASE_ADMIN_API_URL}/watermelon?last_pulled_at=${lastPulledAt}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(changes),
            })
            if (!response.ok) {
                throw new Error(await response.text())
            }
        },
        migrationsEnabledAtVersion: 1,
    }), []);
}

export const useAfterSalesResetDB = () => {
    const database = useAfterSalesDatabase();
    return useCallback(async () => await database.write(async () => await database.unsafeResetDatabase()), []);
}

function AfterSalesDatabase({ suffix = '', children = null }) {

    const adapter = useMemo(() => {
        return new LokiJSAdapter({
            dbName: 'wyes-aftersales-database_' + suffix,
            schema,
            // (You might want to comment out migrations for development purposes -- see Migrations documentation)
            migrations,
            useWebWorker: false,
            useIncrementalIndexedDB: true,

            // --- Optional, but recommended event handlers:

            onQuotaExceededError: (error) => {
                // Browser ran out of disk space -- offer the user to reload the app or log out
            },
            onSetUpError: (error) => {
                console.error('failed to load database', error);
                // Database failed to load -- offer the user to reload the app or log out
            },
            extraIncrementalIDBOptions: {
                onDidOverwrite: () => {
                    // Called when this adapter is forced to overwrite contents of IndexedDB.
                    // This happens if there's another open tab of the same app that's making changes.
                    // Try to synchronize the app now, and if user is offline, alert them that if they close this
                    // tab, some data may be lost
                },
                onversionchange: () => {
                    // database was deleted in another browser tab (user logged out), so we must make sure we delete
                    // it in this tab as well - usually best to just refresh the page
                    //if (checkIfUserIsLoggedIn()) {
                    //    window.location.reload()
                    //}
                },
            }
        });
    }, [name]);

    const database = useMemo(() => {
        return new Database({
            adapter,
            modelClasses: [
                Device,
                Firmware,
                User
            ],
        });
    }, [adapter]);

    return (
        <AfterSalesDatabaseContext.Provider value={database}>
            {children}
        </AfterSalesDatabaseContext.Provider>
    )
}

AfterSalesDatabase.propTypes = {
    suffix: PropTypes.string,
    children: PropTypes.any,
}

export default AfterSalesDatabase;
