import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Paper, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import _ from 'lodash';
import { useSocket } from './connection-provider';
import ProgrammerParameterModal from './programmer-parameter-modal';

export const useParameterListener = (id) => {
    const socket = useSocket();
    const [twin, setTwin] = useState(null);
    const [config, setConfig] = useState(undefined);
    const [callback, setCallback] = useState(null);
    const [error, setError] = useState(null);
    const [started, setStarted] = useState(null);

    useEffect(() => {
        if (id) {
            const _socket = socket;
            const _id = id;

            // Ask for a user input.
            _socket.on(`parameter:${_id}:input`, ({ parameter, config }, cb) => {
                setTwin(parameter);
                setConfig(config);
                setCallback(() => (error, value) => {
                    cb({ error, value });
                    setConfig(undefined);
                });
            });

            // Notify the start of the process.
            _socket.on(`parameter:${_id}:prestart`, ({ parameter }) => {
                setStarted(null);
                setError(null);
                setTwin(parameter);
            });

            // Notify the start of the process.
            _socket.on(`parameter:${_id}:start`, ({ parameter }) => {
                setStarted(true);
                setError(null);
                setTwin(parameter);
            });

            // Notify the end of the process.
            _socket.on(`parameter:${_id}:stop`, ({ error, parameter }) => {
                if (error) {
                    setError(error);
                }
                setStarted(false);
                setTwin(parameter);
            });

            return () => {
                _socket.removeAllListeners(`parameter:${_id}:input`);
                _socket.removeAllListeners(`parameter:${_id}:start`);
                _socket.removeAllListeners(`parameter:${_id}:stop`);
            }
        }
    }, [id]);

    return {
        twin,
        config,
        started,
        error,
        callback,
    }
}

export default function ProgrammerParameter({ parameter }) {
    let { id, name, reload, ttl, cycles } = parameter;
    const { twin, config, started, error, callback } = useParameterListener(id);

    id = twin ? twin.id : id;
    name = twin ? twin.name : name;
    reload = twin ? twin.reload : reload;
    ttl = twin ? twin.ttl : ttl;
    cycles = twin ? twin.cycles : cycles;

    const runs = ttl > 0 ? `${cycles} / ${ttl}` : `${cycles} / ${'∞'}`;

    return (
        <Stack sx={{ p: 2 }} component={Paper} variant='outlined' spacing={1} direction='row' alignItems={'center'}>
            {reload ?
                <RotateLeftIcon color={error ? 'error' : started === false ? 'success' : 'inherit'} /> :
                <CheckCircleIcon color={error ? 'error' : started === false ? 'success' : 'inherit'} />}
            <Stack direction='column' flexGrow={1}>
                <Typography>{name}</Typography>
                <Typography variant='body2' fontSize={10}>Runs : {runs}</Typography>
                {started !== null && (
                    <LinearProgress
                        sx={{ mt: 1 }}
                        color={error ? 'error' : started ? 'secondary' : 'success'}
                        value={100}
                        variant={started === true ? 'indeterminate' : 'determinate'} />
                )}
            </Stack>
            <ProgrammerParameterModal open={Boolean(config)} parameter={parameter} config={config} setValue={callback} />
        </Stack>
    )
}

ProgrammerParameter.propTypes = {
    parameter: PropTypes.any,
}
