import React, { useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useAfterSalesSynchronizeDB } from '../../model/aftersales/AfterSalesDatabase';
import { useIsAfterSalesAdmin, useIsFactoryAdmin } from '@wyes/react-auth0';
import { useRegistrationSynchronizeDB } from '../../model/registration/RegistrationDatabase';

export default function SyncDatabasesButton(props) {
    const [disabled, setDisabled] = useState(false);

    const handleAfterSalesSync = useAfterSalesSynchronizeDB();
    const handleFactorySync = useRegistrationSynchronizeDB();

    const isFactoryAdmin = useIsFactoryAdmin();
    const isAfterSalesAdmin = useIsAfterSalesAdmin();

    const handleClick = useCallback(() => {
        setDisabled(true);
        const todo = [];
        if (isFactoryAdmin) todo.push(handleFactorySync());
        if (isAfterSalesAdmin) todo.push(handleAfterSalesSync());
        Promise.all(todo)
            .finally(() => setDisabled(false))
    }, []);

    if (!(isFactoryAdmin || isAfterSalesAdmin)) return null;


    return (
        <Tooltip title='Sync databases'>
            <IconButton
                {...props}
                disabled={disabled}
                onClick={handleClick}
                color={'secondary'}
            >
                <SyncIcon />
            </IconButton>
        </Tooltip>
    );
}
