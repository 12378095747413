import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack, Paper, Switch } from '@mui/material';
import SensorInputScale, { defaultValue as SISdefault, schema as SISschema } from './SensorInputScale';
import * as Yup from 'yup';
import SensorCard from '../../../sensors/sensor-card';
import { defaultValue as SITdefault, schema as SITschema, SensorInputDurationSlider } from './SensorInputSensitivity';
import { defaultValue as SIFdefault, schema as SIFschema, SensorInputFilterSlider } from './SensorInputFilter';
import { defaultValue as SIDdefault, schema as SIDschema, SensorInputDelaySlider } from './SensorInputDelay';

export const schema = Yup.object({
  n: Yup.string().nullable(),
  s: SISschema,
  t: SITschema,
  d: SIDschema,
  f: SIFschema
})

export const defaultValue = {
  n: null,
  s: SISdefault,
  t: SITdefault,
  d: SIDdefault,
  f: SIFdefault,
};

export default function SensorInput({ sensor, namespace, onRemove, onAdd, ...props }) {

  const handleClick = onAdd ? onAdd : onRemove ? onRemove : undefined;

  return (
    <Paper sx={{ p: 1 }}>
      <Stack spacing={1} direction='column'>
        <SensorCard onClick={handleClick} sensor={sensor} noAction />
        {namespace !== undefined && <SensorInputScale namespace={namespace + '.s'} />}
        {namespace !== undefined && <SensorInputFilterSlider namespace={namespace + '.f'} />}
        {namespace !== undefined && <SensorInputDurationSlider namespace={namespace + '.t'} />}
        {namespace !== undefined && <SensorInputDelaySlider namespace={namespace + '.d'} />}
        <Stack spacing={2} direction='row' justifyContent='center' alignItems='center'>
          <Switch
            checked={onAdd === undefined}
            onChange={handleClick}
            inputProps={{ 'aria-label': 'add this input' }}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}

SensorInput.propTypes = {
  sensor: propTypes.object,
  namespace: propTypes.string,
  onRemove: propTypes.func,
  onAdd: propTypes.func,
}
