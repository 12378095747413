import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSocket } from './connection-provider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import QRCodeButton from './qrcode-button';

export default function ProgrammerConsoleActions({ }) {

    const socket = useSocket();
    const handleDisconnect = () => socket.disconnect();
    const navigate = useNavigate();
    const handleGoBack = () => navigate(-1);


    return (
        <Stack flexGrow={1} sx={{ marginBottom: 1 }} direction='row'>
            <Stack direction='row' flexGrow={1} spacing={2} justifyContent='flex-start'>
                <QRCodeButton startIcon={<ArrowBackIosIcon />} value='back' onClick={handleGoBack} color='inherit'>Back</QRCodeButton>
            </Stack>
            <Stack direction='row' flexGrow={1} spacing={2} justifyContent='flex-end'>
                <QRCodeButton startIcon={<CloudOffIcon />} value='disconnect' onClick={handleDisconnect} color='primary'>Disconnect</QRCodeButton>
            </Stack>
        </Stack>
    )
}

ProgrammerConsoleActions.propTypes = {
}
