
import React, { useCallback, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { BASE_REGISTRATION_API_URL } from '../../../config';
import { useFetch } from '../../misc/fetch';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';

export function useGenerateToken() {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const fetch = useFetch();

    const generateCallback = useCallback(async (impersonate) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${BASE_REGISTRATION_API_URL}/admin/token`,
                {
                    method: 'POST',
                    body: JSON.stringify({ impersonate }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                });

            if (!response.ok) {
                throw new Error(await response.text())
            }

            const token = await response.json();
            copy(token);
            enqueueSnackbar('Api key copied to clipboard !', { variant: 'success' });
        } catch (e) {
            console.error(e);
            enqueueSnackbar('An error occured ...', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, []);
    return [generateCallback, loading];
}

const TokenGenerationSchema = Yup.object().shape({
    impersonate: Yup.string().min(3, 'Must be at least 3 characters long').required('Required'),
});

export default function ApiTokenGenerateButton({ }) {
    const [generateCallback] = useGenerateToken();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant='h4'>API Tokens</Typography>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{ impersonate: '' }}
                    validationSchema={TokenGenerationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        await generateCallback(values.impersonate);
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, values, errors, submitForm }) => (
                        <Form>
                            <Grid container spacing={1} >
                                <Grid item xs={9}>
                                    <Field name='impersonate' component={TextField} label='Subject' fullWidth />
                                </Grid>
                                <Grid item xs={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Button disabled={isSubmitting || values.impersonate.length < 3 || Object.keys(errors).length > 0} onClick={submitForm} endIcon={<KeyIcon />}>
                                        Generate a token
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    )
}

ApiTokenGenerateButton.propTypes = {}
