
import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@mui/material';
import { CONNECTED } from '../../../model/registration/Programmer';

export default function ProgrammerStatusBadge({ programmer, children, ...props }) {

    const connection = programmer.connection;
    const content = connection === CONNECTED ? 'AVAILABLE' : 'UNAVAILABLE';
    const color = connection === CONNECTED ? 'success' : 'error';

    return (
        <Badge {...props}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            color={color}
            badgeContent={content}>
            {children}
        </Badge>
    );
}

ProgrammerStatusBadge.propTypes = {
    programmer: PropTypes.any,
    children: PropTypes.any,
}
