import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import AddHardwareVersionButton from './add-hardwareversion-modal';
import { ObserveHardwareVersion, ObserveHardwareVersionsList } from './observers';

export const useNavigateToHardwareVersion = () => {
    const navigate = useNavigate();
    const handleClick = useCallback((id) => {
        navigate(`${id}/`);
    }, []);
    return handleClick;
}

export function HardwareVersionListItem({ hardwareVersion }) {

    const handleClick = useNavigateToHardwareVersion();

    return (
        <ListItem onClick={() => handleClick(hardwareVersion.id)}>
            <ListItemButton>
                <ListItemText primary={hardwareVersion.version} secondary={hardwareVersion.type} />
            </ListItemButton>
        </ListItem>
    )
}

HardwareVersionListItem.propTypes = {
    hardwareVersion: PropTypes.any
}

export const ObservedHardwareListItem = ObserveHardwareVersion(HardwareVersionListItem);

export function HardwareVersionsListInternal({ hardwareVersions }) {

    return (
        <List dense>
            {_.map(hardwareVersions, (hardwareVersion) =>
                <ObservedHardwareListItem key={hardwareVersion.id} hardwareVersion={hardwareVersion} />)}
        </List>
    );
}

HardwareVersionsListInternal.propTypes = {
    hardwareVersions: PropTypes.array
}

export const ObservedHardwareVersionsList = ObserveHardwareVersionsList(HardwareVersionsListInternal);

export default function HardwareVersionsList() {
    return (
        <Card>
            <CardHeader
                action={<AddHardwareVersionButton />}
                title="Hardware Versions"
            />
            <CardContent>
                <ObservedHardwareVersionsList />
            </CardContent>
        </Card>
    );
};