import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FieldArray, useField } from 'formik';
import Expresion, { defaultValue as Edefault, schema as Eschema } from './Expression';
import * as Yup from 'yup';

export const schema = Yup.array().of(Eschema).min(1, 'A least one expression is required');
export const defaultValue = [];

export default function ExpressionsList({ namespace, ...props }) {

  const [field] = useField(namespace);
  const expressions = field.value;

  return (
    <FieldArray
      name={namespace}
      render={({ push, remove }) => (
        <Stack spacing={2} direction='column'>
          {_.map(expressions, (exp, i) => (
            <Expresion {...props} key={i} namespace={namespace + `.${i}`} onRemove={() => remove(i)} />
          ))}
          <Stack flexGrow={1} direction='row' alignItems='center' justifyContent='center'>
            <Tooltip title='Add an expression' open={expressions.length === 0}>
              <IconButton onClick={() => push(Object.assign({}, Edefault))}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      )}
    />
  );
}

ExpressionsList.propTypes = {
  namespace: propTypes.string,
}