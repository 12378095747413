import { useCallback, useMemo } from 'react';
import { topicBuilder, useMqttListener } from '@wyes/web-react-devices';
import { BASE_ADMIN_API_URL } from '../../../../config';
import { useOnlineDeviceApi, useOnlineDeviceIds, useOnlineDeviceStream } from '../live/online-device-provider';
import { useFetch } from '../../../misc/fetch';

export const useStartSensorStream = (sn) => {
    const fetch = useFetch();
    return useCallback(async (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        try {
            const response = await fetch(
                `${BASE_ADMIN_API_URL}/actions/devices/${sn}/sensors/stream/start`,
                {
                    method: 'GET',
                });

            if (!response.ok) {
                throw new Error(await response.text());
            }
        } catch (e) {
            console.error(e);
        }

    }, [sn]);
}

export const useStopSensorStream = (sn) => {
    const fetch = useFetch();
    return useCallback(async (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        try {
            const response = await fetch(
                `${BASE_ADMIN_API_URL}/actions/devices/${sn}/sensors/stream/stop`,
                {
                    method: 'GET',
                });

            if (!response.ok) {
                throw new Error(await response.text());
            }
        } catch (e) {
            console.error(e);
        }

    }, [sn]);
}

export const useSensorsStreamListener = () => {
    const { dataset, emitter } = useOnlineDeviceStream();
    const { serialNumber } = useOnlineDeviceIds();

    let startingPoint = null;

    const listener = useCallback(({ data, samples, sensors, sampleInterval, date }) => {
        if (dataset.length !== sensors) {
            console.error('Array dimensions mismatch');
            return;
        }

        if(startingPoint === null) {
            startingPoint = Date.now() - date + (samples * sampleInterval);
        }

        const samplesPerSeconds = 1000 / sampleInterval;
        const samplesPerMinute = 60 * samplesPerSeconds;

        const newData = Array.from({ length: sensors }, (v, k) => ({ key: dataset[k].key, data: [] }));
        const now = startingPoint + date;

        for (let i = 0; i < samples; i++) {
            for (let j = 0; j < sensors; j++) {
                const point = { x: now + (i - samples) * sampleInterval, y: data[(i * sensors) + j] };
                dataset[j].data.push(point); // update the dataset with the point.
                newData[j].data.push(point); // create a copy of the dataset with only the diff.
            }
        }

        try {
            emitter.emit('data', newData);
        } catch (e) { console.error(e) }

        // Leave one minute of samples in the buffers.
        for (let j = 0; j < sensors; j++) {
            if (dataset[j].data.length > samplesPerMinute) {
                dataset[j].data.splice(0, dataset[j].data.length - samplesPerMinute);
            }
        }

    }, [dataset]);
    const topic = useMemo(() => topicBuilder(`v1/dev/${serialNumber}/d/sensors/stream`), [serialNumber]);
    useMqttListener(topic, listener, true);
}

export const useSensorStreamController = () => {
    const { serialNumber } = useOnlineDeviceIds();

    const forceStart = useStartSensorStream(serialNumber);
    const forceStop = useStopSensorStream(serialNumber);

    const { activated, dataset, emitter } = useOnlineDeviceStream();
    const { setStreamActivation } = useOnlineDeviceApi();

    const start = useCallback(async (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        if (!activated) {
            await forceStart();
            setStreamActivation(true);
        }

    }, [activated, forceStart]);

    const stop = useCallback(async (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        if (activated) {
            await forceStop();
            setStreamActivation(false);
        }

    }, [activated, forceStop]);

    return { start, stop, activated, dataset, emitter };
}