import React, { useMemo } from 'react';
import _ from 'lodash';
import { useJobRemove, useJobs } from './jobs-provider';
import { Box, LinearProgress, List, ListItem, ListItemIcon, Stack, Typography, ListItemText } from '@mui/material';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export function JobsList({ ...props }) {

  const jobs = useJobs();
  const _jobs = _.map(jobs, (job, name) => ({ job, name })).reverse();

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
      <Typography p={1} textAlign='center' variant='h6'>Jobs</Typography>
      <List sx={{
        marginX: 1,
        flexGrow: 1,
        "::-webkit-scrollbar": { display: 'none' },
        "msOverflowStyle": 'none',
        scrollbarWidth: 'none',
        overflowY: 'scroll', overflowX: 'hidden',
      }}>
        {_.map(_jobs, ({ name, job }) => {
          return <JobsItem key={name} name={name} job={job} />
        })}
      </List>
    </Box>
  )
}

export function useJobIconValue(job) {
  return useMemo(() => {
    let Icon, iconColor;
    let iconType = 'material-community';

    if (job.status === 'started') {
      iconColor = 'primary';
      Icon = (job.icon && job.icon.started) || ManageHistoryIcon;
    } else if (job.status === 'finished') {
      iconColor = 'success';
      Icon = (job.icon && job.icon.finished) || TaskAltIcon;
    } else {
      iconColor = 'error';
      Icon = (job.icon && job.icon.error) || ErrorOutlineIcon;
    }

    return { Icon, iconType, iconColor };
  }, [job.status]);
}

export function useJobProgressValue(job) {
  return useMemo(() => {
    return {
      progressValue: job.progress === null ? null : job.progress,
      progressVariant: job.progress === null ? 'indeterminate' : 'determinate',
      progressColor: job.status === 'error' ? 'error' : job.status === 'finished' ? 'success' : 'primary',
    }
  }, [job.progress, job.status]);
}

export function JobsItem({ job, name, ...props }) {

  const remove = useJobRemove();
  const { Icon, iconType, iconColor } = useJobIconValue(job);
  const { progressValue, progressVariant, progressColor } = useJobProgressValue(job);

  return (
    <ListItem divider>
      <ListItemIcon>
        <Icon color={iconColor} />
      </ListItemIcon>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <ListItemText primary={job.title || name} secondary={job.message} />
        <LinearProgress animation={'false'} value={progressValue} variant={progressVariant} color={progressColor} />
      </Stack>
    </ListItem>
  )
}
