import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { FACTORY_STATUS } from '../../../../model/Device';

export default function SelectDeviceFactoryStatus({ status, setStatus }) {
    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    return (
        <TextField
            id="select-factory-status"
            size="small"
            select
            label="Select"
            value={status}
            onChange={handleChange}
        >
            <MenuItem value={'null'}>
                ALL
            </MenuItem>
            {FACTORY_STATUS.map((status) => (
                <MenuItem key={status} value={status}>
                    {status}
                </MenuItem>
            ))}
        </TextField>
    );
}

SelectDeviceFactoryStatus.propTypes = {
    status: PropTypes.string,
    setStatus: PropTypes.func,
}
