import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ConfigureReactionModal, { useConfigureReactionModalController } from './configuration/configure-reaction-modal';
import { TYPE as RelayReactionType } from './configuration/relay-reaction-configuration';
import { TYPE as BLEReactionType } from './configuration/ble-reaction-configuration';

export default function ReactionConfigButton({ reaction, ...props }) {

    const [open, handleOpen, handleClose] = useConfigureReactionModalController();

    if (![BLEReactionType, RelayReactionType].includes(reaction.t)) {
        return null;
    }

    return (
        <Fragment>
            <IconButton onClick={handleOpen}>
                <SettingsIcon />
            </IconButton>
            <ConfigureReactionModal open={open} onClose={handleClose} reaction={reaction} />
        </Fragment>
    )
}

ReactionConfigButton.propTypes = {
    reaction: PropTypes.object,
}