import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

function Workbench({ children = null }) {
    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Toolbar />
            <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 4 }}>
                {children}
            </Box>
        </Box>
    );
}

Workbench.propTypes = {
    children: PropTypes.any,
}

export default Workbench;