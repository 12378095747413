import React from 'react';
import UsersList from './list';
import { Route, Routes } from 'react-router-dom';
import UserDetails from './details';
import { useIsAfterSalesAdmin, useIsFactoryAdmin } from '@wyes/react-auth0';

export default function UsersView() {

    const isFactoryAdmin = useIsFactoryAdmin();
    const isAfterSalesAdmin = useIsAfterSalesAdmin();
    if(!(isFactoryAdmin || isAfterSalesAdmin)) return null;

    return (
        <Routes>
            <Route path='/' exact element={<UsersList />} />
            <Route path='/:id' element={<UserDetails />} />
        </Routes>
    );
}
