import React, { createContext, useContext, useEffect, useState } from 'react';
import mqtt from 'precompiled-mqtt';
import * as MQTTPattern from 'mqtt-pattern';

export const topicBuilder = (topic) => {
  if (process.env.REACT_APP_MQTT_ENV === 'development') {
    return `${'development/'}${topic}`;
  }
  return topic;
}

export const MQTT_STATUS_DISCONNECTED = 'disconnected';
export const MQTT_STATUS_CONNECTING = 'connecting';
export const MQTT_STATUS_CONNECTED = 'connected';

const MqttContext = createContext({
  client: undefined,
  status: undefined,
});

const subscriptions = {};

export const useMqtt = () => useContext(MqttContext);

export const useMqttListener = (topic, listener, onlyOne = false) => {
  const { client } = useMqtt();

  useEffect(() => {

    if (client === null) {
      return;
    }

    const _client = client;
    const cleanTopic = MQTTPattern.clean(topic);

    if (onlyOne && subscriptions[cleanTopic] > 0) {
      return;
    }

    const internalListener = (topicReceived, message) => {
      const params = MQTTPattern.exec(topic, topicReceived);
      if (params !== null) {
        const data = JSON.parse(message.toString('ascii'));
        listener(data, params, topicReceived);
      }
    }

    _client.on('message', internalListener);

    if (subscriptions[cleanTopic] === undefined) {
      subscriptions[cleanTopic] = 0;
      _client.subscribe(cleanTopic, function (err) {
        if (err) {
          return console.error(err);
        }
      });
    }

    subscriptions[cleanTopic]++;

    return () => {
      _client.removeListener('message', internalListener);
      subscriptions[cleanTopic]--;
      if (subscriptions[cleanTopic] <= 0) {
        subscriptions[cleanTopic] = undefined;
        if (!_client.disconnecting && _client.connected) {
          _client.unsubscribe(cleanTopic, function (err) {
            if (err) console.error(err);
          });
        }
      }
    }
  }, [client, topic, listener, onlyOne]);
}

export default function MqttClientContext({ url, username = undefined, password = undefined, options = {}, ...props }) {

  const [client, setClient] = useState(null);
  const [status, setStatus] = useState(MQTT_STATUS_DISCONNECTED);

  useEffect(() => {
    const opts = Object.assign({
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
      username,
      password,
    }, options);
    const client = mqtt.connect(url, opts);

    setStatus(MQTT_STATUS_DISCONNECTED);

    client.on('connect', () => setStatus(MQTT_STATUS_CONNECTED));
    client.on('reconnect', () => setStatus(MQTT_STATUS_CONNECTING));
    client.on('disconnect', () => setStatus(MQTT_STATUS_DISCONNECTED));
    client.on('offline', () => setStatus(MQTT_STATUS_DISCONNECTED));

    setClient(client);

    return () => {
      client.removeAllListeners();
      client.end(true);
    }
  }, [url, username, password]);

  return (
    <MqttContext.Provider value={{
      client,
      status,
    }} {...props} />
  )
}
