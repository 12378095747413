import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Typography } from '@mui/material';
import OutputActivationThresholdField, { schema as FieldSchema} from './OutputActivationThresholdField';
import * as Yup from 'yup';

export const schema = Yup.object({
  ht: FieldSchema,
  lt: FieldSchema
});

export const TYPE = 'oas';
export const defaultValue = {
  ht: 0,
  lt: 0,
};

export default function OutputActivationSchmitt({ namespace, ...props }) {
  return (
    <Grid container spacing={2}>
        <Grid item xs={12}><Typography>Schmitt Trigger</Typography></Grid>
        <Grid item xs={6}><OutputActivationThresholdField namespace={namespace + '.ht'} label='High Threshold' /></Grid>
        <Grid item xs={6}><OutputActivationThresholdField namespace={namespace + '.lt'} label='Low Threshold' /></Grid>
    </Grid>
  )
}

OutputActivationSchmitt.propTypes = {
  namespace: propTypes.string,
}
