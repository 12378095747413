import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, CardHeader, Grid, Paper, LinearProgress, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import _ from 'lodash';
import { useSocket } from './connection-provider';

export const useStepListener = (id) => {
    const socket = useSocket();
    const [twin, setTwin] = useState(null);
    const [progress, setProgress] = useState(null);
    const [error, setError] = useState(null);
    const [results, setResults] = useState(null);
    const [started, setStarted] = useState(null);

    useEffect(() => {
        if (id) {
            const _socket = socket;
            const _id = id;

            _socket.on(`step:${_id}:prestart`, ({ step }) => {
                setTwin(step);
                setStarted(null);
                setError(null);
                setProgress(null);
                setResults(null);
            });

            // Notify the start of the process.
            _socket.on(`step:${_id}:start`, ({ step }) => {
                setTwin(step);
                setStarted(true);
                setError(null);
                setResults(null);
            });

            // Notify the progress of the process.
            _socket.on(`step:${_id}:progress`, ({ step, progress }) => {
                setTwin(step);
                setProgress(progress);
            });

            // Notify the end of the process.
            _socket.on(`step:${_id}:stop`, ({ error, results, step }) => {
                if (error) {
                    setError(error);
                } else {
                    setResults(results);
                }
                setTwin(step);
                setStarted(false);
            });

            return () => {
                _socket.removeAllListeners(`step:${_id}:start`);
                _socket.removeAllListeners(`step:${_id}:progress`);
                _socket.removeAllListeners(`step:${_id}:stop`);
            }
        }
    }, [id]);

    return {
        twin,
        results,
        started,
        error,
        progress,
    }
}

export function TaskStep({ step }) {
    let { id, name, description } = step;
    const { twin, started, error, progress } = useStepListener(id);

    id = twin ? twin.id : id;
    name = twin ? twin.name : name;
    description = twin ? twin.description : description;

    return (
        <Stack sx={{ p: 2 }} component={Paper} variant='outlined' spacing={1} direction='row' alignItems={'center'}>
            {started === null ?
                <RotateLeftIcon /> :
                <CheckCircleIcon color={error ? 'error' : 'success'} />
            }
            <Stack direction='column' flexGrow={1}>
                <Typography>{name}</Typography>
                {description && <Typography variant='body2' fontSize={10}>{description}</Typography>}
                {error && <Typography variant='body2' color={'error'} fontSize={10}>{JSON.stringify(error)}</Typography>}
                {started !== null && <LinearProgress
                    sx={{ mt: 1 }}
                    color={error ? 'error' : started ? 'secondary' : 'success'}
                    variant={progress !== null || started === false ? 'determinate' : 'indeterminate'}
                    value={started === false ? 100 : progress} />
                }
            </Stack>
        </Stack>
    )
}

TaskStep.propTypes = {
    step: PropTypes.any,
}

export default function ProgrammerTaskSteps({ steps }) {

    return (
        <Card variant='outlined' sx={{ flexGrow: 1 }}>
            <CardHeader title={'Steps'} />
            <CardContent>
                <Grid container spacing={1}>
                    {_.map(steps, step => <Grid key={step.name} item xs={12}>
                        <TaskStep step={step} />
                    </Grid>
                    )}
                </Grid>
            </CardContent>
            <CardActions disableSpacing>
            </CardActions>
        </Card>
    )
}

ProgrammerTaskSteps.propTypes = {
    steps: PropTypes.array,
}
