import React from 'react';
import propTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import * as Yup from 'yup';

export const type = "number";
export const label = "Scale";
export const defaultValue = 1.0;
export const schema = Yup.number().required('Required');

export default function SensorInputScale({ namespace, className, isSubmitting = false, disabled = false, ...props }) {

  return (
    <Field
      size='small'
      className={className}
      label={label}
      placeholder={label}
      component={TextField}
      name={namespace}
      type={type}
      disabled={disabled || isSubmitting}
      onClick={(ev) => ev.stopPropagation()}
    />
  );
}

SensorInputScale.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
  disabled: propTypes.bool,
}
