import React, { } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent, Paper, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { ObserveHardwareVersionById } from './observers';

export function HardwareVersionsDetailsInternal({ hardwareVersion }) {
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TableContainer component={Paper}>
                            <Table aria-label="hardware version details table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Version</TableCell>
                                        <TableCell>{hardwareVersion.version}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>{hardwareVersion.type}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Bundle file</TableCell>
                                        <TableCell>{hardwareVersion.bundle ? 'Yes' : 'No'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Updated At</TableCell>
                                        <TableCell>{hardwareVersion.updatedAt.toISOString()}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ 'td': { border: 0 } }}>
                                        <TableCell>Created At</TableCell>
                                        <TableCell>{hardwareVersion.createdAt.toISOString()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

HardwareVersionsDetailsInternal.propTypes = {
    hardwareVersion: PropTypes.any
}

const ObservedHardwareVersionsDetails = ObserveHardwareVersionById(HardwareVersionsDetailsInternal);

export default function HardwareVersionsDetails(props) {
    const { id } = useParams();
    return <ObservedHardwareVersionsDetails id={id} />
}
