import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Dialog,DialogContent, Stepper, Step, StepLabel } from '@mui/material';
import ChooseInputs from './steps/choose-inputs';
import WriteExpressions from './steps/write-expressions';
import ChooseActivation from './steps/choose-activation';
import ChooseReactions from './steps/choose-reactions';
import ChangeName from './steps/change-name';

const steps = [
  {
    label: 'Choose inputs',
    content: (props) => <ChooseInputs {...props} />
  }, {
    label: 'Do some maths',
    content: (props) => <WriteExpressions {...props} />
  }, {
    label: 'Choose when it activates',
    content: (props) => <ChooseActivation {...props} />
  }, {
    label: 'Choose the reactions',
    content: (props) => <ChooseReactions {...props} />
  }, {
    label: 'Give it a name',
    content: (props) => <ChangeName {...props} />
  }
];

export const useConfigureManualMovementPhaseController = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return [open, handleOpen, handleClose];
}

export default function ManualMovementPhaseDialog({ open, onClose, ...props }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if (activeStep + 1 >= steps.length) {
      onClose();
      setActiveStep(0);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep - 1 < 0) {
      onClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <Dialog fullWidth maxWidth='xl' open={open} onClose={onClose}>
      <DialogContent sx={{ minHeight: '90vh', display: 'flex', flexDirection: 'column' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps[activeStep].content({ handleBack, handleNext, ...props })}
      </DialogContent>
    </Dialog>
  );
}

ManualMovementPhaseDialog.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
}