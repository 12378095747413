import { compose } from "recompose";
import { withRegistrationDatabase } from "../../../model/registration/RegistrationDatabase";
import withObservables from '@nozbe/with-observables';

export const ObserveHardwareVersionById = compose(
    withRegistrationDatabase,
    withObservables(['id'], ({ id, database }) => ({
      hardwareVersion: database.get('hardware_versions').findAndObserve(id)
    }))
  );
  
  export const ObserveHardwareVersionsList = compose(
    withRegistrationDatabase,
    withObservables([], ({ database }) => ({
      hardwareVersions: database.get('hardware_versions').query()
    }))
  );
  
  export const ObserveHardwareVersion = withObservables(['hardwareVersion'], ({ hardwareVersion }) => ({
    hardwareVersion,
  }));