import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Field, useField } from 'formik';
import * as Yup from 'yup';
import { Autocomplete, Chip, createFilterOptions, TextField } from '@mui/material';

export const type = "text";
export const label = "Equation";
export const defaultValue = "";

export const schema = Yup.string().required('Required');

const filter = createFilterOptions();

function MathExpressionInput({ sensors, className, namespace }) {
  const [{ onChange, value: init, ...field }, meta, { setValue: setInternalValue }] = useField(namespace);

  const value = useMemo(() => {
    const value = [];
    const regex = new RegExp(`(?:s[0-9]+)`, 'g');
    let array;
    let lastIndex = 0;
    while ((array = regex.exec(init)) !== null) {
      const substring = init.slice(lastIndex, array.index);
      if (substring.length > 0) {
        value.push({
          __name: substring,
          __key: substring,
        })
      }

      const sensor = _.find(sensors, (s) => s.__key === array[0]);
      if (sensor) {
        value.push({
          __name: sensor.__name,
          __key: array[0],
          __uuid: sensor.__uuid,
        })
      }

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < init.length) {
      const substring = init.slice(lastIndex);
      value.push({
        __name: substring,
        __key: substring
      })
    }

    return value;
  }, [sensors, init]);

  return (
    <Autocomplete
      className={className}
      label={label}
      placeholder={label}
      fullWidth
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      value={value}
      onChange={(event, newValue) => {
        newValue = _.map(newValue, value => ({
          __key: value.__key || value,
          __name: value.__name || value,
          __uuid: value.__uuid || undefined,
        }));
        setInternalValue(_.map(newValue, '__key').join(' '));
      }}
      multiple
      id="tags-filled"
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          if (option.__uuid) {
            return <Chip key={option.__key} variant="outlined" label={option.__name} {...getTagProps({ index })} onDelete={null} />
          } else {
            return option.__name;
          }
        })
      }
      options={sensors}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.__name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            __name: `${inputValue}`,
            __key: `${inputValue}`
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        return option.__name;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
        />
      )}
    />
  )
}

MathExpressionInput.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
  sensors: propTypes.any,
}


export default function ExpressionEquation(props) {

  return <MathExpressionInput {...props} />
  /*return (
    <Field
      size='small'
      fullWidth
      className={className}
      label={label}
      placeholder={label}
      component={TextField}
      name={namespace}
      type={type}
      disabled={isSubmitting}
      onClick={(ev) => ev.stopPropagation()}
    />
  );*/
}

ExpressionEquation.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
  sensors: propTypes.any,
}
