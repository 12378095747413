import React, { useState } from 'react';
import { Backdrop, LinearProgress, Button, Stack, Box, Typography } from '@mui/material';
import { useJobLock } from './jobs-provider';
import { useJobProgressValue, useJobIconValue } from './jobs-list';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export function JobsLockBackdrop({ name, job, ...props }) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const { Icon, iconType, iconColor } = useJobIconValue(job);
  const { progressValue, progressVariant, progressColor } = useJobProgressValue(job);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Stack direction='row' alignItems='center' spacing={4} sx={{width: '50%'}} >
        <Icon color={iconColor} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Box>
            <Typography display='inline' variant='body1'>{job.title || name}</Typography>
            <Typography display='inline' variant='body2'>{job.message}</Typography>
          </Box>
          <LinearProgress value={progressValue} variant={progressVariant} color={progressColor} />
          <Button onClick={handleClose} color='warning' endIcon={<LockOpenIcon />} >Unlock</Button>
        </Stack>
      </Stack>
    </Backdrop>
  );
}

export default function JobsLock(props) {
  const lock = useJobLock();
  if (lock) {
    return <JobsLockBackdrop {...props} name={lock.name} job={lock.job} />
  } else {
    return null;
  }
}
