import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMovementWriteAction } from './configure-movement-modal';
import { movementDefaultValue } from './configuration/MovementId';
import { useOnlineDeviceApi, useOnlineDeviceMovements } from '../live/online-device-provider';

export const useMovementCopyAction = (sn, movement) => {

    const [disabled, setDisabled] = useState(false);
    const writeMovement = useMovementWriteAction(sn);
    const { setMovements } = useOnlineDeviceApi();
    const movements = useOnlineDeviceMovements();

    const handleCopy = useCallback(async (ev) => {
        setDisabled(true);
        ev.preventDefault();
        ev.stopPropagation();

        try {
            const key = movementDefaultValue();
            const payload = { ..._.cloneDeep(movement), i: key };
            await writeMovement(key, payload);
            setMovements([...movements, payload]);
        } catch (e) {
            console.error(e);
        }
        setDisabled(false);
    }, [movement]);

    return [handleCopy, disabled];
}


export default function CopyMovementButton({ sn, movement }) {

    const [handleCopy, disabled] = useMovementCopyAction(sn, movement);
    const title = 'Copy';

    return (
        <Tooltip title={title}>
            <span>
                <IconButton disabled={disabled} onClick={handleCopy} aria-label="copy movement">
                    <ContentCopyIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
}

CopyMovementButton.propTypes = {
    sn: PropTypes.string,
    movement: PropTypes.object,
}
