import React from 'react';
import { IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useWyesColorMode } from '@wyes/react-themes';

export default function DarkLightModeButton() {
    const { mode, toggleColorMode } = useWyesColorMode();
    return (
        <IconButton sx={{ ml: 1 }} onClick={() => toggleColorMode()} color="inherit">
            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
    );
}
