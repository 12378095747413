import { useGetAccessToken } from '@wyes/react-auth0';
import { useCallback } from 'react';

export const AUTH0_API_AUDIENCE = process.env.REACT_APP_AUTH0_API_AUDIENCE;

export const useFetch = () => {
    const getAccessTokenSilently = useGetAccessToken(AUTH0_API_AUDIENCE);

    return useCallback(async (url, options = {}) => {
        const token = await getAccessTokenSilently();
        const response = await fetch(url, {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token.access_token}`,
            }
        });
        return response;
    }, [getAccessTokenSilently]);
};
