import React, { useCallback } from "react"
import { Button } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"

export const buildRedirectURI = () => window.location.origin + `/auth`;

export const useLoginRoutine = () => {
  const { loginWithRedirect } = useAuth0();

  return useCallback(() => {
    const redirectUri = buildRedirectURI();
    loginWithRedirect({ redirectUri: redirectUri, appState: { redirect: window.location.pathname } });
  }, [loginWithRedirect]);
}

const LoginButtonWithRedirect = () => {
  const loginRoutine = useLoginRoutine();
  return <Button onClick={() => loginRoutine()}>Log In</Button>
}

export default LoginButtonWithRedirect
