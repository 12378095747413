import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import withObservables from '@nozbe/with-observables'
import _ from 'lodash'
import AddDeviceButton from './add-device-modal';
import { useNavigate } from 'react-router-dom';
import DeviceListQueryBuilder, { observeDevicesListFromQuery, useQueryBuilder } from './device-list-query-builder';
import LabelsCsvExportButton from './labels-csv-export-button';
import dayjs from 'dayjs';
import { DECLARED } from '../../../../model/Device';
import { withRegistrationDatabase } from '../../../../model/registration/RegistrationDatabase';
import SelectDeviceWithScanner from './select-with-scanner';

export const useNavigateToDevice = () => {
    const navigate = useNavigate();
    const handleClick = useCallback((id) => {
        navigate(`${id}/details`);
    }, []);
    return handleClick;
}

export function FactoryDeviceListItem({ device, hardwareVersion, firmware }) {

    const handleClick = useNavigateToDevice();

    switch (device.factoryStatus) {
        case DECLARED:
            return (
                <ListItem onClick={() => handleClick(device.id)}>
                    <ListItemButton>
                        <ListItemText primary={`${device.id}`} secondary={`${device.hardware} - ${device.factoryStatus} - ${dayjs(device.createdAt).toISOString()}`} />
                    </ListItemButton>
                </ListItem>
            )
        default:
            return (
                <ListItem onClick={() => handleClick(device.id)}>
                    <ListItemButton>
                        <ListItemText primary={`${device.id}`} secondary={`${device.hardware} - ${device.factoryStatus} - ${dayjs(device.createdAt).toISOString()} - ${hardwareVersion ? hardwareVersion.version : 'null'} - ${firmware ? firmware.version : 'null'}`} />
                    </ListItemButton>
                </ListItem>
            )
    }
}

FactoryDeviceListItem.propTypes = {
    device: PropTypes.any,
    hardwareVersion: PropTypes.any,
    firmware: PropTypes.any
}

const observeDevice = withObservables(['device'], ({ device }) => {
    return ({
        device,
        hardwareVersion: device.hardwareVersion,
        firmware: device.firmware,
    })
});

export const ObservedFactoryDeviceListItem = withRegistrationDatabase(observeDevice(FactoryDeviceListItem));

export function FactoryDevicesListInternal({ devices }) {
    return (
        <List dense>
            {_.map(devices, (device) => <ObservedFactoryDeviceListItem key={device.id} device={device} />)}
        </List>
    );
}

FactoryDevicesListInternal.propTypes = {
    devices: PropTypes.array
}

export const ObservedFactoryDevicesList = observeDevicesListFromQuery(FactoryDevicesListInternal)

export default function FactoryDevicesList({ }) {

    const [query, setQuery] = useQueryBuilder();
    const handleClick = useNavigateToDevice();

    return (
        <Card>
            <CardHeader
                action={(
                    <Stack spacing={1} direction='row' alignItems='center'>
                        <SelectDeviceWithScanner onClick={handleClick} />
                        <DeviceListQueryBuilder query={query} setQuery={setQuery} />
                        <LabelsCsvExportButton query={query} />
                        <AddDeviceButton />
                    </Stack>
                )}
                title="Devices"
            />
            <CardContent>
                <ObservedFactoryDevicesList query={query} />
            </CardContent>
        </Card>
    );
};

FactoryDevicesList.propTypes = {
}
