import React from 'react';
import propTypes from 'prop-types';
import { useField } from 'formik';
import * as Yup from 'yup';
import { MenuItem, TextField } from '@mui/material';

const VALUES = {
  DO_NOTHING: 0,
  CANCEL_PHASE: 1,
  GO_TO_NEXT_PHASE: 2,
}

export const type = "number";
export const label = "Then";
export const defaultValue = 0;
export const schema = Yup.number().required('Required')
  .min(VALUES.DO_NOTHING, 'Must be between DO NOTHING and GO TO NEXT PHASE')
  .max(VALUES.GO_TO_NEXT_PHASE, 'Must be between DO NOTHING and GO TO NEXT PHASE');

export default function ReactionOutputSolution({ namespace, className, isSubmitting, ...props }) {
  const [{ value }, meta, { setValue }] = useField(namespace);

  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
  };

  return (
    <TextField
      size='small'
      className={className}
      label={label}
      placeholder={label}
      name={namespace}
      type={type}
      disabled={isSubmitting}
      value={value.toString()}
      onChange={handleChange}
      select>
      {_.map(VALUES, (value, name) => <MenuItem key={name} value={value.toString()}>{name.replace(/_/g, ' ')}</MenuItem>)}
    </TextField>
  );
}

ReactionOutputSolution.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
}