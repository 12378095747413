
import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { MenuItem, Stack, TextField, Tooltip } from '@mui/material';
import { useField } from 'formik';
import { useOnlineDeviceConnections } from '../../../live/online-device-provider';
import ListBLEConnectionsButton from '../../../live/list-ble-connections-button';
import { Box } from '@mui/system';

export const BROADCAST = {
    value: 'null',
    name: 'Broadcast',
};

export default function TargetBLEDeviceField({ name, ...props }) {

    const [{ value }, meta, { setValue }] = useField(name);

    const connections = useOnlineDeviceConnections();

    const defaultValues = useMemo(() =>
        _.uniqBy([
            BROADCAST,
            value === null ? BROADCAST : { value, name: value.match(/.{1,2}/g).join(':') },
            ...connections.map(({ a }) => ({ value: a, name: a.match(/.{1,2}/g).join(':') }))
        ], 'value')
        , [value, connections]);

    return (
        <Stack sx={{ width: '100%' }} alignItems={'center'} justifyContent={'center'} direction='row' spacing={2}>
            <Tooltip title={'Liste les appareils connectés'}>
                <Box>
                    <ListBLEConnectionsButton />
                </Box>
            </Tooltip>

            <TextField
                select
                fullWidth
                name={name}
                label='Target Device Address'
                value={value === null ? 'null' : value}
                onChange={(ev) => {
                    if (ev.target.value === 'null') {
                        setValue(null);
                    } else {
                        setValue(ev.target.value);
                    }
                }}
            >
                {defaultValues.map(({ value, name }) => (
                    <MenuItem key={value} value={value}>
                        {name || value}
                    </MenuItem>
                ))}
            </TextField>
        </Stack>

    )
}

TargetBLEDeviceField.propTypes = {
    name: propTypes.string,
}
