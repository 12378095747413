import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { buildRedirectURI } from "./login-button-with-redirect";

export const useGetAccessToken = (audience) => {
  const { getAccessTokenSilently } = useAuth0();
  return useCallback(async (scope = '') => {
    const redirect_uri = buildRedirectURI();
    const token = await getAccessTokenSilently({ audience, scope, redirect_uri, detailedResponse: true });
    return token;
  }, [getAccessTokenSilently, audience]);
}

export const useAuthUser = () => {
  const { user } = useAuth0();
  return user;
}

export const useIsAuthenticated = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated;
}
