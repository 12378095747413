import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow } from '@mui/material';
import PingButton from './ping-button';
import RebootButton from './reboot-button';
import StreamButton from './stream-button';


export default function OnlineDeviceActionsCard({ ...props }) {

    return (
        <Card {...props}>
            <CardHeader title={`Actions`} />
            <CardContent>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell>Reboot</TableCell>
                            <TableCell align='center'><RebootButton /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Stream</TableCell>
                            <TableCell align='center'><StreamButton /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Ping</TableCell>
                            <TableCell align='center'><PingButton /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>

    )
}

OnlineDeviceActionsCard.propTypes = {

}