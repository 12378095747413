import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack } from '@mui/material';
import { FieldArray, useField } from 'formik';
import SensorInput, { defaultValue as SIdefault, schema as SISchema } from './SensorInput';
import * as Yup from 'yup';
import { useOnlineDeviceSensors } from '../../../live/online-device-provider';

export const schema = Yup.array().of(SISchema).min(1, 'A least one sensor is required');
export const defaultValue = [];

export default function SensorInputs({ namespace, ...props }) {

  const [field] = useField(namespace);
  const inputs = field.value;

  const sensors = useOnlineDeviceSensors();

  return (
    <FieldArray
      name={namespace}
      render={({ push, remove }) => (
        <Stack spacing={2} direction='row' alignItems='flex-start' justifyContent='center'>
          {_.map(sensors, (sensor, i) => {
            const index = _.findIndex(inputs, (i) => i.n === sensor.k);
            if (index >= 0) {
              return <SensorInput key={sensor.k} sensor={sensor} namespace={namespace + `.${index}`} onRemove={() => remove(index)} />
            } else {
              return <SensorInput key={sensor.k} sensor={sensor} onAdd={() => push(Object.assign({}, SIdefault, { n: sensor.k }))} />
            }
          })}
        </Stack>
      )}
    />
  );
}

SensorInputs.propTypes = {
  namespace: propTypes.string,
}