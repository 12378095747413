import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
import { BASE_ADMIN_API_URL } from '../../../config';
import { useAfterSalesSynchronizeDB } from '../../../model/aftersales/AfterSalesDatabase';
import { useFetch } from '../../misc/fetch';

export function useLaunchFuota() {
    const fetch = useFetch();
    const sync = useAfterSalesSynchronizeDB();
    const launchFuotaCb = useCallback(async ({ device, firmware }) => {
        const response = await fetch(
            `${BASE_ADMIN_API_URL}/actions/devices/${device.id}/fuota/${firmware.id}`,
            {
                method: 'POST',
            });

        if (!response.ok) {
            throw new Error(await response.text())
        }

        await sync();
    }, []);
    return launchFuotaCb;
}

export function ConfirmFuotaModal({ open, onClose, firmware, device }) {

    const handleLaunchFuota = useLaunchFuota();

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
            <DialogTitle>Upgrade to: {firmware.version} for device: {device.id} ? </DialogTitle>
            <DialogActions>
                <Button color='inherit' onClick={onClose} autoFocus>Cancel</Button>
                <Button color='primary' onClick={() => handleLaunchFuota({ device, firmware }).finally(onClose)}>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmFuotaModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    device: PropTypes.any,
    firmware: PropTypes.any,
}

export default function FuotaButton({ firmware, device }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Button onClick={handleClickOpen} aria-label="launch fuota">
                Upgrade
            </Button>
            <ConfirmFuotaModal open={open} onClose={handleClose} firmware={firmware} device={device} />
        </Fragment>
    );
}

FuotaButton.propTypes = {
    device: PropTypes.any,
    firmware: PropTypes.any,
}
