import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const useConfigureSensorModalController = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    return [open, handleOpen, handleClose];
}

function ConfigureSensorModalController({ open, onClose, sensor }) {

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg'>
            <DialogContent>
                <Typography>{JSON.stringify(sensor)}</Typography>
            </DialogContent>
        </Dialog>
    )
}

ConfigureSensorModalController.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    sensor: PropTypes.object,
}


export default function SensorConfigButton({ sensor, ...props }) {

    const [open, handleOpen, handleClose] = useConfigureSensorModalController();

    return (
        <Fragment>
            <IconButton onClick={handleOpen}>
                <SettingsIcon />
            </IconButton>
            <ConfigureSensorModalController open={open} onClose={handleClose} sensor={sensor} />
        </Fragment>
    )
}

SensorConfigButton.propTypes = {
    sensor: PropTypes.object,
}