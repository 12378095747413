import React from 'react';
import propTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import { Field, useField } from 'formik';
import * as Yup from 'yup';
import { Typography } from '@mui/material';

export const type = "text";
export const label = "Phase Name";
export const defaultValue = '';

export const schema = Yup.string().nullable().min(3, 'Minimum 3 characters').required('Required');

export function ManualMovementPhaseNameText({ namespace }) {
  const [field] = useField(namespace);
  return (
    <Typography>{field.value}</Typography>
  );
}

ManualMovementPhaseNameText.propTypes = {
  namespace: propTypes.string,
}

export default function ManualMovementPhaseName({ namespace, className, isSubmitting, ...props }) {
  return (
    <Field
      size='small'
      className={className}
      placeholder={label}
      component={TextField}
      name={namespace}
      type={type}
      disabled={isSubmitting}
      onClick={(ev) => ev.stopPropagation()}
    />
  );
}

ManualMovementPhaseName.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
}
