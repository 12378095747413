import { FRENCH_AZERTY_MAPPING } from "./layout-french-azerty";
import { US_INTERNATIONAL_MAPPING } from "./layout-us-international";

const computeMapping = (name, layout) => {
    const mapping = {};
    const symbols = {}
    const reverseMapping = {};
    const reverseSymbols = {};
    layout = _.merge({}, US_INTERNATIONAL_MAPPING, layout);
    _.each(layout, ({ key, symbol }, code) => {
        mapping[code] = key;
        symbols[code] = symbol;
        reverseMapping[key] = code;
        reverseSymbols[symbol] = code;
    });

    return {
        name,
        mapping,
        symbols,
        reverseMapping,
        reverseSymbols,
    }
}

export const LAYOUTS = [
    computeMapping('US INTERNATIONAL', US_INTERNATIONAL_MAPPING),
    computeMapping('FRENCH AZERTY', FRENCH_AZERTY_MAPPING),
];

export const getLayoutKey = (layout, code) => {
    return LAYOUTS[layout]?.mapping[code];
}

export const getLayoutCodeFromKey = (layout, key) => {
    return LAYOUTS[layout]?.reverseMapping[key];
}

export const getLayoutSymbol = (layout, code) => {
    return LAYOUTS[layout]?.symbols[code];
}

export const getLayoutCodeFromSymbol = (layout, symbol) => {
    return LAYOUTS[layout]?.reverseSymbols[symbol];
}
