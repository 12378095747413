import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import FirmwaresButton from './firmwares-button';
import UsersButton from './users-button';
import { ListItem, ListItemText } from '@mui/material';
import HardwareVersionsButton from './hardwareversions-button';
import OnlineDevicesButton from './devices-online-button';
import FactoryDevicesButton from './devices-factory-button';
import ProgrammersButton from './programmers-button';
import SyncDatabasesButton from './sync-databases-button';
import ResetDatabasesButton from './reset-databases-button';
import { Stack } from '@mui/system';
import FactoryAdminButton from './admin-factory-button';
import { useIsAfterSalesAdmin, useIsFactoryAdmin } from '@wyes/react-auth0';

const drawerWidth = 240;

export default function Sidebar() {

    const isFactoryAdmin = useIsFactoryAdmin();
    const isAfterSalesAdmin = useIsAfterSalesAdmin();

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    <ListItem>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' flexGrow={1} spacing={2}>
                            Synchronize
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <SyncDatabasesButton />
                                <ResetDatabasesButton />
                            </Stack>
                        </Stack>
                    </ListItem>
                </List>
                {(isFactoryAdmin || isAfterSalesAdmin) && (
                    <>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemText primary='After Sales' />
                            </ListItem>

                            <OnlineDevicesButton />
                            <UsersButton />
                        </List>
                    </>
                )}
                {isFactoryAdmin && (
                    <>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemText primary='Factory' />
                            </ListItem>
                            <FactoryAdminButton />
                            <FactoryDevicesButton />
                            <HardwareVersionsButton />
                            <FirmwaresButton />
                            <ProgrammersButton />
                        </List>
                    </>
                )}
            </Box>
        </Drawer >
    );
}
