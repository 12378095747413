import React, { Fragment, useMemo } from 'react';
import { Button, Grid, Backdrop } from '@mui/material';
import _ from 'lodash';
import StreamChart, { useDataFromDatasets, useStreamChartDefaultOptions } from './stream-chart';
import { useStartStopChartController } from './device-stream-chart';

export const useSensorsDatasets = (dataset = []) => {
  return useMemo(() => _.map(dataset, ({ name, key, data }) => ({
    __name: name,
    __key: key,
    __compute: (scope, value, data) => {
      return value;
    },
    __data: data,
  })), [dataset]);
}

// A dataset must be like :
// {
//   __name: String,
//   __key: String,
//   __needPush: Boolean,
//   __compute: (scope, value, data) => Number, (where data is the __data array below)
//   __data: Array,
// }

export default function SensorsChart({ datasets = [], defaultStarted = false, onStart = () => { }, onStop = () => { }, ...props }) {

  const [pause, started, start, setPause] = useStartStopChartController(onStart, onStop, defaultStarted);

  const data = useDataFromDatasets(datasets);
  const options = useStreamChartDefaultOptions({ pause });

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid sx={{ position: 'relative' }} container spacing={1}>
            <Grid item xs={12}>
              <Button color={started ? 'warning' : 'success'} onClick={() => start()}>{started ? 'Stop' : 'Start'}</Button>
              <Button onClick={() => setPause(pause => !pause)}>{pause ? 'Resume' : 'Pause'}</Button>
            </Grid>
            <Grid item xs={12}>
              <StreamChart {...props} data={data} options={options} />
            </Grid>
            <Backdrop onClick={() => start()} open={!started} sx={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
              <Button color='success'>Start</Button>
            </Backdrop>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )

}
