import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Grid } from '@mui/material';
import OutputActivationSchmitt, { TYPE as OAStype, schema as OASschema } from './OutputActivationSchmitt';
import OutputActivationThreshold, { defaultValue as OATdefault, TYPE as OATtype, schema as OATschema } from './OutputActivationThreshold';
import OutputActivationTypeSelector from './OutputActivationTypeSelector';
import { useField } from 'formik';
import * as Yup from 'yup';

export const schema = Yup.object({
  t: Yup.string().oneOf([OATtype, OAStype], `Must be one of [${OATtype}, ${OAStype}]`).required('Required'),
  c: Yup.object().when('t', (t, schema) => {
    if (t === OATtype) return OATschema;
    else if (t === OAStype) return OASschema;
    return schema;
  })
});

export const defaultValue = {
  t: OATtype,
  c: OATdefault
}

export default function OutputActivation({ namespace, ...props }) {

  const [t] = useField(namespace + '.t');
  const type = t.value;

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}><OutputActivationTypeSelector namespace={namespace} /></Grid>
      {type == OAStype && <Grid item xs={6}><OutputActivationSchmitt namespace={namespace + '.c'} /></Grid>}
      {type == OATtype && <Grid item xs={6}><OutputActivationThreshold namespace={namespace + '.c'} /></Grid>}
    </Grid>
  );
}

OutputActivation.propTypes = {
  namespace: propTypes.string,
}
