import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@mui/material';
import { useField } from 'formik';
import { SemVer } from 'semver';

function useVersionFieldController() {
    const [{ value }, { initialValue, error }, { setValue, setError }] = useField('version');

    const init = useMemo(() => {
        const initialVersion = new SemVer(initialValue);
        const build = initialVersion.build;
        const [bom, pcb, casing] = build;
        return {
            build,
            pcb, casing, bom
        }
    }, []);

    const [pcb, setPCB] = useState(init.pcb || "");
    const [casing, setCase] = useState(init.casing || "");
    const [bom, setBOM] = useState(init.bom || "");

    const computeVersion = ({ bom, pcb, casing }) => {
        const build = [bom, pcb, casing].join('.');
        let initialVersion = new SemVer(initialValue);
        if (bom === init.bom) {
        } else if (bom > init.bom || init.bom === undefined) {
            initialVersion = initialVersion.inc('major');
            console.log({ initialVersion });
        } else {
            setError('Case must be higher or equals');
        }

        if (pcb === init.pcb) {

        } else if (pcb > init.pcb || init.pcb === undefined) {
            initialVersion = initialVersion.inc('minor');
        } else {
            setError('PCB must be higher or equals');
        }

        if (casing === init.casing) {
        } else if (casing > init.casing || init.casing === undefined) {
            initialVersion = initialVersion.inc('patch');
        } else {
            setError('Case must be higher or equals');
        }

        setValue(`${initialVersion.format()}+${build}`.substring(0, 24));
    }

    const handlePCB = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setPCB(ev.target.value);
        computeVersion({ bom, pcb: ev.target.value, casing });
    }

    const handleCase = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setCase(ev.target.value);
        computeVersion({ bom, pcb, casing: ev.target.value });
    }

    const handleBOM = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setBOM(ev.target.value);
        computeVersion({ bom: ev.target.value, pcb, casing });
    }


    return { pcb, handlePCB, casing, handleCase, bom, handleBOM, value, error };
}

export default function HardwareVersionField({ }) {

    const { pcb, handlePCB, casing, handleCase, bom, handleBOM, value, error } = useVersionFieldController();

    return (
        <Stack spacing={2}>
            <TextField label='BOM' value={bom} onChange={handleBOM} />
            <TextField label='PCB' value={pcb} onChange={handlePCB} />
            <TextField label='Casing' value={casing} onChange={handleCase} />
            <TextField disabled value={value} error={error} />
        </Stack>
    );
}

HardwareVersionField.propTypes = {

}
