import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Stack } from '@mui/material';
import { FieldArray, useField } from 'formik';
import ReactionOutput, { defaultValue as ROdefault, schema as ROschema } from './ReactionOutput';
import * as Yup from 'yup';
import { useOnlineDeviceReactions } from '../../../live/online-device-provider';

export const schema = Yup.array().of(ROschema).min(1, 'A least one reaction is required');
export const defaultValue = [];

export default function ReactionOutputs({ namespace, ...props }) {

  const [field] = useField(namespace);
  const outputs = field.value;

  const reactions = useOnlineDeviceReactions();

  return (
    <FieldArray
      name={namespace}
      render={({ push, remove }) => (
        <Grid container spacing={2} alignItems='flex-start' justifyContent={'center'}>
          {_.map(reactions, (reaction, i) => {
            const index = _.findIndex(outputs, (o) => o.n === reaction.k);
            const ns = index >= 0 ? namespace + `.${index}` : undefined;
            const onRemove = index >= 0 ? () => remove(index) : undefined;
            const onAdd = index >= 0 ? undefined : () => push(Object.assign({}, ROdefault, { n: reaction.k }));

            return (
              <Grid key={reaction.k}  item xs={12} sm={4} lg={3}>
                <ReactionOutput reaction={reaction} namespace={ns} onRemove={onRemove} onAdd={onAdd} />
              </Grid>
            );
          })}
        </Grid>
      )}
    />
  );
}

ReactionOutputs.propTypes = {
  namespace: propTypes.string,
}