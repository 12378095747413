import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardActionArea, Chip, Typography } from '@mui/material';
import ConfigureMovementModal, { useConfigureMovementModalController } from './configure-movement-modal';
import { useOnlineDeviceIds } from '../live/online-device-provider';
import { Stack } from '@mui/system';
import RemoveMovementButton from './remove-movement-button';
import CopyMovementButton from './copy-movement-button';
import CopyToClipboardMovementButton from './copy-to-clipboard-movement-button';

export default function MovementCard({ movement, canCopy = true, ...props }) {

    const [open, handleOpen, handleClose] = useConfigureMovementModalController();
    const { serialNumber } = useOnlineDeviceIds();

    return (
        <Fragment>
            <Card sx={{ minWidth: '300px' }} >
                <CardActionArea onClick={handleOpen}>
                    <CardContent>
                        <Stack spacing={2} justifyContent='center' alignItems='center'>
                            <Stack sx={{ width: '100%' }} flexGrow={1} direction='row' alignItems='center' justifyContent='space-around'>
                                <Stack alignItems='flex-start' justifyContent='center'>
                                    <Typography variant='h6'>{movement.n}</Typography>
                                    <Typography variant='caption'>{movement.i}</Typography>
                                </Stack>
                                {movement.r ? <Chip color='primary' label='Running' /> : <Chip label='In Memory' />}
                            </Stack>
                            <Stack sx={{ width: '100%' }} flexGrow={1} direction='row' alignItems='center' justifyContent='space-around'>
                                <RemoveMovementButton sn={serialNumber} movement={movement} />
                                {canCopy && <CopyMovementButton sn={serialNumber} movement={movement} />}
                                <CopyToClipboardMovementButton movement={movement} />
                            </Stack>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
            <ConfigureMovementModal sn={serialNumber} open={open} onClose={handleClose} movement={movement} />
        </Fragment>
    )
}

MovementCard.propTypes = {
    movement: PropTypes.object,
    canCopy: PropTypes.bool,
}