import React, { useEffect } from 'react';
import MainView from './main';
import { SnackbarProvider } from 'notistack';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import { AuthenticationPage, AuthProvider } from '@wyes/react-auth0';
import { WyesThemeProvider } from '@wyes/react-themes';

function WrapperAuthenticationPage() {

  const navigate = useNavigate();

  return (
    <AuthenticationPage
      redirectMethod={(path) => navigate(path, { replace: true })}
      onError={() => navigate('/', { replace: true })} />
  )
}

function App() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.warn('RUNNING IN DEVELOPEMENT ENVIRONMENT');
    }
  }, []);

  return (
    <BrowserRouter>
      <WyesThemeProvider>
        <SnackbarProvider maxSnack={3}>
          <AuthProvider
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            cacheLocation={process.env.REACT_APP_AUTH0_CACHE_LOCATION}
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
          >
            <Routes>
              <Route path='/auth/*' element={<WrapperAuthenticationPage />} />
              <Route path='/main/*' element={<MainView />} />
              <Route path="*" element={<Navigate to='/main' replace />} />
            </Routes>
          </AuthProvider>
        </SnackbarProvider>
      </WyesThemeProvider>
    </BrowserRouter>
  );
}

export default App;
