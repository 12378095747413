
import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Backdrop, CircularProgress, Typography, Stack, Divider, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import MovementName from './MovementName';
import MovementId from './MovementId';
import ManualMovement, { TYPE as MMType, defaultValue as MMDefaultValue, schema as MMschema } from './manual-movements/ManualMovement';
import { defaultValue as MNdefault, schema as MNschema } from './MovementName';
import { defaultValue as MIdefault, schema as MIschema } from './MovementId';
import * as Yup from 'yup';
import { useSensorsStreamListener } from '../../sensors/sensors-stream';

export const schema = Yup.object({
  i: MIschema,
  n: MNschema,
  t: Yup.string().oneOf([MMType], `Movement type must be in [${MMType}].`),
  c: Yup.object().when('t', (t, schema) => {
    if (t === MMType) return MMschema;
    else return schema;
  }),
});

export const TYPE = 'mm';
export const defaultValue = {
  i: MIdefault,
  n: MNdefault,
  t: MMType,
  c: MMDefaultValue,
}

const DEFAULT_VALUES_MAP = {
  [MMType]: MMDefaultValue
}

export const useDefaultValue = (t, movement) => useMemo(() => {
  if (movement === undefined) {
    const c = DEFAULT_VALUES_MAP[t] || {};
    return { ...defaultValue, t, c }
  } else {
    return movement;
  }
}, []);

function LoadingBackdrop({ }) {
  return (
    <Backdrop sx={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} open>
      <CircularProgress />
    </Backdrop>
  )
}

LoadingBackdrop.propTypes = {
}


function MovementTypeName({ type }) {
  switch (type) {
    case MMType:
      return <Typography>Manual Movement</Typography>
    default:
      return null;
  }
}

MovementTypeName.propTypes = {
  type: propTypes.string,
}


function MovementTypeSwitch({ type, ...props }) {

  switch (type) {
    case MMType:
      return <ManualMovement {...props} />
    default:
      return null;
  }
}

MovementTypeSwitch.propTypes = {
  type: propTypes.string,
}


export default function Movement({ onSubmit = async () => { }, movement, ...props }) {

  const [type, setType] = useState(MMType);
  const defaultValue = useDefaultValue(type, movement);
  useSensorsStreamListener();

  return (
    <Formik
      validationSchema={schema}
      initialValues={defaultValue}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (e) {
          console.error(e);
        }
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, errors }) => {
        return (
          <Form>
            <Stack alignItems='center' spacing={4}>
              <Stack spacing={2} direction='row' alignItems='center'>
                <MovementTypeName type={values.t} />
                <MovementId namespace='i' />
                <MovementName namespace='n' />
              </Stack>
              <Divider />
              <MovementTypeSwitch {...props} namespace='c' type={values.t} />
              <Button disabled={Object.keys(errors).length !== 0} type='submit'>Save</Button>
            </Stack>
            {isSubmitting ? <LoadingBackdrop /> : null}
          </Form>
        )
      }}
    </Formik>
  );
}

Movement.propTypes = {
  onSubmit: propTypes.func,
  movement: propTypes.object,
  sensors: propTypes.array,
  reactions: propTypes.array,
}