import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { BASE_REGISTRATION_API_URL } from '../../../config';
import * as Yup from 'yup';
import { useRegistrationSynchronizeDB } from '../../../model/registration/RegistrationDatabase';
import { useFetch } from '../../misc/fetch';


export function useCreateProgrammer() {
    const sync = useRegistrationSynchronizeDB();
    const fetch = useFetch();
    const addCallback = useCallback(async ({ name = '', description = '' }) => {

        const response = await fetch(
            `${BASE_REGISTRATION_API_URL}/programmers/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    description
                })
            });

        if (!response.ok) {
            throw new Error(await response.text())
        }

        await sync();
    }, []);
    return addCallback;
}

const ProgrammerCreationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string(),
});

export function CreateProgrammerModal({ open, onClose, name = '', description = '' }) {

    const handleCreate = useCreateProgrammer();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add a programmer</DialogTitle>
            <Formik
                initialValues={{ name, description }}
                validationSchema={ProgrammerCreationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    await handleCreate(values);
                    setSubmitting(false);
                    onClose();
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field component={TextField} disabled={Boolean(name)} name="name" type="text" label="Name" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextField} disabled={Boolean(description)} name="description" type="text" label="Description" fullWidth />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isSubmitting} onClick={onClose} color='inherit' >Cancel</Button>
                            <Button disabled={isSubmitting} type='submit' color='primary' >Create</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>

        </Dialog>
    )
}

CreateProgrammerModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    name: PropTypes.string,
    description: PropTypes.string,
}

export default function CreateProgrammerButton({ name, description }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <IconButton onClick={handleClickOpen} aria-label="add hardware">
                <AddIcon />
            </IconButton>
            <CreateProgrammerModal open={open} onClose={handleClose} name={name} description={description} />
        </Fragment>
    );
}

CreateProgrammerButton.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
}
