import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';

export function useRevokeFirmware(firmware) {
    const revokeCallback = useCallback(async () => {
        await firmware.markRevoked();
    }, []);
    return revokeCallback;
}

export function ConfirmRevokeFirmwareModal({ open, onClose, firmware }) {

    const revoke = useRevokeFirmware(firmware);

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
            <DialogTitle>Revoke {firmware.version} ? </DialogTitle>
            <DialogActions>
                <Button color='primary' onClick={onClose} autoFocus>Cancel</Button>
                <Button color='inherit' onClick={() => revoke().finally(onClose)}>
                    Revoke
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmRevokeFirmwareModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    device: PropTypes.any,
    firmware: PropTypes.any,
}

export default function RevokeButton({ firmware }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Button color='warning' onClick={handleClickOpen} aria-label="revoke firmware">
                Revoke
            </Button>
            <ConfirmRevokeFirmwareModal open={open} onClose={handleClose} firmware={firmware} />
        </Fragment>
    );
}

RevokeButton.propTypes = {
    firmware: PropTypes.any,
}
