import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useField } from 'formik';
import ExpressionsList from '../../expressions/ExpressionsList';
import StepBackBone, { useStepperController } from './step-backbone';
import { createFilterOptions, Stack } from '@mui/material';
import { SensorsChart } from '@wyes/web-react-devices';
import { useSensorStreamController } from '../../../../sensors/sensors-stream';
import { useSensorsDatasetFromField } from './choose-inputs';
import { evaluate } from 'mathjs';
import { Autocomplete } from 'formik-mui';

const expressionAlgorithm = (expression) => {
  return (scope, value, history) => {
    try {
      return evaluate(expression, scope);
    } catch (e) {
      return null;
    }
  }
}

export const useExpressionsDatasetFromField = (namespace) => {
  const [{ value }, meta] = useField(namespace);
  const [data, setData] = useState([]);

  useEffect(() => {
    const data = [];
    _.each(value, (expression) => {
      data.push({
        noBackground: true,
        __name: expression.n,
        __key: expression.n,
        __compute: expressionAlgorithm(expression.e),
      });
    });
    setData(data);
  }, [value, setData]);

  return { data, value };
}

export default function WriteExpressions({ namespace, device, ...props }) {

  const { nextDisabled, setNextButtonDisabled } = useStepperController();
  const [field, meta] = useField(namespace + '.e');

  const { start, stop, activated, dataset, emitter } = useSensorStreamController();
  const { data: sensors } = useSensorsDatasetFromField(namespace + '.i', dataset);
  const { data: expressions } = useExpressionsDatasetFromField(namespace + '.e');

  const data = useMemo(() => [...sensors, ...expressions], [sensors, expressions]);

  useEffect(() => {
    setNextButtonDisabled(meta.error);
  }, [meta.error, setNextButtonDisabled]);

  return (
    <StepBackBone nextDisabled={nextDisabled} {...props}>
      <Stack spacing={2}>
        <SensorsChart defaultStarted={activated} onStart={start} onStop={stop} datasets={data} emitter={emitter} height='500px' />
        <ExpressionsList namespace={namespace + '.e'} sensors={sensors} />
      </Stack>
    </StepBackBone>
  );
}

WriteExpressions.propTypes = {
  namespace: propTypes.string,
  device: propTypes.object,
}