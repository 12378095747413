import { useMemo } from 'react'
import { createTheme } from '@mui/material/styles'

export const useDefaultWyesTheme = (mode) => {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: '#ffe54c',
            main: '#ffb300',
            dark: '#c68400',
            contrastText: '#000'
          },
          secondary: {
            light: '#8bf6ff',
            main: '#4fc3f7',
            dark: '#0093c4',
            contrastText: '#000'
          },
          mode
        }
      }),
    [mode]
  )
  return theme
}
