
import React, { useEffect, useState } from 'react'
import { Stack, CircularProgress, Typography, Button } from "@mui/material"
import { useAuth0 } from '@auth0/auth0-react'

const Auth0WaitLoading = () => {
  return (
    <Stack direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <CircularProgress />
      <Typography>Authentication provider loading.</Typography>
    </Stack>
  )
}

const Auth0Loaded = ({ redirectMethod = (path) => window.location.replace(path), defaultPath = '/', onError, errorHint = 'Get me out' }) => {
  const { error, user, handleRedirectCallback } = useAuth0();
  const [internalError, setInternalError] = useState(null);

  useEffect(() => {

    const routine = async () => {
      try {
        const { appState } = await handleRedirectCallback(window.location.href);
        if (appState && appState.redirect) {
          redirectMethod(appState.redirect);
        } else {
          redirectMethod(defaultPath);
        }
      } catch (e) {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('error')) {
          const name = searchParams.get('error');
          const message = searchParams.get('error_description');
          const error = new Error(message || name);
          error.name = name;
          setInternalError(error);
        }
      }
    }

    routine();

  }, []);

  if (error || internalError) {
    return (
      <Stack direction='column'>
        <Typography>An error occured in the authentication proccess:</Typography>
        <Typography>{error ? error.message : internalError.message}</Typography>
        {onError && <Button onClick={onError}>{errorHint}</Button>}
      </Stack>
    )
  }

  return (
    <Stack direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <CircularProgress />
      <Typography>Authentication process running.</Typography>
    </Stack>
  )
}


export default function AuthenticationPage({ redirectMethod, defaultPath, onError, errorHint }) {

  const { isLoading } = useAuth0();

  return (
    <Stack sx={{
      flexGrow: 1,
    }}
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}>
      {isLoading ? <Auth0WaitLoading /> : <Auth0Loaded
        redirectMethod={redirectMethod}
        defaultPath={defaultPath}
        onError={onError} errorHint={errorHint}
      />}
    </Stack >
  )
}
