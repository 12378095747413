import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { BASE_ADMIN_API_URL } from '../../../../config';
import { useOnlineDeviceApi, useOnlineDeviceMovements } from '../live/online-device-provider';
import { useFetch } from '../../../misc/fetch';

export const useMovementRemoveAction = (sn, key) => {

    const [disabled, setDisabled] = useState(false);
    const { setMovements } = useOnlineDeviceApi();
    const movements = useOnlineDeviceMovements();
    const fetch = useFetch();
    const handleRemove = useCallback(async (ev) => {
        setDisabled(true);
        ev.preventDefault();
        ev.stopPropagation();

        try {
            const response = await fetch(
                `${BASE_ADMIN_API_URL}/actions/devices/${sn}/movements/erase/${key}`,
                {
                    method: 'GET',
                });

            if (!response.ok) {
                throw new Error(await response.text());
            }

            setMovements(_.remove(movements, m => m.i !== key));
        } catch (e) {
            console.error(e);
        }
        setDisabled(false);
    }, [movements]);

    return [handleRemove, disabled];
}


export default function RemoveMovementButton({ sn, movement }) {

    const [handleRemove, disabled] = useMovementRemoveAction(sn, movement.i);
    const title = 'Delete';

    return (
        <Tooltip title={title}>
            <span>
                <IconButton disabled={disabled} onClick={handleRemove} aria-label="remove movement">
                    <DeleteIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
}

RemoveMovementButton.propTypes = {
    sn: PropTypes.string,
    movement: PropTypes.object,
}
