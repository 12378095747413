import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { useField } from 'formik';
import * as Yup from 'yup';
import { FormControlLabel, Switch } from '@mui/material';

export const type = "checkbox";
export const label = "Mandatory";
export const defaultValue = false;
export const schema = Yup.boolean().required('Required');

export default function ReactionOutputMandatory({ namespace, className, isSubmitting, ...props }) {
  const [{ value }, meta, { setValue }] = useField(namespace);

  useEffect(() => {
    if (value === undefined) {
      setValue(defaultValue);
    }
  }, []);

  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <FormControlLabel
      sx={{ justifyContent: 'space-evenly' }}
      control={<Switch
        color="primary"
        checked={value === undefined ? defaultValue : value}
        onChange={handleChange}
        disabled={isSubmitting}
      />}
      label={label}
      labelPlacement="start"
    />

  );
}

ReactionOutputMandatory.propTypes = {
  namespace: propTypes.string,
  className: propTypes.string,
  isSubmitting: propTypes.bool,
}