import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Grow, List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { Q } from '@nozbe/watermelondb'
import withObservables from '@nozbe/with-observables'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import { withAfterSalesDatabase } from '../../../../model/aftersales/AfterSalesDatabase';
import { W_CONTROLLERS_0 } from '../../../../model/Hardware';
import SelectDeviceWithScanner from '../factory/select-with-scanner';
import { topicBuilder, useMqttListener } from '@wyes/web-react-devices';

export const useNavigateToDevice = () => {
    const navigate = useNavigate();
    const handleClick = useCallback((id) => {
        navigate(`${id}/details`);
    }, []);
    return handleClick;
}


export const useConnectedDevicesListener = () => {
    const [lastUpdate, setLastUpdate] = React.useState(null);
    const [devices, setDevices] = useState({});
    const listener = useCallback((data, params) => {
        setLastUpdate(new Date());
        setDevices(devices => ({
            ...devices,
            [params.serialNumber]: data,
        }))
    }, []);
    const topic = useMemo(() => topicBuilder(`v1/dev/+serialNumber/d/connection`), []);
    useMqttListener(topic, listener);
    return { devices, lastUpdate };
}

export function DeviceListItem({ device }) {

    const handleClick = useNavigateToDevice();

    return (
        <Grow in>
            <ListItem onClick={() => handleClick(device.id)}>
                <ListItemButton>
                    <ListItemText primary={`${device.id}`} secondary={`${device.hardware} - ${device.factoryStatus}`} />
                </ListItemButton>
            </ListItem>
        </Grow>
    )
}

DeviceListItem.propTypes = {
    device: PropTypes.any,
}

const observeDevice = withObservables(['device'], ({ device }) => ({
    device,
}));

export const ObservedDeviceListItem = withAfterSalesDatabase(observeDevice(DeviceListItem));

export function DevicesListInternal({ devices }) {
    return (
        <List>
            {_.map(devices, (device) => <ObservedDeviceListItem key={device.id} device={device} />)}
        </List>
    );
}

DevicesListInternal.propTypes = {
    devices: PropTypes.array
}

const observeOnlineDevices = withObservables(['onlineDevices'], ({ database, onlineDevices }) => ({
    devices: database.get('devices').query(
        Q.and(
            Q.where('id', Q.oneOf(onlineDevices)),
            Q.where('hardware', W_CONTROLLERS_0)
        ),
        Q.sortBy('updated_at', Q.desc))
}));

const observeAllDevices = withObservables([], ({ database }) => ({
    devices: database.get('devices').query(
        Q.and(Q.where('hardware', W_CONTROLLERS_0)),
        Q.sortBy('updated_at', Q.desc)),
}));

export const ObservedOnlineDevicesList = withAfterSalesDatabase(observeOnlineDevices(DevicesListInternal))
export const ObservedAllDevicesList = withAfterSalesDatabase(observeAllDevices(DevicesListInternal))

export default function DevicesList({ }) {

    const handleClick = useNavigateToDevice();
    const { devices } = useConnectedDevicesListener();

    const onlineDevices = useMemo(() => _.compact(
        _.map(devices,
            (device, serialNumber) => device.state === 'connected' ? serialNumber : null
        )
    ), [devices]);

    return (
        <Stack spacing={2}>
            <Card>
                <CardHeader
                    title="Online Devices"
                />
                <CardContent>
                    <ObservedOnlineDevicesList onlineDevices={onlineDevices} />
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    title="All Devices"
                    action={<SelectDeviceWithScanner onClick={handleClick} />}
                />
                <CardContent>
                    <ObservedAllDevicesList />
                </CardContent>
            </Card>
        </Stack>
    );
};

DevicesList.propTypes = {
    query: PropTypes.any,
}
