import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpressionName, { defaultValue as ENdefault, schema as ENschema } from './ExpressionName';
import ExpressionEquation, { defaultValue as EEdefault, schema as EEschema } from './ExpressionEquation';
import * as Yup from 'yup';

export const schema = Yup.object({
  n: ENschema,
  e: EEschema,
});
export const defaultValue = {
  n: ENdefault,
  e: EEdefault,
};

export default function Expresion({ namespace, onRemove = () => { }, ...props }) {
  return (
    <Stack spacing={1} direction='row' alignItems='center'>
      <ExpressionName namespace={namespace + '.n'} />
      <Typography>=</Typography>
      <ExpressionEquation namespace={namespace + '.e'} {...props} />
      <Stack flexGrow={1} direction='row' justifyContent='center' alignItems='center'>
        <IconButton onClick={onRemove}><DeleteIcon /></IconButton>
      </Stack>
    </Stack>
  )
}

Expresion.propTypes = {
  namespace: propTypes.string,
  onRemove: propTypes.func,
}
