import { appSchema } from '@nozbe/watermelondb'
import { deviceSchema } from '../Device';
import { firmwareSchema } from '../Firmware';
import { userSchema } from '../User';

export default appSchema({
  version: 1,
  tables: [
    deviceSchema,
    firmwareSchema,
    userSchema,
  ]
});
