import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import Header from './header';
import Sidebar from './sidebar';
import Workbench from './workbench';
import Views from './views';
import { JobsLock, JobsProvider, MqttClientProvider, useJobsState, useSubscribeJobsState } from '@wyes/web-react-devices';
import { AuthRequired, useAuthUser } from '@wyes/react-auth0';
import AfterSalesDatabase from '../model/aftersales/AfterSalesDatabase';
import RegistrationDatabase from '../model/registration/RegistrationDatabase';

function MainViewWithMqttProvider() {

  const user = useAuthUser();
  const name = useMemo(() => user.sub, []);

  return (
    <AfterSalesDatabase suffix={name}>
      <RegistrationDatabase suffix={name}>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Header />
          <Sidebar />
          <Workbench>
            <Views />
          </Workbench>
        </Box>
      </RegistrationDatabase>
    </AfterSalesDatabase>
  )
}

export default function MainView() {
  return (
    <AuthRequired>
        <MqttClientProvider url='wss://websocket.iot.fr-par.scw.cloud' username='876d6fb4-60e9-4f73-a380-8856b462f1c4'>
          <MainViewWithMqttProvider />
        </MqttClientProvider>
    </AuthRequired>
  );
}
