import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import _ from 'lodash';
import { useCapabilities } from './capabilities-provider';
import { QRCodeHandler } from './qrcode-button';


export default function ProgrammerCapabilities({ navigate }) {

    const capabilities = useCapabilities();

    const handleClick = useCallback((capability) => () => navigate(`cap/${capability}`), []);

    return (
        <Stack direction='row' spacing={2} flexGrow={1} justifyContent='center' alignItems='center'>
            {_.map(capabilities, ({ name, configured }) => (
                <Card key={name} sx={{ flexGrow: 1 }}>
                    <CardActionArea disabled={!configured} onClick={handleClick(name)}>
                        <CardContent sx={{ width: '100%', height: '100%' }}>
                            <Box sx={{ height: '200px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography gutterBottom variant="h5" component="div" color={configured ? undefined : 'gray'}>
                                    {name}
                                </Typography>
                                <QRCodeHandler disabled={!configured} value={name} onClick={handleClick(name)} />
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </Stack>
    )
}

ProgrammerCapabilities.propTypes = {
    navigate: PropTypes.any,
}
