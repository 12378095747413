import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { createSensor, useOnlineDeviceApi, useOnlineDeviceIds, useOnlineDeviceSensors } from '../live/online-device-provider';
import SensorCard from './sensor-card';
import SensorsLoadListIconButton from './sensors-load-list';
import { topicBuilder, useMqttListener } from '@wyes/web-react-devices';

export const useSensorsListListener = (serialNumber, cb) => {
    const listener = useCallback((data) => {
        data = data.map(d => createSensor(d));
        cb(data);
    }, [cb]);
    const topic = useMemo(() => topicBuilder(`v1/dev/${serialNumber}/d/sensors/list`), [serialNumber]);
    useMqttListener(topic, listener);
}

export default function SensorsList({ ...props }) {

    const { serialNumber } = useOnlineDeviceIds();
    const { setSensors } = useOnlineDeviceApi();
    const sensors = useOnlineDeviceSensors();

    useSensorsListListener(serialNumber, setSensors);

    return (
        <Card>
            <CardHeader
                title={`Sensors (${sensors.length})`}
                action={<SensorsLoadListIconButton sn={serialNumber} />} />
            <CardContent>
                <Stack spacing={2} direction='column' alignItems='left'>
                    {sensors.map(s => <SensorCard key={s.k} sensor={s} />)}
                </Stack>
            </CardContent>
        </Card>

    )
}

SensorsList.propTypes = {

}