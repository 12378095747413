import { useField } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactionOutputs from '../../reaction-outputs/ReactionOutputs';
import StepBackBone, { useStepperController } from './step-backbone';

export default function ChooseReactions({ namespace, ...props }) {

  const { nextDisabled, setNextButtonDisabled } = useStepperController();
  const [field, meta] = useField(namespace + '.o');

  useEffect(() => {
      setNextButtonDisabled(meta.error);
  }, [meta.error, setNextButtonDisabled]);

  return (
    <StepBackBone nextDisabled={nextDisabled} {...props}>
      <ReactionOutputs namespace={namespace + '.o'} />
    </StepBackBone>
  );
}

ChooseReactions.propTypes = {
  namespace: propTypes.string,
}