import React, { } from 'react';
import PropTypes from 'prop-types';
import { Card, CardActionArea, CardHeader } from '@mui/material';
import SensorsIcon from '@mui/icons-material/Sensors';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import SensorConfigButton from './sensor-config-button';

const Avatars = {
    ["ls"]: BlurLinearIcon,
}

function SensorCardWrapper({ children, onClick }) {
    if (!onClick) return children;
    return (
        <CardActionArea onClick={onClick}>
            {children}
        </CardActionArea>
    )
}

SensorCardWrapper.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
}

export default function SensorCard({ sensor, onClick, noAction = false, ...props }) {
    const Avatar = Avatars[sensor.t] || SensorsIcon;

    return (
        <Card>
            <SensorCardWrapper>
                <CardHeader
                    title={sensor.n}
                    subheader={sensor.k}
                    avatar={<Avatar />}
                    action={!noAction && <SensorConfigButton sensor={sensor} />}
                />
            </SensorCardWrapper>
        </Card>
    )
}

SensorCard.propTypes = {
    sensor: PropTypes.object,
    onClick: PropTypes.func,
    noAction: PropTypes.bool,
}