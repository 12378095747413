import React, { useCallback } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WebhookIcon from '@mui/icons-material/Webhook';
import { useNavigate } from 'react-router-dom';
import { useIsFactoryAdmin } from '@wyes/react-auth0';

export default function FirmwaresButton() {
    const navigate = useNavigate();
    const handleClick = useCallback(() => {
        navigate('factory/firmwares/');
    }, []);

    const isFactoryAdmin = useIsFactoryAdmin();
    if(!isFactoryAdmin) return null;

    return (
        <ListItem disablePadding onClick={handleClick}>
            <ListItemButton>
                <ListItemIcon>
                    <WebhookIcon />
                </ListItemIcon>
                <ListItemText primary={'Firmwares'} />
            </ListItemButton>
        </ListItem>

    );
}
