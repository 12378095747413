import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import _ from 'lodash';
import ProgrammerParameter from './programmer-parameter';

export default function ProgrammerTaskSession({ session }) {

    return (
        <Card variant='outlined' sx={{ flexGrow: 1 }}>
            <CardHeader title={'Session'} />
            <CardContent>
                <Grid container spacing={1}>
                    {_.map(session, parameter => <Grid key={parameter.name} item xs>
                        <ProgrammerParameter parameter={parameter} />
                    </Grid>)}
                </Grid>
            </CardContent>
            <CardActions disableSpacing>
            </CardActions>
        </Card>
    )
}

ProgrammerTaskSession.propTypes = {
    session: PropTypes.any,
}
