import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProgrammersList from './list';
import ProgrammersDetails from './details';
import { useIsFactoryAdmin } from '@wyes/react-auth0';

export default function ProgrammersViews() {

    const isFactoryAdmin = useIsFactoryAdmin();
    if(!isFactoryAdmin) return null;

    return (
        <Routes>
            <Route path='/' exact element={<ProgrammersList />} />
            <Route path='/:id/*' element={<ProgrammersDetails />} />
        </Routes>
    );
}
