import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Box, Grid, Paper, Typography, Switch, FormControlLabel, Divider } from '@mui/material';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useSocket } from './connection-provider';
import ProgrammerCapabilities from './programmer-capabilities';
import ProgrammerTasks from './programmer-tasks';
import ProgrammerTask from './programmer-task';
import _ from 'lodash';
import ProgrammerConsoleActions from './programmer-console-actions';
import ProgrammerCapabilitiesProvider from './capabilities-provider';

const LEVEL_TO_COLOR = {
    'debug': 'white',
    'log': 'white',
    'info': 'green',
    'warn': 'orange',
    'error': 'red',
}

const AlwaysScrollToBottom = ({ enabled }) => {
    const elementRef = useRef();
    useEffect(() => {
        if (enabled) elementRef.current.scrollIntoView()
    });
    return <div ref={elementRef} />;
};

AlwaysScrollToBottom.propTypes = {
    enabled: PropTypes.bool,
}

export const useConsoleOverSockets = () => {
    const socket = useSocket();
    const [logs, setLogs] = useState([]);

    const clearLogs = useCallback(() => {
        setLogs([]);
    }, []);

    useEffect(() => {
        if (socket !== null) {
            const _socket = socket;
            const handler = ({ level, message, id }) => {
                logs.push({ color: LEVEL_TO_COLOR[level] || 'inherit', message: `[${id}][${level}] ${message}`, id });
                if (logs.length > 10000) {
                    logs.shift();
                }
            }

            socket.on('console', handler);

            return () => {
                _socket.off('console', handler);
            }
        }
    }, [socket, logs]);

    return [logs, clearLogs];
}

export function ProgrammerConsoleScreen({ }) {

    const [logs, clearLogs] = useConsoleOverSockets();
    const [refresh, setRefresh] = useState();
    const [autoScroll, setAutoScroll] = useState(false);

    const handleScroll = (ev) => setAutoScroll(ev.target.checked);

    useEffect(() => {
        const interval = setInterval(() => setRefresh(new Date()), 500);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box sx={{ width: '100%', height: '100%', p: 2 }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }} component={Paper} variant='outlined' >
                <Stack sx={{ p: 1 }} direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                    <Typography sx={{ ml: 1 }}>Console</Typography>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <Button onClick={clearLogs}>Clear</Button>
                        <FormControlLabel label='Autoscroll' labelPlacement='start' control={<Switch size='small' checked={autoScroll} onChange={handleScroll} />} />
                    </Stack>
                </Stack>
                <Box sx={{ marginX: 2, height: '100px', flexGrow: 1 }} >
                    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
                        {_.map(logs, ({ message, color, id }) => <Typography sx={{ display: 'block' }} key={id} variant='caption' color={color}>{message}</Typography>)}
                        <AlwaysScrollToBottom enabled={autoScroll} />
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}

export function ProgrammerConsoleSideView({ }) {

    const navigate = useNavigate();


    return (
        <Box sx={{ width: '100%', height: '100%', p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProgrammerConsoleActions />
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Routes>
                        <Route path='/cap' exact element={<ProgrammerCapabilities navigate={navigate} />} />
                        <Route path='/cap/:capId' exact element={<ProgrammerTasks />} />
                        <Route path='/cap/:capId/:taskId' exact element={<ProgrammerTask />} />
                        <Route path='*' element={<Navigate to='cap' />} />
                    </Routes>
                </Grid>
            </Grid>
        </Box>
    )
}

ProgrammerConsoleSideView.propTypes = {
}

export default function ProgrammerConsole({ }) {


    return (
        <ProgrammerCapabilitiesProvider>
            <Grid flexGrow={1} spacing={1} container>
                <Grid item xs={4}>
                    <ProgrammerConsoleScreen />
                </Grid>
                <Grid item xs={8}>
                    <ProgrammerConsoleSideView />
                </Grid>
            </Grid>
        </ProgrammerCapabilitiesProvider>
    )
}

ProgrammerConsole.propTypes = {
}
