import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { useTasks } from './capabilities-provider';
import { QRCodeHandler } from './qrcode-button';

export default function ProgrammerTasks({ }) {

    const { capId } = useParams();
    const tasks = useTasks(capId);
    const navigate = useNavigate();

    const handleClick = useCallback((task) => () => navigate(task), []);

    return (
        <Stack direction='row' spacing={2} flexGrow={1} justifyContent='center' alignItems='center'>
            {_.map(tasks, ({ name, description }) => (
                <Card key={name} sx={{ flexGrow: 1 }}>
                    <CardActionArea onClick={handleClick(name)}>
                        <CardContent sx={{ width: '100%', height: '100%' }}>
                            <Box sx={{ height: '200px', width: '100%', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography gutterBottom variant="h5" component="p" >
                                    {name}
                                </Typography>
                                <Typography gutterBottom variant="body" component="p" >
                                    {description}
                                </Typography>
                                <QRCodeHandler value={name} onClick={handleClick(name)} />
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </Stack>
    )
}

ProgrammerTasks.propTypes = {
}
