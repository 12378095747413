import React from 'react';
import HardwaresVersionsList from './list';
import HardwareVersionsDetails from './details';
import { Route, Routes } from 'react-router-dom';
import { useIsFactoryAdmin } from '@wyes/react-auth0';

export default function HardwareVersionsView() {

    const isFactoryAdmin = useIsFactoryAdmin();
    if(!isFactoryAdmin) return null;

    return (
        <Routes>
            <Route path='/' exact element={<HardwaresVersionsList />} />
            <Route path='/:id' element={<HardwareVersionsDetails />} />
        </Routes>
    );
}
