
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { BASE_REGISTRATION_API_URL } from '../../../config';
import copy from 'copy-to-clipboard';
import { useRegistrationSynchronizeDB } from '../../../model/registration/RegistrationDatabase';
import { useSnackbar } from 'notistack';
import { useFetch } from '../../misc/fetch';

export function useRegisterProgrammer(programmer) {
    const [loading, setLoading] = useState(false);
    const sync = useRegistrationSynchronizeDB();
    const { enqueueSnackbar } = useSnackbar();
    const fetch = useFetch();

    const registerCallback = useCallback(async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setLoading(true);
        try {
            const response = await fetch(
                `${BASE_REGISTRATION_API_URL}/programmers/${programmer.id}/register`,
                {
                    method: 'GET',
                });

            if (!response.ok) {
                throw new Error(await response.text())
            }

            const { token } = await response.json();
            copy(token);
            enqueueSnackbar('Token copied to clipboard !', { variant: 'success' });
            await sync();
        } catch (e) {
            enqueueSnackbar('An error occured ...', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [programmer]);
    return [registerCallback, loading];
}

export default function RegisterProgrammerButton({ programmer }) {
    const [handleRegister, loading] = useRegisterProgrammer(programmer);

    return (
        <Button disabled={loading} onClick={handleRegister} endIcon={<KeyIcon />}>
            Generate the Token
        </Button>
    );
}

RegisterProgrammerButton.propTypes = {
    programmer: PropTypes.any,
}
