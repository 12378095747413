import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Stack } from '@mui/material';
import AddFirmwareButton from './add-firmware-modal';
import FirmwaresList from './list';
import { W_BLE_0, W_CONTACTORS_0, W_CONTROLLERS_0 } from '../../../model/Hardware';
import { useIsFactoryAdmin } from '@wyes/react-auth0';

function FirmwaresListByHardware() {

    return (
        <Stack spacing={2}>
            <FirmwaresList
                title={`Firmwares for ${W_CONTROLLERS_0}`}
                action={<AddFirmwareButton hardware={W_CONTROLLERS_0} />}
                hardware={W_CONTROLLERS_0} />
            <FirmwaresList
                title={`Firmwares for ${W_CONTACTORS_0}`}
                action={<AddFirmwareButton hardware={W_CONTACTORS_0} />}
                hardware={W_CONTACTORS_0} />
            <FirmwaresList
                title={`Firmwares for ${W_BLE_0}`}
                action={<AddFirmwareButton hardware={W_BLE_0} />}
                hardware={W_BLE_0} />
        </Stack>
    )
}

export default function FirmwaresView() {
    const isFactoryAdmin = useIsFactoryAdmin();
    if(!isFactoryAdmin) return null;

    return (
        <Routes>
            <Route path='/' exact element={<FirmwaresListByHardware />} />
        </Routes>
    );
}
