import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import EventEmitter from 'events';

const defaultSensor = {
    k: null,
    t: null,
    n: null,
}

export const createSensor = ({ k = null, n = null, t = null, ...props }) => {
    return {
        ...defaultSensor,
        ...props,
        k,
        t,
        n
    }
}

const defaultReaction = {
    k: null,
    t: null,
    n: null,
    e: [],
}

export const createReaction = ({ k = null, n = null, t = null, e = [], ...props }) => {
    return {
        ...defaultReaction,
        ...props,
        e,
        k,
        t,
        n
    }
}

const defaultContactorDevice = {
    serialNumber: null,
    hardwareVersion: null,
    productId: null,
}

export const createContactorDevice = (serialNumber = null, hardwareVersion = null, productId = null) => {
    return {
        ...defaultContactorDevice,
        serialNumber,
        hardwareVersion,
        productId
    }
}

const defaultExtensionDevice = {
    serialNumber: null,
    hardwareVersion: null,
    productId: null,
}

export const createExtensionDevice = (serialNumber = null, hardwareVersion = null, productId = null) => {
    return {
        ...defaultExtensionDevice,
        serialNumber,
        hardwareVersion,
        productId
    }
}

const defaultOnlineDevice = () => ({
    serialNumber: null,
    hardwareVersion: null,
    productId: null,
    state: {},
    contactors: [],
    extensions: [],
    sensors: [],
    reactions: [],
    movements: [],
    connections: [],
    stream: {
        emitter: new EventEmitter(),
        activated: false,
        dataset: [],
    }
});

const defaultOnlineDeviceContext = {
    ...defaultOnlineDevice(),

    api: {
        setIds: (hardwareVersion, productId) => console.error('early call to setIds()'),
        setState: (state) => console.error('early call to setState()'),
        setContactors: (contactors) => console.error('early call to setContactors()'),
        setExtensions: (extensions) => console.error('early call to setExtensions()'),
        setSensors: (sensors) => console.error('early call to setSensors()'),
        setReactions: (reactions) => console.error('early call to setReactions()'),
        setMovements: (movements) => console.error('early call to setMovements()'),
        setConnections: (connections) => console.error('early call to setConnections()'),
        setStreamActivation: (activation) => console.error('early call to setStreamActivation()'),
    }
};

const OnlineDeviceContext = createContext(defaultOnlineDeviceContext);

export const useOnlineDevice = () => useContext(OnlineDeviceContext);
export const useOnlineDeviceApi = () => useContext(OnlineDeviceContext).api;
export const useOnlineDeviceIds = () => {
    const { serialNumber, hardwareVersion, productId } = useContext(OnlineDeviceContext);
    return { serialNumber, hardwareVersion, productId };
}
export const useOnlineDeviceState = () => useContext(OnlineDeviceContext).state;
export const useOnlineDeviceContactors = () => useContext(OnlineDeviceContext).contactors;
export const useOnlineDeviceExtensions = () => useContext(OnlineDeviceContext).extensions;
export const useOnlineDeviceSensors = () => useContext(OnlineDeviceContext).sensors;
export const useOnlineDeviceReactions = () => useContext(OnlineDeviceContext).reactions;
export const useOnlineDeviceMovements = () => useContext(OnlineDeviceContext).movements;
export const useOnlineDeviceConnections = () => useContext(OnlineDeviceContext).connections;
export const useOnlineDeviceStream = () => useContext(OnlineDeviceContext).stream;

export default function OnlineDeviceProvider({ serialNumber, children = null }) {
    const [device, setDevice] = useState({ ...defaultOnlineDevice(), serialNumber });

    const setIds = useCallback((hardwareVersion = null, productId = null) => {
        setDevice(device => {
            return {
                ...device,
                hardwareVersion,
                productId
            }
        });
    }, [setDevice]);

    const setState = useCallback((state = {}) => {
        setDevice(device => {
            return {
                ...device,
                state
            }
        });
    }, [setDevice]);
    const setContactors = useCallback((contactors = []) => {
        setDevice(device => {
            return {
                ...device,
                contactors
            }
        });
    }, [setDevice]);
    const setExtensions = useCallback((extensions = []) => {
        setDevice(device => {
            return {
                ...device,
                extensions
            }
        });
    }, [setDevice]);
    const setSensors = useCallback((sensors = []) => {
        setDevice(device => {
            return {
                ...device,
                sensors,
            }
        });
    }, [setDevice]);
    const setReactions = useCallback((reactions = []) => {
        setDevice(device => {
            return {
                ...device,
                reactions
            }
        });
    }, [setDevice]);
    const setMovements = useCallback((movements = []) => {
        setDevice(device => {
            return {
                ...device,
                movements
            }
        });
    }, [setDevice]);
    const setConnections = useCallback((connections = []) => {
        setDevice(device => {
            return {
                ...device,
                connections
            }
        });
    }, [setDevice]);

    const setStreamActivation = useCallback((activated = false) => {
        setDevice(device => {
            const dataset = activated ? [] : device.stream.dataset;
            if (activated) {
                device.sensors.forEach(s => dataset.push({ name: s.n, key: s.k, data: [] }));
            }
            return {
                ...device,
                stream: {
                    ...device.stream,
                    activated,
                    dataset,
                }
            }
        });
    }, [setDevice]);

    const context = useMemo(() => ({
        ...device,
        api: {
            setIds,
            setState,
            setContactors,
            setExtensions,
            setSensors,
            setReactions,
            setMovements,
            setConnections,
            setStreamActivation
        }
    }), [device, setIds, setState, setContactors, setExtensions, setSensors,
        setReactions, setMovements, setConnections, setStreamActivation]);

    return (
        <OnlineDeviceContext.Provider value={context}>
            {children}
        </OnlineDeviceContext.Provider>
    )
}

OnlineDeviceProvider.propTypes = {
    serialNumber: PropTypes.string,
    children: PropTypes.any
}
