import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useSensorStreamController } from '../sensors/sensors-stream';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

export default function StreamButton() {
    const { start, stop, activated } = useSensorStreamController();

    if (activated) {
        return (
            <Button variant="outlined" startIcon={<PauseIcon />} onClick={stop}>
                Stop
            </Button>
        )
    } else {
        return (
            <Button variant="outlined" startIcon={<PlayArrowIcon />} onClick={start}>
                Start
            </Button>
        )
    }
}

StreamButton.propTypes = {

}
