import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useAfterSalesDatabase } from '../../../model/aftersales/AfterSalesDatabase';

export function useCreateUser() {
    const database = useAfterSalesDatabase();
    const addCallback = useCallback(async (data) => {
        const user = await database.write(async () => {
            return await database.get('users')
                .create(u => {
                    u.name = data.name;
                    u.mail = data.mail;
                })
        });
        return user;
    }, []);
    return addCallback;
}

export function AddUserModal({ open, onClose }) {
    const handleAdd = useCreateUser();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add a user</DialogTitle>
            <Formik
                initialValues={{ name: '', mail: '', }}
                onSubmit={async (values, { setSubmitting }) => {
                    const user = await handleAdd(values);
                    setSubmitting(false);
                    onClose();
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field component={TextField} name="name" type="text" label="Name" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextField} name="mail" type="email" label="Mail" fullWidth />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isSubmitting} onClick={onClose}>Cancel</Button>
                            <Button disabled={isSubmitting} type='submit' color='secondary'>Add</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>

        </Dialog>
    )
}

AddUserModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default function AddUserButton() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <IconButton onClick={handleClickOpen} aria-label="add user">
                <AddIcon />
            </IconButton>
            <AddUserModal open={open} onClose={handleClose} />
        </Fragment>
    );
}
