import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useTask } from './capabilities-provider';
import ProgrammerTaskSession from './programmer-task-session';
import ProgrammerTaskSteps from './programmer-task-steps';
import { useSocket } from './connection-provider';
import QRCodeButton from './qrcode-button';

export function useCreateTaskController(capability, task) {
    const socket = useSocket();
    const [controller, setController] = useState(null);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(null);
    const [status, setStatus] = useState('idle');

    const init = useCallback(() => {
        return new Promise((resolve, reject) => {
            socket.emit('tasks:initialize', { capability, task }, ({ error, task }) => {
                if (error) {
                    setError(error);
                    reject(error);
                } else {
                    setStatus('initialized');
                    setController(task);
                    resolve(task);
                }
            });
        });
    }, []);

    const start = useCallback((id) => {
        socket.emit(`task:${id}:cmd:start`);
    }, []);

    const reset = useCallback((id) => {
        socket.emit(`task:${id}:cmd:reset`, () => {
            setStatus('idle');
        });
    }, []);

    useEffect(() => {
        const _socket = socket;
        const _id = controller && controller.id;

        if (_id) {

            _socket.on(`task:${_id}:prestart`, () => {

            });

            _socket.on(`task:${_id}:start`, () => {
                setStatus('started');
                setError(null);
                setProgress(null);
            });

            _socket.on(`task:${_id}:progress`, ({ progress }) => {
                setProgress(progress);
            });

            _socket.on(`task:${_id}:stop`, ({ error }) => {
                if (error) {
                    setError(error);
                }
                setStatus('stopped');
            });

            return () => {
                _socket.removeAllListeners(`task:${_id}:start`)
                _socket.removeAllListeners(`task:${_id}:progress`)
                _socket.removeAllListeners(`task:${_id}:stop`)
            }
        }

    }, [socket, controller]);

    return { controller, init, start, reset, status, progress, error };
}

export default function ProgrammerTask({ }) {

    const { capId, taskId } = useParams();
    const taskDesc = useTask(capId, taskId);
    const { controller, init, start, reset, status, progress, error } = useCreateTaskController(capId, taskId);

    const actions = (
        <Stack direction='row'>
            {status === 'idle' &&
                <QRCodeButton
                    color='inherit'
                    value='init'
                    onClick={() => init().then(({ id }) => start(id)).catch(e => { })}>
                    Start
                </QRCodeButton>
            }
            {status === 'initialized' &&
                <QRCodeButton disabled={!(controller && controller.id)}
                    value='start'
                    color='inherit'
                    onClick={() => start(controller.id)}>
                    Start
                </QRCodeButton>
            }
            {status === 'stopped' &&
                <QRCodeButton disabled={!(controller && controller.id)}
                    color='inherit'
                    value='restart'
                    onClick={() => start(controller.id)}>
                    {error ? 'Restart' : 'Run one more time'}
                </QRCodeButton>
            }
        </Stack >
    )

    const session = controller ? controller.session : taskDesc.session;
    const steps = controller ? controller.steps : taskDesc.steps;

    return (
        <Card sx={{ flexGrow: 1 }}>
            <CardHeader title={(
                <Stack direction='row' justifyContent={'flex-start'} alignItems='center' spacing={1}>
                    <Stack direction='column' flexGrow={1}>
                        <Typography>{taskDesc.name}</Typography>
                        <Typography fontSize={14}>{taskDesc.description}</Typography>
                        <LinearProgress sx={{ mt: 1, visibility: status !== 'started' ? 'hidden' : 'inherit' }}
                            color={error ? 'error' : status === 'stopped' ? 'success' : status === 'started' ? 'secondary' : 'inherit'}
                            variant={status === 'started' ? (progress !== null ? 'determinate' : 'indeterminate') : 'determinate'}
                            value={status === 'started' ? progress : 100}
                        />
                    </Stack>
                    <Chip label={status.toUpperCase()} color={status === 'stopped' ? error ? 'error' : 'success' : undefined} />
                </Stack>
            )} />
            <CardContent>
                <Stack spacing={1}>
                    {error && <Typography variant='body2' color={'error'}>{JSON.stringify(error)}</Typography>}
                    <ProgrammerTaskSession session={session} />
                    <ProgrammerTaskSteps steps={steps} />
                </Stack>
            </CardContent>
            <CardActions disableSpacing>
                {actions}
            </CardActions>
        </Card >
    )
}

ProgrammerTask.propTypes = {
}
