import React, { } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@mui/material';
import ProgrammerConnectionNetwork from './connection-network';
import ProgrammerStatusChip from '../programmer-status-chip';
import { CONNECTED } from '../../../../model/registration/Programmer';

export default function ProgrammerOnlineWorkbench({ programmer }) {

    const connected = programmer.connection === CONNECTED;

    return (
        <Box component={Paper} variant='outlined' sx={{ m: 2, display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
            {!connected && <ProgrammerStatusChip programmer={programmer} />}
            {connected && <ProgrammerConnectionNetwork programmer={programmer} />}
        </Box>
    )
}

ProgrammerOnlineWorkbench.propTypes = {
    programmer: PropTypes.any
}
