import React from 'react';
import { Route, Routes, } from 'react-router-dom';
import { FactoryDevicesView, OnlineDevicesView } from './devices';
import FirmwaresView from './firmwares';
import HardwareVersionsView from './hardwareversion';
import NoMatchView from './no-match-view';
import ProgrammersViews from './programmers';
import UsersView from './users';
import AdminViews from './admin';

function AfterSalesViews() {
    return (
            <Routes>
                <Route path='/devices/*' element={<OnlineDevicesView />} />
                <Route path='/users/*' element={<UsersView />} />
                <Route path="*" element={<NoMatchView />} />
            </Routes>
    );
}

function FactoryViews() {
    return (
        <Routes>
            <Route path='/admin/*' element={<AdminViews />} />
            <Route path='/devices/*' element={<FactoryDevicesView />} />
            <Route path='/firmwares/*' element={<FirmwaresView />} />
            <Route path='/hardwareversions/*' element={<HardwareVersionsView />} />
            <Route path='/programmers/*' element={<ProgrammersViews />} />
            <Route path="*" element={<NoMatchView />} />
        </Routes>
    );
}

function Views() {
    return (
        <Routes>
            <Route path='/aftersales/*' element={<AfterSalesViews />} />
            <Route path='/factory/*' element={<FactoryViews />} />
            <Route path="*" element={<NoMatchView />} />
        </Routes>
    );
}

export default Views;