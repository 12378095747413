import React from 'react'
import LoginButtonWithRedirect from './login-button-with-redirect'
import LogoutButton from './logout-button'
import { useAuth0 } from "@auth0/auth0-react"

const AuthenticationButton = ({ audience }) => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <LogoutButton /> : <LoginButtonWithRedirect/>;
};

export default AuthenticationButton
