import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Chip, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useOnlineDeviceApi, useOnlineDeviceIds, useOnlineDeviceState } from '../live/online-device-provider';
import { topicBuilder, useMqttListener } from '@wyes/web-react-devices';
import PingButton from './ping-button';

export const useLastStateListener = (serialNumber, cb) => {
    const [lastUpdate, setLastUpdate] = React.useState(null);
    const listener = useCallback((data) => {
        cb(data);
        setLastUpdate(new Date());
    }, [cb]);
    const topic = useMemo(() => topicBuilder(`v1/dev/${serialNumber}/d/connection`), [serialNumber]);
    useMqttListener(topic, listener);
    return lastUpdate;
}

export default function LastStateCard({ ...props }) {

    const { serialNumber } = useOnlineDeviceIds();
    const { setState } = useOnlineDeviceApi();
    const { state, modelNumber, firmwareRevision, hardwareRevision } = useOnlineDeviceState();

    const lastUpdate = useLastStateListener(serialNumber, setState);

    return (
        <Card {...props}>
            <CardHeader title={`Status`} subheader={serialNumber} action={<PingButton />} />
            <CardContent>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell>Connection</TableCell>
                            <TableCell>{state === 'connected' ?
                                <Chip label='connected' color='success' /> :
                                <Chip label='disconnected' variant='outlined' />}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Model Number</TableCell>
                            <TableCell><Typography>{modelNumber}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Firmware Revision</TableCell>
                            <TableCell><Typography>{firmwareRevision}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Hardware Revision</TableCell>
                            <TableCell><Typography>{hardwareRevision}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Last Update</TableCell>
                            <TableCell><Typography>{lastUpdate?.toLocaleTimeString()}</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>

    )
}

LastStateCard.propTypes = {

}