import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { BASE_ADMIN_API_URL } from '../../../../../config';
import { useOnlineDeviceApi, useOnlineDeviceIds, useOnlineDeviceReactions } from '../../live/online-device-provider';
import ReactionConfiguration from './reaction-configuration';
import { useFetch } from '../../../../misc/fetch';

export const useReactionWriteAction = (sn) => {
    const fetch = useFetch();
    return useCallback(async (key, payload) => {
        try {
            const response = await fetch(
                `${BASE_ADMIN_API_URL}/actions/devices/${sn}/reactions/write-conf/${key}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                });

            if (!response.ok) {
                throw new Error(await response.text());
            }
        } catch (e) {
            console.error(e);
        }

    }, []);
}

export const useConfigureReactionModalController = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return [open, handleOpen, handleClose];
}

export default function ConfigureReactionModal({ open, onClose, reaction }) {

    const { serialNumber } = useOnlineDeviceIds();
    const handleWriteConf = useReactionWriteAction(serialNumber);
    const { setReactions } = useOnlineDeviceApi();
    const reactions = useOnlineDeviceReactions();

    const handleSubmit = async (values) => {
        await handleWriteConf(values.k, values);
        const reaction = _.find(reactions, ({ k }) => k === values.k);
        if (reaction) {
            _.merge({}, reaction, values);
        } else {
            reactions.push(_.merge({}, values));
        }
        setReactions(reactions);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
            <DialogTitle>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {`Configure reaction ${reaction.n} - ${reaction.k}`}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <ReactionConfiguration reaction={reaction} onSubmit={handleSubmit} />
            </DialogContent>
        </Dialog>
    )
}

ConfigureReactionModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    reaction: PropTypes.object,
}
