import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Stack, Button, Box } from '@mui/material';

export const useStepperController = () => {
  const [backDisabled, setBackButtonDisabled] = useState(false);
  const [nextDisabled, setNextButtonDisabled] = useState(false);
  return { backDisabled, nextDisabled, setBackButtonDisabled, setNextButtonDisabled };
}

export default function StepBackBone({ handleBack, handleNext, backDisabled = false, nextDisabled = false, children }) {

  return (
    <Stack sx={{ mt: 4 }} flexGrow={1} spacing={2}>
      <Box sx={{ flexGrow: 1 }}>
        {children}
      </Box>
      <Stack sx={{ mt: 2 }} spacing={2} direction='row' justifyContent='right'>
        <Button disabled={Boolean(backDisabled)} onClick={handleBack}>Back</Button>
        <Button disabled={Boolean(nextDisabled)} onClick={handleNext}>Next</Button>
      </Stack>
    </Stack>
  );
}

StepBackBone.propTypes = {
  handleBack: propTypes.func,
  handleNext: propTypes.func,
  backDisabled: propTypes.any,
  nextDisabled: propTypes.any,
  children: propTypes.any,
}