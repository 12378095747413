import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Formik, Form } from 'formik';
import { BASE_REGISTRATION_API_URL } from '../../../config';
import FileField from '../../misc/forms/file-field';
import * as Yup from 'yup';
import HardwareSelectField from '../hardwares/hardware-select-field';
import HardwareVersionField from './fields/version-field';
import { useRegistrationSynchronizeDB } from '../../../model/registration/RegistrationDatabase';
import { useFetch } from '../../misc/fetch';

export function useCreateHardwareVersion() {
    const fetch = useFetch();
    const sync = useRegistrationSynchronizeDB();
    const addCallback = useCallback(async ({ type, version, bundle }) => {
        const body = new FormData();
        if (bundle) {
            body.append('bundle', bundle);
        }

        const response = await fetch(
            `${BASE_REGISTRATION_API_URL}/hardwareversions/${type}/${version}`,
            {
                method: 'PUT',
                body
            });

        if (!response.ok) {
            throw new Error(await response.text())
        }

        await sync();
    }, []);
    return addCallback;
}

const HardwareversionCreationSchema = Yup.object().shape({
    version: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    bundle: Yup.mixed(input => input instanceof File),
});

export function AddHardwareVersionModal({ open, onClose, type = '', version = '0.0.0' }) {

    const handleCreate = useCreateHardwareVersion();

    return (
        <Dialog open={open}>
            <DialogTitle>Add an hardware version</DialogTitle>
            <Formik
                initialValues={{ version, type, bundle: null, }}
                validationSchema={HardwareversionCreationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    await handleCreate(values);
                    setSubmitting(false);
                    onClose();
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <HardwareVersionField />
                                </Grid>
                                <Grid item xs={12}>
                                    <HardwareSelectField disabled={Boolean(type)} name="type" label="Hardware Type" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileField name="bundle" label="Bundle" fullWidth />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isSubmitting} onClick={onClose}>Cancel</Button>
                            <Button disabled={isSubmitting} type='submit' color='secondary'>Upload</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>

        </Dialog>
    )
}

AddHardwareVersionModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    version: PropTypes.string,
}

export default function AddHardwareVersionButton({ type, version }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <IconButton onClick={handleClickOpen} aria-label="add hardware">
                <AddIcon />
            </IconButton>
            <AddHardwareVersionModal open={open} onClose={handleClose} type={type} version={version} />
        </Fragment>
    );
}

AddHardwareVersionButton.propTypes = {
    type: PropTypes.string,
    version: PropTypes.string,
}
