
import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack, Divider, Button, Backdrop, CircularProgress, FormControlLabel } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Switch, TextField } from 'formik-mui';

export const TYPE = "relay";

export const schema = Yup.object({
    k: Yup.string(),
    n: Yup.string(),
    w: Yup.number().min(100).max(10000).required(),
    s: Yup.boolean().required(),
    r: Yup.boolean().required(),
}).unknown(true);

function LoadingBackdrop({ }) {
    return (
        <Backdrop sx={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} open>
            <CircularProgress />
        </Backdrop>
    )
}

LoadingBackdrop.propTypes = {
}

export default function RelayReactionConfiguration({ onSubmit = async () => { }, reaction, ...props }) {

    return (
        <Formik
            validationSchema={schema}
            initialValues={{ s: false, r: true, ...reaction }}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    await onSubmit(values);
                } catch (e) {
                    console.error(e);
                }
                setSubmitting(false);
            }}
        >
            {({ values, isSubmitting, errors }) => {
                return (
                    <Form>
                        <Stack alignItems='center' spacing={2}>
                            <Stack sx={{ p: 2, width: '100%' }} spacing={2} direction='row' alignItems='center'>
                                <Field
                                    size='small'
                                    label={'Key'}
                                    component={TextField}
                                    name={'k'}
                                    type={'text'}
                                    disabled
                                    onClick={(ev) => ev.stopPropagation()}
                                />
                                <Field
                                    size='small'
                                    label={'Name'}
                                    fullWidth
                                    component={TextField}
                                    name={'n'}
                                    type={'text'}
                                    disabled
                                    onClick={(ev) => ev.stopPropagation()}
                                />
                            </Stack>
                            <Stack sx={{ p: 2, width: '100%' }} spacing={2} direction='row' alignItems='center'>
                                <Field
                                    size='small'
                                    label={'Pulse duration (ms)'}
                                    placeholder={'Pulse duration (ms)'}
                                    component={TextField}
                                    name={'w'}
                                    type={'number'}
                                    disabled={isSubmitting}
                                    onClick={(ev) => ev.stopPropagation()}
                                />
                                <FormControlLabel
                                    control={<Field
                                        size='small'
                                        component={Switch}
                                        name={'s'}
                                        type={'checkbox'}
                                        disabled={isSubmitting}
                                        onClick={(ev) => ev.stopPropagation()}
                                    />}
                                    label={'Steady state (NO/NF)'}
                                />
                                <FormControlLabel
                                    control={<Field
                                        size='small'
                                        component={Switch}
                                        name={'r'}
                                        type={'checkbox'}
                                        disabled={isSubmitting}
                                        onClick={(ev) => ev.stopPropagation()}
                                    />}
                                    label={'Reset at startup'}
                                />

                            </Stack>
                            <Divider />
                            <Button disabled={Object.keys(errors).length !== 0} type='submit'>Save</Button>
                        </Stack>
                        {isSubmitting ? <LoadingBackdrop /> : null}
                    </Form>
                )
            }}
        </Formik>
    );
}

RelayReactionConfiguration.propTypes = {
    onSubmit: propTypes.func,
    reaction: propTypes.object,
}