import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Chip, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables'
import { Q } from '@nozbe/watermelondb'
import _ from 'lodash'
import FuotaButton from './fuota-button';
import RevokeButton from './revoke-button';
import { withRegistrationDatabase } from '../../../model/registration/RegistrationDatabase';

export function FirmwareListItem({ firmware, device }) {

    return (
        <ListItem>
            <ListItemText primary={`${firmware.version}`} secondary={`${firmware.type} - ${firmware.id}`} />
            <ListItemSecondaryAction>
                {device && (device.firmwareId !== firmware.id) && (
                    <FuotaButton firmware={firmware} device={device} />
                )}
                {!device && !firmware.revoke && (
                    <RevokeButton firmware={firmware} />
                )}
            </ListItemSecondaryAction>
            {firmware.revoke ? <ListItemIcon>
                <Chip label='REVOKED' />
            </ListItemIcon> : null}
        </ListItem>
    )
}

FirmwareListItem.propTypes = {
    firmware: PropTypes.any,
    device: PropTypes.any
}

const observeFirmware = withObservables(['firmware'], ({ firmware }) => ({
    firmware,
}));

export const ObservedFirmwareListItem = observeFirmware(FirmwareListItem);

export function FirmwaresListInternal({ firmwares, ...props }) {

    if (firmwares.length === 0) {
        return <Typography align='center'>No Firmwares</Typography>
    }

    return (
        <List>
            {_.map(firmwares, (firmware) => <ObservedFirmwareListItem key={firmware.id} firmware={firmware} {...props} />)}
        </List>
    );
}

FirmwaresListInternal.propTypes = {
    firmwares: PropTypes.array
}

const observeFirmwares = withObservables([], ({ database, hardware = undefined, filterRevoked = false }) => {

    const queries = [];
    if (hardware) {
        queries.push(Q.where('type', hardware));
    } 

    if(filterRevoked) {
        queries.push(Q.where('revoke', false));
    }

    if(queries.length > 0) {
        return {
            firmwares: database.get('firmwares').query(...queries)
        } 
    } else {
        return {
            firmwares: database.get('firmwares').query()
        } 
    }
});

export const ObservedFirmwaresList = withRegistrationDatabase(observeFirmwares(FirmwaresListInternal))

export default function FirmwaresList({ title = "Firmwares", action = null, ...props }) {
    return (
        <Card>
            <CardHeader
                action={action}
                title={title}
            />
            <CardContent>
                <ObservedFirmwaresList {...props} />
            </CardContent>
        </Card>
    );
};

FirmwaresList.propTypes = {
    title: PropTypes.string,
    action: PropTypes.node
}
