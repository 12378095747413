import React, { useEffect, useState } from 'react';
import { Box, Badge, Tooltip } from '@mui/material';

import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import { MQTT_STATUS_CONNECTED, MQTT_STATUS_CONNECTING, MQTT_STATUS_DISCONNECTED, useMqtt } from './mqtt-client';

export const MQTT_STATUS_BADGE_COLORS = {
  [MQTT_STATUS_DISCONNECTED]: 'error',
  [MQTT_STATUS_CONNECTING]: 'warning',
  [MQTT_STATUS_CONNECTED]: 'success',
}

export const MQTT_STATUS_BADGE_ICONS = {
  [MQTT_STATUS_DISCONNECTED]: CloudOffIcon,
  [MQTT_STATUS_CONNECTING]: CloudQueueIcon,
  [MQTT_STATUS_CONNECTED]: CloudIcon,
}

export default function MqttStatusIcon({ children }) {
  const { status } = useMqtt();
  const color = MQTT_STATUS_BADGE_COLORS[status];
  const [Icon, setIcon] = useState(MQTT_STATUS_BADGE_ICONS[status]);

  useEffect(() => {
    let interval = null;
    if (status === MQTT_STATUS_CONNECTING) {
      interval = setInterval(() => {
        setIcon(icon => icon === CloudQueueIcon ? CloudOffIcon : CloudQueueIcon);
      }, 700);
    } else {
      setIcon(MQTT_STATUS_BADGE_ICONS[status]);
    }

    return () => {
      if (interval) clearInterval(interval);
    }
  }, [status]);

  return (
    <Box sx={{ color: 'action.active' }}>
      <Tooltip title={status}>
        <Badge color={color} variant="dot">
          {children}
        </Badge>
      </Tooltip>
    </Box>
  );
}
