
import React, { useEffect } from 'react'
import { Stack, CircularProgress, Typography } from "@mui/material"
import { useAuth0 } from '@auth0/auth0-react'
import { useLoginRoutine } from './login-button-with-redirect'

function AuthRequiredInternal() {
  const loginRoutine = useLoginRoutine();

  useEffect(() => {
    loginRoutine();
  }, []);

  return (
    <Stack sx={{
      height: '100%',
    }}
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={4}
      >
        <CircularProgress />
        <Typography>Authentication is required, authentication process starting...</Typography>
      </Stack>
    </Stack >
  )
}


export default function AuthRequired({ children }) {

  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return null;
  } else if (isAuthenticated) {
    return children;
  } else {
    return <AuthRequiredInternal />
  }
}
