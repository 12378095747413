import { appSchema } from '@nozbe/watermelondb'
import { deviceSchema } from '../Device';
import { firmwareSchema } from '../Firmware';
import { hardwareVersionSchema } from '../HardwareVersion';
import { programmerSchema } from './Programmer';

export default appSchema({
  version: 1,
  tables: [
    deviceSchema,
    hardwareVersionSchema,
    firmwareSchema,
    programmerSchema,
  ]
});
