import { Model } from '@nozbe/watermelondb'
import { tableSchema } from '@nozbe/watermelondb'
import { field, date, json, readonly, relation } from '@nozbe/watermelondb/decorators'

export const DECLARED = 'DECLARED';
export const PRODUCED = 'PRODUCED';
export const SHIPPED = 'SHIPPED';
export const MAINTENANCE = 'MAINTENANCE';
export const WITHDRAWN = 'WITHDRAWN';
export const DESTROYED = 'DESTROYED';

export const FACTORY_STATUS = [
  DECLARED,
  PRODUCED,
  SHIPPED,
  MAINTENANCE,
  WITHDRAWN,
  DESTROYED
]

export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';

export const CONNECTIVITY_STATUS = [
  CONNECTED,
  DISCONNECTED
]

export const deviceSchema = tableSchema({
  name: 'devices',
  columns: [
    { name: 'hardware', type: 'string' },
    { name: 'owner_id', type: 'string', isOptional: true },
    { name: 'hardware_version_id', type: 'string', isOptional: true },
    { name: 'firmware_id', type: 'string', isOptional: true },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
    { name: 'factory_produced_at', type: 'number' },
    { name: 'factory_log', type: 'string' },
    { name: 'factory_status', type: 'string' },
    { name: 'status', type: 'string' },
    { name: 'last_connection', type: 'number' },
    { name: 'ext_conf', type: 'string' },
  ]
});

const sanitizer = input => {
  return input;
}

const sanitizerExtConf = input => {
  return input || {};
}

export default class Device extends Model {
  static table = 'devices'
  static associations = {
  }

  @readonly @field('hardware') hardware
  @readonly @field('owner_id') ownerId
  @relation('users', 'owner_id') owner // must not be set, but can be null.
  @readonly @field('hardware_version_id') hardwareVersionId
  @relation('hardware_versions', 'hardware_version_id') hardwareVersion  // must not be set, but can be null.
  @readonly @field('firmware_id') firmwareId
  @relation('firmwares', 'firmware_id') firmware  // must not be set, but can be null.
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
  @readonly @date('factory_produced_at') factoryProducedAt
  @readonly @json('factory_log', sanitizer) factoryLog
  @readonly @field('factory_status') factoryStatus
  @readonly @field('status') status
  @readonly @date('last_connection') lastConnection
  @readonly @json('ext_conf', sanitizerExtConf) extConf
};


