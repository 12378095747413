import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import io from 'socket.io-client';
import { Button, CircularProgress, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const NetworkContext = createContext({
    socket: null,
    host: null,
    lastPong: null,
});

export const useSocket = () => useContext(NetworkContext).socket;

export function WaitProgrammerIsConnected({ resetNetwork, loading }) {

    return (
        <Stack spacing={2} justifyContent='center' alignItems='center'>
            <Button onClick={() => resetNetwork()}>Back</Button>
            {loading && <CircularProgress />}
        </Stack>
    )
}

WaitProgrammerIsConnected.propTypes = {
    resetNetwork: PropTypes.func,
    loading: PropTypes.bool,
}

export default function ProgrammerConnectionProvider({ network, resetNetwork, children, }) {

    const { enqueueSnackbar } = useSnackbar();

    const [socket, setSocket] = useState(null);
    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lastPong, setLastPong] = useState(null);
    const host = useMemo(() => network, []);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const s = io(host, { path: '/' });

        s.on('connect_error', (err) => {
            enqueueSnackbar('Connection failed: ' + err.message, { variant: 'error' });
            setConnected(false);
            setLoading(false);
            resetNetwork();
            console.error({ err });
        })

        s.on('connect', () => {
            setConnected(true);
            setLoading(false);
        });

        s.on('disconnect', (reason) => {
            resetNetwork();
            setConnected(false);
            setLoading(false);
            navigate('./');
            if (reason != 'io client disconnect') {
                enqueueSnackbar('Programmer disconnected. ' + reason, { variant: 'warning' });
            }
        });

        s.on('pong', () => {
            setLastPong(new Date());
        });

        setSocket(s);

        return () => {
            s.removeAllListeners('connect');
            s.removeAllListeners('disconnect');
            s.removeAllListeners('pong');
            s.disconnect();
            setSocket(null);
        }
    }, []);

    return (
        <NetworkContext.Provider value={{ socket, host, lastPong }}>
            {connected ?
                children :
                <WaitProgrammerIsConnected
                    loading={loading}
                    resetNetwork={() => {
                        if (socket) socket.disconnect();
                        resetNetwork();
                    }}
                />}
        </NetworkContext.Provider>
    )
}

ProgrammerConnectionProvider.propTypes = {
    network: PropTypes.string,
    resetNetwork: PropTypes.func,
    children: PropTypes.any,
}
