import React, { } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import SendIcon from '@mui/icons-material/Send';

export default function ProgrammerNetworkList({ programmer, onClick }) {

    const networks = programmer.networks;

    const handleClick = (net) => (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        onClick(net);
    }

    return (
        <Stack>
            <List
                subheader={
                    <ListSubheader>
                        LAN
                    </ListSubheader>
                }
            >
                {_.map(networks.LAN, network => (
                    <ListItem key={network}>
                        <ListItemButton onClick={handleClick(network)}>
                            <ListItemIcon>
                                <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary={network} />
                        </ListItemButton>
                        <ListItemSecondaryAction>

                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <List
                subheader={
                    <ListSubheader>
                        WAN
                    </ListSubheader>
                }
            >
                {_.map(networks.WAN, network => (
                    <ListItem key={network}>
                        <ListItemButton onClick={handleClick(network)}>
                            <ListItemIcon>
                                <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary={network} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    )
}

ProgrammerNetworkList.propTypes = {
    programmer: PropTypes.any,
    onClick: PropTypes.func,
}
