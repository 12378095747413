import React, { useEffect, useMemo, useRef } from 'react';
import {
  Chart,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartStreaming from 'chartjs-plugin-streaming';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import ZoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-luxon';
import { deepPurple, indigo, teal, green, lime, amber, deepOrange } from '@mui/material/colors';

Chart.register(
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
  Filler,
  ChartStreaming,
  ZoomPlugin,
  AnnotationPlugin,
);

export const CHART_LINES_COLORS = [deepPurple, indigo, teal, green, lime, amber, deepOrange];
export const CHART_LINES_COLORS_VARIANTS = ['A100', 'A200', 'A400', 'A700'];

export function generateChartLineColor(index) {
  const variant = Math.floor(index / CHART_LINES_COLORS.length) % CHART_LINES_COLORS_VARIANTS.length;
  const color = index % CHART_LINES_COLORS.length;

  const choice = CHART_LINES_COLORS[color];

  return {
    line: choice[CHART_LINES_COLORS_VARIANTS[variant]] + 'ff',
    background: choice[CHART_LINES_COLORS_VARIANTS[variant]] + '10',
  };
}

export const useStreamChartDefaultOptions = ({ yMin = -1000, yMax = 1000, pause }) => {
  const start = useMemo(() => Date.now(), []);
  return useMemo(() => ({
    animation: false,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 0,
    elements: { point: { radius: 0 }, line: { tension: 0 } },
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    plugins: {
      streaming: {
        ttl: 60000,
        duration: 10000,
        delay: 500,
        frameRate: 24,
        pause,
      },
      zoom: {
        pan: { overScaleMode: 'xy', enabled: true },
        zoom: { wheel: { enabled: true }, overScaleMode: 'xy' },
        limits: {
          x: { minDuration: 1000, maxDuration: 10000 },
          y: { min: -10000, max: 10000, minRange: 100 }
        }
      },
    },
    scales: {
      x: {
        type: 'realtime', ticks: {
          callback: function (value, index, ticks) {
            return `${((ticks[index].value - start) / 1000).toFixed(2)} s`;
          }
        }
      },
      y: { min: yMin, max: yMax },
    },
  }), [yMin, yMax, pause, start]);
}

export const useStreamChartAnnotationsOptions = (settings, annotations) => {
  const defaultOptions = useStreamChartDefaultOptions(settings);
  return useMemo(() => _.merge({}, defaultOptions, {
    plugins: {
      annotation: {
        annotations
      }
    },
  }), [defaultOptions, annotations])
}

export const useDataFromDatasets = (...datasets) => {
  return useMemo(() => {
    return {
      datasets: _.concat(...datasets).map(({ __name, __key, __uuid, __compute, __data, noBackground = false }, i) => {
        const color = generateChartLineColor(i);
        return {
          label: __name,
          backgroundColor: color.background,
          borderColor: color.line,
          fill: !noBackground,
          data: [],

          __name,
          __key,
          __uuid,
          __compute,
          __data,
        }
      })
    }
  }, datasets);
}

export default function StreamChart({ height, data, options, emitter = undefined }) {

  const chartRef = useRef(null);

  // recompute each datasets when the data reference has changed.
  useEffect(() => {
    const chart = chartRef.current;

    if (data.datasets[0]) { // dataset[0] is the reference. All the datasets are aligned based on this object.

      if (data.datasets[0].__data) {
        data.datasets[0].__data.forEach(({ x, y }, i) => {

          const scope = {};

          data.datasets.forEach(({ __key, data: history, __data, __compute }) => {
            const value = _.get(__data, `[${i}].y`, null);
            scope[__key] = __compute(scope, value, history);
            const point = { x, y: scope[__key] };
            history.push(point);
          });

        });
      }


      if (emitter) {
        const _emitter = emitter;
        const listener = (newData) => {
          if (newData[0]) {
            newData[0].data.forEach(({ x }, i) => {

              const scope = {};

              data.datasets.forEach(({ __key, __uuid, data: history, __compute }) => {
                const dataset = _.find(newData, { key: __uuid });
                const __data = dataset ? dataset.data : undefined;
                const value = _.get(__data, `[${i}].y`, null);
                scope[__key] = __compute(scope, value, history);
                const point = { x, y: scope[__key] };
                history.push(point);
              });

            });

            chart.update('none');
          }
        };

        emitter.on('data', listener);

        return () => {
          _emitter.removeListener('data', listener);
        }
      }

    }

  }, [data]);

  return (
    <Line ref={chartRef} height={height} data={data} options={options} />
  )
}
