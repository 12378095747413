import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Backdrop } from '@mui/material';

import { useDeviceStream, useSubscribeDeviceStream } from '../mqtt/device-stream-hook';
import StreamChart, { generateChartLineColor } from './stream-chart';

export function useStartStopChartController(onStart, onStop, defaultStarted = false) {
  const [pause, setPause] = useState(!defaultStarted);
  const [started, setStarted] = useState(defaultStarted);
  const start = useCallback(() => {
    setPause(started);
    setStarted(!started);
  }, [started]);

  useEffect(() => {
    if (started) {
      onStart();
    } else {
      onStop();
    }
  }, [started, onStart, onStop]);

  return [pause, started, start, setPause];
}

export default function DeviceStreamChart({ device, onStart = () => { }, onStop = () => { }, noBackground = false }) {

  const [pause, started, start, setPause] = useStartStopChartController(onStart, onStop);

  const data = useMemo(() => ({
    datasets: [0, 1, 2, 3, 4, 5].map(idx => {
      const color = generateChartLineColor(idx);
      return {
        label: idx.toString(),
        backgroundColor: color.background,
        borderColor: color.line,
        fill: !noBackground,
        data: [],
      }
    }),

  }), []);

  useSubscribeDeviceStream(device.id);
  useDeviceStream(device.id, (record) => {
    const now = Date.now();
    record.forEach((value, idx) => {
      data.datasets[idx].data.push({ x: now, y: value });
    });
  });

  return (
    <Fragment>
      <Grid sx={{ position: 'relative' }} container spacing={1}>
        <Grid item xs={12}>
          <Button color={started ? 'warning' : 'success'} onClick={() => start()}>{started ? 'Stop' : 'Start'}</Button>
          <Button onClick={() => setPause(pause => !pause)}>{pause ? 'Resume' : 'Pause'}</Button>
        </Grid>
        <Grid item xs={12}>
          <StreamChart data={data} pause={pause} />
        </Grid>
        <Backdrop onClick={() => start()} open={!started} sx={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
          <Button color='success'>Start</Button>
        </Backdrop>
      </Grid>
    </Fragment>
  )

}
