import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { MenuItem } from '@mui/material';
import { WyesGlasses0SelectItem } from './contactors/wyes-glasses-0';
import { WyesContactors0SelectItem } from './extensions/wyes-contactors-0';
import { WyesControllers0SelectItem } from './controllers/wyes-controllers-0';
import { HARDWARES } from '../../../model/Hardware';
import { WyesBLE0SelectItem } from './extensions/wyes-ble-0';

const HardwareTypeToSelectItem = {
    [HARDWARES[0]]: WyesGlasses0SelectItem,
    [HARDWARES[1]]: WyesContactors0SelectItem,
    [HARDWARES[2]]: WyesControllers0SelectItem,
    [HARDWARES[3]]: WyesBLE0SelectItem,
}

function HardwareVersionSelectField({ name, ...props }) {

    return (
        <Field {...props} component={TextField} select placeholder='Hardware Type' name={name} >
            {HARDWARES.map(hardware => {

                const HardwareComponent = HardwareTypeToSelectItem[hardware];

                return (
                    <MenuItem key={hardware} value={hardware}>
                        {HardwareComponent && <HardwareComponent />}
                    </MenuItem>
                )
            })}
        </Field>
    )
}

HardwareVersionSelectField.propTypes = {
    name: PropTypes.string,
}

export default HardwareVersionSelectField;
