import React, { } from 'react';
import PropTypes from 'prop-types';
import { Card, CardActionArea, CardHeader } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VibrationIcon from '@mui/icons-material/Vibration';
import HelpIcon from '@mui/icons-material/Help';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ReactionConfigButton from './reaction-config-button';
import BluetoothIcon from '@mui/icons-material/Bluetooth';

const Avatars = {
    ["rgbled"]: LightbulbIcon,
    ["led"]: LightbulbIcon,
    ["buzzer"]: VolumeUpIcon,
    ["haptic"]: VibrationIcon,
    ["relay"]: ToggleOnIcon,
    ["ble"]: BluetoothIcon,
}

function ReactionCardWrapper({ children, onClick }) {
    if (!onClick) return children;
    return (
        <CardActionArea onClick={onClick}>
            {children}
        </CardActionArea>
    )
}

ReactionCardWrapper.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
}

export default function ReactionCard({ reaction, onClick, noAction = false, ...props }) {

    const Avatar = Avatars[reaction.t] || HelpIcon;

    return (
        <Card>
            <ReactionCardWrapper>
                <CardHeader
                    title={reaction.n}
                    subheader={reaction.k}
                    avatar={<Avatar />}
                    action={!noAction && <ReactionConfigButton reaction={reaction} />}
                />
            </ReactionCardWrapper>
        </Card>
    )
}

ReactionCard.propTypes = {
    reaction: PropTypes.object,
    onClick: PropTypes.func,
    noAction: PropTypes.bool,
}