import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import withObservables from '@nozbe/with-observables'
import _ from 'lodash'
import AddUserButton from './add-button';
import { withAfterSalesDatabase } from '../../../model/aftersales/AfterSalesDatabase';

export const useNavigateToUser = () => {
    const navigate = useNavigate();
    const handleClick = useCallback((id) => {
        navigate(`${id}/`);
    }, []);
    return handleClick;
}

export function UserListItem({ user }) {

    const handleClick = useNavigateToUser();

    return (
        <ListItem onClick={() => handleClick(user.id)}>
            <ListItemButton>
                <ListItemText primary={user.name} secondary={user.mail} />
            </ListItemButton>
        </ListItem>
    )
}

UserListItem.propTypes = {
    user: PropTypes.any
}

const observeUser = withObservables(['user'], ({ user }) => ({
    user,
}));
export const ObservededUserListItem = observeUser(UserListItem);

export function UsersListInternal({ users }) {

    return (
        <List dense>
            {_.map(users, (user) => <ObservededUserListItem key={user.id} user={user} />)}
        </List>
    );
}

UsersListInternal.propTypes = {
    users: PropTypes.array
}

const observeUsers = withObservables([], ({ database }) => ({
    users: database.get('users').query(),
}));

export const ObservedUsersList = withAfterSalesDatabase(observeUsers(UsersListInternal));

export default function UsersList() {
    return (
        <Card>
            <CardHeader
                action={<AddUserButton />}
                title="Users"
            />
            <CardContent>
                <ObservedUsersList />
            </CardContent>
        </Card>
    );
}
