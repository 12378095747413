import React from 'react';
import propTypes from 'prop-types';
import ManualMovementPhases, { defaultValue as MMPdefault, schema as MMPschema } from './ManualMovementPhases';
import * as Yup from 'yup';

export const schema = Yup.object({
  p: MMPschema,
});

export const TYPE = 'mm';
export const defaultValue = {
  p: MMPdefault,
}

export default function ManualMovement({ namespace, ...props }) {
  return (
    <ManualMovementPhases {...props} namespace={namespace + '.p'} />
  );
}

ManualMovement.propTypes = {
  namespace: propTypes.string,
}