import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack, TextField } from '@mui/material';
import SelectDeviceFactoryStatus from './device-factory-status';
import dayjs from 'dayjs';
import { compose } from 'recompose';
import withObservables from '@nozbe/with-observables'
import { Q } from '@nozbe/watermelondb'
import SelectDeviceHardware from './device-hardware-select';
import _ from 'lodash';
import { withRegistrationDatabase } from '../../../../model/registration/RegistrationDatabase';

export const useQueryBuilder = () => {
    const [query, setQuery] = useState({ from: dayjs(0), to: dayjs(), status: 'null', hardware: 'null' });
    return [query, setQuery];
}

export const observeDevicesListFromQuery = compose(
    withRegistrationDatabase,
    withObservables(['query'], ({ database, query = undefined }) => {
        if (!query) {
            return {
                devices: database.get('devices').query(),
            };
        }

        const filter = _.compact([
            query.status !== 'null' ? Q.where('factory_status', query.status) : undefined,
            Q.where('created_at', Q.between(
                query.from.unix() * 1000,
                query.to.unix() * 1000)
            ),
            query.hardware !== 'null' ? Q.where('hardware', query.hardware) : undefined,
        ]);

        const q = [
            Q.and(...filter),
            Q.sortBy('created_at', Q.asc),
        ];
        return {
            devices: database.get('devices').query(...q),
        }
    })
);



export default function DeviceListQueryBuilder({ query = {}, setQuery }) {
    return (
        <Stack spacing={1} direction='row' alignItems='center'>
            <SelectDeviceFactoryStatus status={query.status} setStatus={(status) => { setQuery({ ...query, status }) }} />
            <SelectDeviceHardware hardware={query.hardware} setHardware={(hardware) => { setQuery({ ...query, hardware }) }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label="From"
                    value={query.from || null}
                    onChange={(newValue) => {
                        setQuery({ ...query, from: newValue });
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
                <DateTimePicker
                    label="To"
                    value={query.to || null}
                    onChange={(newValue) => {
                        setQuery({ ...query, to: newValue });
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
            </LocalizationProvider>
        </Stack>
    );
};

DeviceListQueryBuilder.propTypes = {
    query: PropTypes.any,
    setQuery: PropTypes.func,
}
