import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogTitle, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { BASE_REGISTRATION_API_URL } from '../../../config';
import { useRegistrationDatabase } from '../../../model/registration/RegistrationDatabase';
import { useFetch } from '../../misc/fetch';

export function useDeleteProgrammer(programmer, onClose) {
    const [loading, setLoading] = useState(false);
    const database = useRegistrationDatabase();
    const fetch = useFetch();

    const deleteCallback = useCallback(async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setLoading(true);
        try {
            const response = await fetch(
                `${BASE_REGISTRATION_API_URL}/programmers/${programmer.id}`,
                {
                    method: 'DELETE',
                });

            if (!response.ok) {
                throw new Error(await response.text())
            }

            await database.write(async () => {
                await programmer.destroyPermanently();
            });

            onClose();
        } finally {
            setLoading(false);
        }
    }, [programmer, onClose]);
    return [deleteCallback, loading];
}


export function DeleteProgrammerModal({ open, onClose, programmer }) {

    const [handleDelete, loading] = useDeleteProgrammer(programmer, onClose);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete the programmer {programmer.name} ?</DialogTitle>
            <DialogActions>
                <Button disabled={loading} onClick={onClose} color='primary' >Cancel</Button>
                <Button disabled={loading} onClick={handleDelete} color='inherit' >Delete</Button>
            </DialogActions>
        </Dialog>
    )
}

DeleteProgrammerModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    programmer: PropTypes.any,
}

export default function DeleteProgrammerButton({ programmer }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    return (
        <Fragment>
            <IconButton onClick={handleClickOpen} aria-label="add hardware">
                <DeleteIcon />
            </IconButton>
            <DeleteProgrammerModal open={open} onClose={handleClose} programmer={programmer} />
        </Fragment>
    );
}

DeleteProgrammerButton.propTypes = {
    programmer: PropTypes.any,
}
