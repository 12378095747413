import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Stack } from '@mui/material';
import ApiTokenGenerateButton from './generate-api-token-button';
import { useIsFactoryAdmin } from '@wyes/react-auth0';
import ServicesStatus from './services-status';

function AdminDashboard() {

    return (
        <Stack spacing={8}>
            <ServicesStatus />
            <ApiTokenGenerateButton />
        </Stack>
    )
}

export default function AdminViews() {
    const isFactoryAdmin = useIsFactoryAdmin();
    if(!isFactoryAdmin) return null;

    return (
        <Routes>
            <Route path='/' exact element={<AdminDashboard />} />
        </Routes>
    );
}
