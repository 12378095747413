import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import StepBackBone, { useStepperController } from './step-backbone';
import SensorInputs from '../../sensor-inputs/SensorInputs';
import { useField } from 'formik';
import { Stack } from '@mui/material';
import { SensorsChart } from '@wyes/web-react-devices';
import { useSensorStreamController } from '../../../../sensors/sensors-stream';

const sensorAlgorithm = (parameters) => {
  let lastValue = null;
  let avg_value = 0;

  let capacity = 0;
  let average = 0;
  let integration = 0;
  const delay = [];
  const MAX_VALUE = 100000;

  return (scope, value, history) => {
    if (lastValue === null) {
      lastValue = value;
      return 0;
    }

    let val = lastValue - value;
    lastValue = value;

    val = val * parameters.s;
    if (val < -MAX_VALUE) {
      val = -MAX_VALUE;
    }
    if (val > MAX_VALUE) {
      val = MAX_VALUE;
    }

    avg_value = (1 - (parameters.f / 100)) * val + (parameters.f / 100) * avg_value;

    capacity++;
    if (capacity > parameters.t) {
      capacity--;
      average = ((average * capacity - average) + avg_value) / capacity;
      integration = integration - integration / parameters.t + average;
    } else {
      average += avg_value / capacity;
      integration += average;
    }

    delay.push(integration);
    if (delay.length > parameters.d) {
      return delay.shift();
    }
    return 0;
  }
}

export const useSensorsDatasetFromField = (namespace, dataset) => {
  const [{ value }, meta] = useField(namespace);
  const [data, setData] = useState([]);

  useEffect(() => {
    const data = [];
    _.each(value, (v) => {
      const index = _.findIndex(dataset, { key: v.n });
      const set = dataset[index];
      if (set) {
        data.push({
          noBackground: true,
          __name: set.name,
          __key: 's' + data.length,
          __uuid: set.key,
          __data: set.data,
          __compute: sensorAlgorithm(v),
        });
      }
    });
    setData(data);
  }, [value, dataset, setData]);

  return { data, value };
}

export default function ChooseInputs({ namespace, ...props }) {

  const { nextDisabled, setNextButtonDisabled } = useStepperController();
  const [field, meta] = useField(namespace + '.i');
  const { start, stop, activated, dataset, emitter } = useSensorStreamController();

  const { data } = useSensorsDatasetFromField(namespace + '.i', dataset);

  useEffect(() => {
    setNextButtonDisabled(meta.error);
  }, [meta.error, setNextButtonDisabled]);

  return (
    <StepBackBone nextDisabled={nextDisabled} backDisabled {...props}>
      <Stack spacing={2}>
        <SensorsChart defaultStarted={activated} onStart={start} onStop={stop} datasets={data} emitter={emitter} height='500px' />
        <SensorInputs namespace={namespace + '.i'} />
      </Stack>
    </StepBackBone>
  );
}

ChooseInputs.propTypes = {
  namespace: propTypes.string,
}
