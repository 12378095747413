
import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack, Divider, Button, Backdrop, CircularProgress } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import KeyboardReportField, { INITIAL_VALUE } from './keyboard/keyboard-report-field';
import KeyboardLayoutField, { DEFAULT_VALUE } from './keyboard/keyboard-layout-field';
import TargetBLEDeviceField, { BROADCAST } from './target-ble-device-field';

export const TYPE = "ble";

export const schema = Yup.object({
    k: Yup.string(),
    n: Yup.string(),
    kr: Yup.string().required(),
    kl: Yup.number().required(),
    td: Yup.string().length(12).nullable(),
}).unknown(true);

function LoadingBackdrop({ }) {
    return (
        <Backdrop sx={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} open>
            <CircularProgress />
        </Backdrop>
    )
}

LoadingBackdrop.propTypes = {
}

export default function BLEReactionConfiguration({ onSubmit = async () => { }, reaction, ...props }) {

    return (
        <Formik
            validationSchema={schema}
            initialValues={{ kr: INITIAL_VALUE, kl: DEFAULT_VALUE, td: BROADCAST.value, ...reaction }}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    values.kr = values.kr.padEnd(8 * 2, '0');
                    await onSubmit(values);
                } catch (e) {
                    console.error(e);
                }
                setSubmitting(false);
            }}
        >
            {({ values, isSubmitting, errors }) => {
                return (
                    <Form>
                        <Stack alignItems='center' spacing={2}>
                            <Stack sx={{ p: 2, width: '100%' }} spacing={2} direction='row' alignItems='center'>
                                <Field
                                    size='small'
                                    label={'Key'}
                                    component={TextField}
                                    name={'k'}
                                    type={'text'}
                                    disabled
                                />
                                <Field
                                    size='small'
                                    label={'Name'}
                                    fullWidth
                                    component={TextField}
                                    name={'n'}
                                    type={'text'}
                                    disabled
                                />
                            </Stack>
                            <Stack sx={{ p: 2, width: '100%' }} spacing={2} direction='column' alignItems='center'>
                                <TargetBLEDeviceField name='td' />
                                <KeyboardLayoutField name='kl' />
                                <KeyboardReportField name='kr' layoutName='kl' />
                            </Stack>
                            <Divider />
                            <Button disabled={Object.keys(errors).length !== 0} type='submit'>Save</Button>
                        </Stack>
                        {isSubmitting ? <LoadingBackdrop /> : null}
                    </Form>
                )
            }}
        </Formik>
    );
}

BLEReactionConfiguration.propTypes = {
    onSubmit: propTypes.func,
    reaction: propTypes.object,
}