import { Model, tableSchema } from '@nozbe/watermelondb'
import { field, date, json, readonly } from '@nozbe/watermelondb/decorators'

export const CREATED = 'CREATED';
export const REGISTERED = 'REGISTERED';

export const PROGRAMMER_STATUS = [
  CREATED,
  REGISTERED
]

export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';

export const PROGRAMMER_CONNECTIVITY_STATUS = [
  CONNECTED,
  DISCONNECTED
]

export const programmerSchema = tableSchema({
  name: 'programmers',
  columns: [
    { name: 'name', type: 'string' },
    { name: 'description', type: 'string', isOptional: true },
    { name: 'status', type: 'string' },
    { name: 'connection', type: 'string' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
    { name: 'configuration', type: 'string' },
    { name: 'capabilities', type: 'string' },
    { name: 'networks', type: 'string' },
  ]
});

const sanitizer = input => {
  return input;
}

export default class Programmer extends Model {
  static table = 'programmers'
  static associations = {
  }

  @readonly @field('name') name
  @readonly @field('description') description
  @readonly @json('configuration', sanitizer) configuration
  @readonly @json('capabilities', sanitizer) capabilities
  @readonly @json('networks', sanitizer) networks
  @readonly @field('status') status
  @readonly @field('connection') connection
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
};
