import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Stack, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FieldArray, useField } from 'formik';
import { ManualMovementPhase, defaultValue as MMPdefault, schema as MMPschema } from './ManualMovementPhase';
import * as Yup from 'yup';

export const schema = Yup.array().of(MMPschema).min(1, 'At least one is required.');

export const defaultValue = [];

export default function ManualMovementPhases({ namespace, ...props }) {

  const [field] = useField(namespace);
  const phases = field.value;

  return (
    <Stack spacing={2} justifyContent='center' alignItems='center'>
      <Typography>Movement Phases</Typography>
      <FieldArray
        name={namespace}
        render={({ push, remove }) => (
          <Stack flexGrow={1} spacing={2} direction='row' alignItems='center'>
            {_.map(phases, (phase, i) => (
              <ManualMovementPhase {...props} key={i} name={i} namespace={namespace + `.${i}`} onRemove={() => remove(i)} onCopy={push} />
            ))}
            <Stack flexGrow={1} direction='row' alignItems='center' justifyContent='center'>
              <IconButton onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                push(MMPdefault)
              }}>
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
        )}
      />
    </Stack >
  );
}

ManualMovementPhases.propTypes = {
  namespace: propTypes.string,
}