import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { Box, Stack, Typography } from '@mui/material';

export default function FactoryDeviceQRcode({ device }) {
    return (
        <Stack direction='column' justifyContent='center' alignItems='center'>
            <Typography>{`Label's QRCode`}</Typography>
            <Box sx={{ p: 0.5 }} bgcolor={'white'}>
                <QRCodeSVG display={'block'}
                    value={JSON.stringify({ sn: device.id, type: device.hardware })} />
            </Box>
        </Stack>
    );
}

FactoryDeviceQRcode.propTypes = {
    device: PropTypes.any,
}