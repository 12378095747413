import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Movement from './configuration/Movement';
import { BASE_ADMIN_API_URL } from '../../../../config';
import { useOnlineDeviceApi, useOnlineDeviceMovements } from '../live/online-device-provider';
import { useFetch } from '../../../misc/fetch';

export const useMovementWriteAction = (sn) => {
    const fetch = useFetch();
    return useCallback(async (key, payload) => {
        try {
            const response = await fetch(
                `${BASE_ADMIN_API_URL}/actions/devices/${sn}/movements/write-conf/${key}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                });

            if (!response.ok) {
                throw new Error(await response.text());
            }
        } catch (e) {
            console.error(e);
        }

    }, []);
}

export const useConfigureMovementModalController = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return [open, handleOpen, handleClose];
}

export default function ConfigureMovementModal({ sn, open, onClose, movement }) {

    const handleWriteConf = useMovementWriteAction(sn);
    const { setMovements } = useOnlineDeviceApi();
    const movements = useOnlineDeviceMovements();

    const handleSubmit = async (values) => {
        console.log({ values, size: JSON.stringify(values).length });
        await handleWriteConf(values.i, values);
        const movement = _.find(movements, ({ i }) => i === values.i);
        if (movement) {
            _.merge({}, movement, values);
        } else {
            movements.push(_.merge({}, values));
        }
        setMovements(movements);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} fullScreen fullWidth maxWidth='xl'>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {movement && `Configure movement ${movement.n}` || `Configure a new movement`}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Movement onSubmit={handleSubmit} movement={movement}></Movement>
            </DialogContent>
        </Dialog>
    )
}

ConfigureMovementModal.propTypes = {
    sn: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    movement: PropTypes.object,
}
